@import '../../Variables.scss';

.myHistory {
	display: flex;
	flex-direction: column;
	padding: 28px 20px;

	&__column {
		display: flex;
		flex-direction: column;
	}

	&__item {
		background-color: #ffffff;
		border-radius: 10px;
		margin-bottom: 20px;
		box-shadow: 0px 3px 10px #00000029;
		display: flex;
		overflow: hidden;

		h1 {
			color: #073853;
			font-size: 16px;

			&.center {
				text-align: center;
			}
		}
	}

	&__welcome {
		flex: 1;
	}

	&__wellness {
		flex: 1;
		flex-direction: column;
		padding: 22px 23px;
		box-sizing: border-box;
	}

	&__progress {
		padding: 29px 24px 25px 32px;
	}
}

@media all and (min-width: $size-lg) {
	.myHistory {
		flex-direction: row;
		padding: 28px 34px;

		&__column {
			max-width: 524px;

			& + & {
				margin-left: 32px;
			}
		}

		&__welcome {
			flex: auto;
			width: 220px;
			height: 304px;
		}

		&__wellness {
			flex: auto;
			width: 304px;
			height: 304px;
		}
	}
}
