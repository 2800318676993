@import "../../Variables.scss";

.profile {
	display: flex;
	flex-direction: column;
	font-size: 14px;
	min-height: 100%;

	.MuiTypography-body1,
	.MuiFormLabel-root,
	.MuiInputBase-root {
		font-size: 14px;
	}

	.form__accordion {
		.MuiAccordionSummary-root {
			padding: 0;
			min-height: auto;
			width: 100%;
		}

		.MuiAccordionSummary-content {
			display: block;
		}

		.MuiAccordionDetails-root {
			padding: 0;
		}
	}

	.form__confirm {
		display: flex;
		justify-content: flex-end;
		width: 100%;
	}

	.form__field {
		display: flex;
		flex-direction: column;
		padding: 25px 0 20px;

		& + & {
			padding: 5px 0 20px;
		}

		.MuiAccordion-root {
			width: 100%;
		}
	}

	.form__label {
		margin: 0 0 5px 0;
		display: flex;
		flex-direction: column;
		color: var(--dark-blue);
		font-size: 14px;
		font-weight: 500;

		p {
			margin-bottom: 0;
			color: var(--dark-grey);
			font-weight: 400;
		}
	}

	.form__control {
		display: flex;
		flex-direction: column;

		& > button {
			align-self: flex-end;
		}
	}

	.form__submit {
		margin: 20px 0px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}

	.form__link {
		flex: 1;
		font-size: 14px;
		font-weight: 600;

		&__icon {
			justify-content: flex-end;
			color: #c1c1c1;

			svg {
				width: 10px;
				height: 16px;
			}
		}

		.MuiListItem-root {
			padding-top: 0;
			padding-bottom: 0;

			&:hover {
				background-color: inherit;
			}
		}

		.MuiListItemText-primary {
			color: var(--dark-blue);
		}
	}

	.form__field.notification__group {
		flex-direction: column;

		.form__label {
			flex: 1 0 60px;
		}
	}

	.notification__control {
		margin-bottom: 16px;

		label {
			margin: 0 0 0 16px;
			font-weight: 500;
		}
	}

	.notification__control:only-child {
		margin-bottom: 0;
	}

	.notification__controlContent {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		p {
			margin-top: 8px;
		}
	}
	
	.notification__groupControls {
		background: #F8F8FB;
		border-radius: 8px;
		padding: 10px;
		margin: 0 0 20px;

		p {
			margin-left: 16px;
		}

		.MuiFormControlLabel-label {
			font-weight: 500;
			margin-left: 16px;
		}

		.MuiFormControlLabel-root {
			margin: 0;
		}
		
		.MuiFormControlLabel-labelPlacementStart {
			width: 100%;
			justify-content: space-between;
		}	
	}

}

.Preferences {
	&__successMessage {
		margin: 8px 0 4px;
		color: var(--blue);
		font-size: 12px;
		font-weight: $fontWeight--medium;
	}
}

@media all and (min-width: $size-lg) {
	.profile {
		flex-direction: row;

		.form__accordion {
			.MuiAccordionSummary-content {
				display: flex;
			}
		}

		.form__field {
			flex-direction: row;
			justify-content: space-between;
			padding: 20px 0;

			&--centered {
				align-items: center;

				.form__label {
					padding-top: 0;
				}
			}
		}

		.form__label {
			flex: 1 0 250px;
			margin: 0;
			padding-top: 18px;

			&--reset {
				padding-top: 0;
			}
		}

		.form__control {
			flex: 0 0 50%;
		}
	}
}

@media all and (min-width: $size-md) {
	.profile .container--page {
		margin: 50px 10%;
		width: 80%;
	}

	.profile .container--component {
		padding: 20px 40px;
		border-radius: 10px;
	}

	.profile .header--component {
		margin: 0 -39px;
		padding: 15px 20px 25px 39px;
	}
}

@media all and (min-width: $size-lg) {
	.profile .container--page {
		margin: 25px;
	}
}
