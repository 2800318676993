@import '../../../Variables.scss';

.module {
	grid-column: span 2;
	padding-block: 26px;
	padding-inline: 25px;
	background-color: var(--white);
	box-shadow: 0px 3px 10px #00000029;
	border-radius: 15px;
	overflow: hidden;
	color: var(--dark-grey);
	font-size: 14px;

	&__dot {
		width: 9px;
		aspect-ratio: 1;
		border-radius: 50%;
		background-color: currentColor;

		&--1 {
			background-color: var(--nil-about);
		}

		&--2 {
			background-color: var(--nil-financial-success);
		}

		&--3 {
			background-color: var(--nil-my-financial-plan);
		}
	}

	&__number {
		display: flex;
		align-items: center;
		gap: 10px;
		font-weight: 600;

		&--1 {
			color: var(--nil-about);
		}

		&--2 {
			color: var(--nil-financial-success);
		}

		&--3 {
			color: var(--nil-my-financial-plan);
		}
	}

	&__name {
		margin-block-start: 7px;
		font-size: 16px;
		font-weight: 600;
	}

	&__description {
		margin-block: 18px;
	}

	&__completion {
		color: #3e3e3e;
		font-weight: 500;
	}

	&__progress {
		display: flex;
		align-items: center;
		gap: 30px;
		margin-block-start: 7px;
	}

	&__progressBar {
		flex: 1;
	}

	&__percentage {
		color: #3e3e3e;
		font-size: 16px;
		font-weight: 500;
		text-align: right;
	}

	&__separator {
		height: 1px;
		margin-block: 25px;
		background-color: #c1c1c1;
	}

	&__courses {
		flex-grow: 1;
	}

	&__coursesGrid + &__coursesGrid {
		margin-block-start: 38px;
	}

	.MuiPaper-elevation1 {
		box-shadow: none;
	}

	.MuiAccordionSummary-root {
		align-items: flex-start;
		padding: 0;
	}

	.MuiAccordionSummary-content {
		margin: 0;

		&.Mui-expanded {
			margin: 0;
		}
	}

	.MuiAccordionSummary-expandIcon {
		padding: 0px 0 0;
	}

	.MuiAccordionDetails-root {
		padding: 0;
	}

	.module__coursesCompleted .nilCourseCard__arrow {
		color: rgb(157, 157, 157) !important;
	}
}
