@import "../../Variables.scss";

.SiteHeader {
	height: $header-desktop-height;

	&__overlay {
		margin: 0;
		padding: 0;
		border: 0;
		background: transparent;
		display: block;
	}

	&__menu {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: $header-desktop-height;
		position: fixed;
		background-color: var(--white);
		z-index: 1000;
		padding: 0 18px;
		box-sizing: border-box;
		box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
	}

	&__header {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex: 0 0 185px;
	}

	&__menuButton {
		background-color: inherit;
		border: none;
		cursor: pointer;
		padding: 0;
		width: 33px;
		height: 33px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__menuIcon {
		width: 22px;
		height: 22px;
	}

	&__logo {
		max-height: 40px;
		max-width: 140px;

		&Container {
			flex: 0 0 140px;
		}
	}

	&__loginButton {
			font-size: 15px;
			font-weight: $fontWeight--bold;
			padding: 10px 50px;
			color: var(--white);
			background-color: var(--blue);
			box-shadow: none;
			border-radius: 50px;
			margin-bottom: 0;
			line-height: 1.75;
			display: inline-block;  
			white-space: nowrap;  
			overflow: hidden;     
			text-overflow: ellipsis;

			&:hover {
				color: var(--white);
				background-color: var(--blue);
			}

			& + & {
				margin-top: 15px;
			}
	}

	&__search {
		flex: 1;
		padding: 0 40px;
		max-width: 500px;

		.Home__enhancedSearch {
			padding: 0;
			margin: 0;
		}
	}

	&__tray {
		flex: 0 0 185px;
		display: flex;
		justify-content: flex-end;
		gap: 20px;

		&Item {
			width: 38px;
			height: 38px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 0;
			background: none;
			color: var(--grey3);
			cursor: pointer;
			position: relative;
			flex: 0 0 38px;

			&:hover {
				background: var(--grey1);
			}

			&--search {
				display: none;
			}

			&--user {
				background: var(--grey3);
				color: var(--white);

				&:hover {
					background: var(--blue);
				}

				&.SiteHeader__trayItem--active {
					background: var(--blue);
				}
			}

			&--active {
				background-color: var(--grey1);
			}

			&Icon {
				color: var(--grey3);
				width: 20px;
				height: 20px;
				flex: 0 0 20px;
			}

			&Count {
				background-color: var(--red);
				color: var(--white);
				width: 16px;
				height: 16px;
				border-radius: 50%;
				font-size: 12px;
				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				top: 3px;
				right: 3px;
			}
		}
	}

	&UserMenu {
		.MuiPaper-root {
			box-shadow: 0 8px 28px rgba(0, 0, 0, 0.1);
			border-radius: 20px;
			width: 282px;
			padding: 8px 0;
		}

		&__item {
			padding: 14px 22px;
			display: flex;
			align-items: center;
			cursor: pointer;
			color: inherit;
			font-size: 14px;
			width: 100%;
			box-sizing: border-box;
			background-color: transparent;
			border: 0;

			&:hover {
				background-color: var(--grey1);
			}

			&Detail {
				color: var(--grey3);
			}

			&--user {
				cursor: default;

				&:hover {
					background-color: var(--white);
				}
			}

			&IconContainer {
				margin-right: 14px;
			}

			&Icon {
				width: 24px;
				height: 24px;
				color: var(--grey3);

				&--userBadge {
					width: 38px;
					height: 38px;
					display: flex;
					align-items: center;
					justify-content: center;
					background: var(--blue);
					color: var(--white);
					border-radius: 50%;
				}
			}
		}

		&__divider {
			padding: 0;
			margin: 0;
			border: 0;
			height: 1px;
			background-color: var(--grey1);
		}
	}

	&NotificationsMenu {
		.MuiPaper-root {
			box-shadow: 0 8px 28px rgba(0, 0, 0, 0.1);
			border-radius: 20px;
			width: 373px;
			padding: 23px 0;
			box-sizing: border-box;
			border: 1px solid var(--grey2);
		}

		&__header {
			padding: 0 20px;
			font-weight: $fontWeight--bold;
			font-size: 16px;
			color: var(--darker-blue);
		}

		&__item {
			display: flex;
			padding: 16px 20px;

			&Status {
				flex: 0 0 16px;

				&Icon {
					width: 8px;
					height: 8px;
					border-radius: 50%;
					background-color: var(--blue);
					margin-top: 10px;
				}
			}

			&Aimee {
				border-radius: 50%;
				flex: 0 0 30px;
				height: 30px;
				background-color: #f4f7fe;
				display: flex;
				align-items: center;
				justify-content: center;

				&Icon {
					width: 22px;
					height: 22px;
				}
			}

			&Detail {
				padding: 0 16px;
				font-size: 15px;
				line-height: 1.2;
				flex: 1;

				.MuiButton-root,
				&Button {
					margin: 0 !important;
					padding: 4px 0 !important;
					box-shadow: none !important;
					color: var(--blue);
					background: var(--white) !important;
					font-weight: $fontWeight--medium;
					letter-spacing: normal;
					line-height: normal;
					display: inline-block;
				}
			}

			&Menu {
				flex: 0 0 24px;
				height: 24px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;

				&Button {
					border: 0;
					padding: 0;
					margin: 0;
					cursor: pointer;
					background: transparent;
				}

				&:hover,
				&--active {
					background: var(--grey1);
				}

				&Icon {
					width: 10px;
					height: 10px;
				}
			}

			&Popover {
				.MuiPaper-root {
					border-radius: 10px;
					border: 1px solid var(--grey2);
					width: 243px;
				}

				&Item {
					padding: 12px 12px 10px;
					font-size: 14px;
					font-weight: $fontWeight--regular;
					line-height: 1.4;
					cursor: pointer;
					display: flex;
					align-items: center;

					&:hover {
						background: var(--grey1);
					}

					&Icon {
						width: 18px;
						height: 18px;
						flex: 0 0 18px;
						margin-right: 12px;
					}
				}
			}
		}

		&__divider {
			padding: 0;
			margin: 0;
			border: 0;
			height: 1px;
			background-color: var(--grey1);
		}

		&__noNotifications {
			padding: 40px 57px;
			text-align: center;

			&Icon {
				margin: 0 0 14px;
				padding: 0;
				width: 78px;
				height: 78px;
			}

			&Title {
				margin: 0 0 14px;
				padding: 0;
				font-size: 16px;
				line-height: 1.25;
				font-weight: $fontWeight--medium;
			}

			&Description {
				font-size: 16px;
				line-height: 1.25;
				margin: 0;
				padding: 0;
			}
		}

		&__error {
			padding: 40px 57px;
			text-align: center;
		}
	}
}

@media screen and (max-width: $size-tablet-max) {
	.SiteHeader {
		&__tray {
			flex-basis: 0;
		}
	}
}

@media screen and (max-width: $size-tablet-min) {
	.SiteHeader {
		&__search {
			max-width: 100%;
			position: fixed;
			top: 80px;
			left: 0;
			width: 100%;
			box-sizing: border-box;
			background: var(--white);
			padding: 0 20px 18px;
			display: none;

			&--active {
				display: block;
			}
		}

		&__tray {
			flex-basis: 212px;

			&Item {
				&--search {
					display: flex;
				}
			}
		}
	}
}

@media screen and (max-width: 438px) {
	.SiteHeader {
		&__header {
			flex: 0 1 0px;
		}

		&__logoContainer {
			display: none;
		}
	}
}
