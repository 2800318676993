@import "../../Variables.scss";

.GlobalBanner {
	display: none;
	font-size: 16px;
	font-weight: $fontWeight--regular;
	line-height: 1.25;
	background-color: var(--grey1);
	padding: 20px;
	margin-bottom: 20px;
	gap: 20px;

	&__icon {
		width: 24px;
		height: 24px;
		color: var(--grey3);

		&Container {
			flex: 0 0 40px;
			height: 40px;
			background: var(--white);
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&--app {
		display: flex;
	}

	a {
		color: var(--blue);
	}
}

@media screen and (min-width: $size-tablet-max) {
	.GlobalBanner {
		border-radius: 20px;
		padding: 20px 30px;
		margin-bottom: 12px;
		display: flex;

		&--financialCoaching,
		&--lifeEvents,
		&--library {
			margin-top: 25px;
		}

		&--home {
			margin-bottom: 23px;
		}

		&--app {
			display: none;
		}
	}
}
