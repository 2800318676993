@import "../../Variables.scss";

.HubDisabled {
	display: flex;
	width: 100%;
	min-height: 100%;
	justify-content: center;
	align-items: center;

	&__content {
		padding: 30px;
	}

	&__logo {
		width: 200px;
		height: auto;
	}

	&__title {
		font-size: 26px;
		line-height: 1.4;
		font-weight: $fontWeight--bold;
	}

	&__subtitle {
		font-size: 18px;
		line-height: 1.4;
		font-weight: $fontWeight--medium;
	}

	&__contactUs {
		font-size: 14px;
		line-height: 1.4;
		font-weight: $fontWeight--regular;
	}

	a {
		color: var(--blue);
	}
}
