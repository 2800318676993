.activityTimeline {
	padding: 22px 37px 22px 31px;

	ol {
		list-style: none;
		margin-block-start: 0;
		padding-inline-start: 0;
		position: relative;
		z-index: 2;

		li {
			margin: 23px 0;
		}
	}

	&__timeline {
		position: relative;
		z-index: 1;

		&::after {
			content: "";
			position: absolute;
			width: 1px;
			background-color: #f7f7f7;
			top: 0;
			bottom: 0;
			left: 111px;
			margin-left: -3px;
			z-index: 1;
		}
	}

	h1 {
		color: #073853;
		font-size: 16px;
	}
}

.activityItem {
	display: flex;
	flex-direction: row;
	align-items: center;

	> div + div {
		margin-left: 23px;
	}

	&__dateTime {
		flex: 0 0 65px;
		width: 65px;
		color: #9d9d9d;
		font-size: 12px;
		text-align: left;
	}

	&__iconBox {
		width: 41px;
		height: 41px;
		display: flex;
		justify-content: center;
		align-items: center;

		&--actionItem {
			width: 38px;
			height: 38px;
		}
	}

	&__icon {
		width: 41px;
		height: 41px;

		&--green {
			color: var(--green);
		}

		&--red {
			color: var(--red);
		}

		&--orange {
			color: var(--orange);
		}

		&--yellow {
			color: var(--yellow);
		}
	}

	&__type {
		color: #9d9d9d;
		font-size: 12px;
		line-height: 17px;
	}

	&__name {
		color: var(--dark-grey);
		font-size: 14px;
		font-weight: 500;
		line-height: 18px;
	}
}
