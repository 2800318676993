html, 
body {
	width: 100%;
	height: 100%;
	min-height: 100%;
	padding: 0;
	margin: 0;
	color: #383838;
    font-family: Roboto, Helvetica, Arial, sans-serif;

	#root {
		height: 100vh;
	}
}

// ensures clickable items with SVGs in the container can still be clicked
svg {
    pointer-events: none;
}

// IE11 HTML5 compatibility
header, nav, article, footer, address { 
    display: block; 
}