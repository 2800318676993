.historyWelcome {
	display: flex;
	flex-direction: column;
	background: transparent
		linear-gradient(90deg, var(--blue) 0%, var(--light-blue) 100%);
	color: var(--white);
	height: 100%;
	padding: 23px 25px 22px 23px;
	box-sizing: border-box;

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	&__avatar {
		background-color: #00000059;
		width: 34px;
		height: 34px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 34px;
		text-transform: uppercase;
		font-size: 16px;
		line-height: 30px;
		letter-spacing: 0.29px;
	}

	&__welcome {
		font-size: 19px;
		line-height: 23px;
		margin-top: 29px;
		color: var(--white);

		&__name {
			font-weight: 600;
		}
	}

	&__footer {
		margin-top: auto;
	}

	&__aimee {
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 12px;
		display: flex;
		align-items: center;
	}

	&__changeButton {
		background-color: #ffffff30;
		border: none;
		border-radius: 5px;
		color: var(--white);
		font-size: 13px;
		font-weight: 600;
		padding: 10px 12px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}
	
	&__aimeeContainer {
		width: 17px;
		height: 17px;
		background-color: var(--white);
		border-radius: 17px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 5px;
	}

	&__icon {
		height: 14px;
		width: 14px;
		padding: 3px;

		&--profile {
			fill: var(--white);
			height: 16px;
			width: 16px;
		}

		&--chevron {
			margin-left: 8px;
			height: 10px;
			width: 10px;
			padding: 1px;
		}
	}

	&__aimeeIcon {
		width: 15px;
		height: 15px;
	}
}
