.progressCircle {
	display: grid;
	place-items: center;

	> * {
		grid-area: 1 / 1;
	}

	&__chart {
		width: 134px;
		aspect-ratio: 1 / 1;
	}

	&__total {
		color: #9d9d9d;
		font-size: 14px;
		font-weight: 500;
		text-align: center;
	}

	&__value {
		color: var(--blue);
		font-size: 36px;
		font-weight: 600;
	}
}

.certificationProgress {
	padding-block: 26px;
	padding-inline: 25px;
	background-color: var(--white);
	box-shadow: 2px 4px 10px #00000029;
	border-radius: 15px;
	overflow: hidden;

	&__title {
		color: #3e3e3e;
		font-weight: 600;
	}

	&__progress {
		display: flex;
		margin-block-start: 33px;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	&__name {
		margin-block-start: 23px;
		color: #073853;
		font-size: 18px;
		font-weight: 600;
	}

	&__location {
		margin-block-start: 10px;
		color: #a0a0a0;
		font-size: 14px;
	}

	&__modules {
		display: flex;
		flex-direction: column;
		gap: 10px;
		margin-block-start: 45px;

		&Title {
			color: var(--blue);
			font-weight: 600;
		}
	}

	&__info {
		margin-block-start: 25px;
		color: var(--dark-grey);
		font-size: 14px;
		text-align: center;
	}
}
