@import "../../Variables.scss";

.FinancialCoaching {
	padding: 0px 10px 40px;

	&__titleBar {
		display: flex;
		align-items: center;
		height: 88px;
		margin-left: 10px;

		h1 {
			color: var(--dark-blue);
			font-size: 25px;
			font-weight: $fontWeight--bold;
			margin: 0;
		}
	}

	&__tabs {
		margin: 0 0 33px 10px;
	}

	&__tab {
		flex: 1;
		background: none;
		border: none;
		color: var(--grey);
		font-size: 15px;
		font-weight: $fontWeight--regular;
		text-align: center;
		padding: 0 0 12px;
		margin: 0 46px 0 0;
		cursor: pointer;

		&:focus {
			outline: 0;
		}

		&.active {
			border-bottom: 2px solid var(--blue);
			color: var(--blue);
			font-weight: $fontWeight--bold;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&__header {
		font-size: 14px;
		font-weight: $fontWeight--regular;
		color: #707070;
		margin-bottom: 7px;
		display: flex;
		padding: 0 24px;
	}

	&__item {
		display: flex;
		border-radius: 10px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
		background: var(--white);
		margin-bottom: 11px;
		padding: 21px 24px;
		align-items: center;
		font-size: 14px;
		font-weight: $fontWeight--regular;
	}

	&__column {
		strong {
			font-weight: $fontWeight--bold;
		}

		&--coach {
			flex: 3 0 145px;
			margin-right: 10px;
			line-height: 1.29;
			display: flex;
			align-items: center;

			a {
				display: flex;
				align-items: center;
				color: var(--blue);
			}
		}

		&--date {
			flex: 1 0 70px;
			min-width: 70px;
			line-height: 1.43;
		}

		&--type {
			flex: 0 0 10px;
			min-width: 12px;
			display: flex;
			align-items: center;
		}
	}

	&__typeIcon {
		display: block;
		min-width: 10px;
		width: 10px;
		height: 10px;
		background: #6da31a;
		border-radius: 50%;

		&--Chat {
			background: #ffba00;
		}

		&--1x1 {
			background: #55abdd;
		}
	}

	&__typeName {
		visibility: collapse;
		margin-left: 12px;
	}

	&__hr {
		height: 1px;
		border: 0;
		margin: 45px 0;
		padding: 0;
		background: #d3d3d3;
	}

	&__banner {
		display: flex;
		flex-direction: column;
		align-items: center;
		background: var(--blue);
		color: var(--white);
		position: relative;
		z-index: 1;
		border-radius: 15px;
		padding: 30px 24px 26px;
		margin-bottom: 24px;
		box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
		font-size: 15px;

		&Title {
			font-size: 21px;
			font-weight: $fontWeight--bold;
			line-height: 1.31;
			margin: 0 0 9px;
			padding: 0;
		}

		&Intro {
			line-height: 1.57;
			font-weight: $fontWeight--regular;
			margin: 0 0 9px;
		}

		&Link {
			color: inherit;
			font-weight: $fontWeight--bold;
		}
	}

	&__icon {
		width: 60px;
		height: 60px;
		position: absolute;
		top: 10px;
		left: 10px;

		&Container {
			background: var(--white);
			border-radius: 50%;
			position: relative;
			width: 60px;
			height: 60px;
			overflow: hidden;
			flex: 0 0 60px;
			margin-bottom: 23px;
		}
	}

	&__actions {
		display: flex;
		flex-direction: column;
	}

	&__action {
		margin: 12px 0;
		border-radius: 10px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
		background: var(--white);
		display: flex;
		flex-direction: column;

		&Body {
			display: flex;
			padding: 30px 24px;
			flex: 1;
		}

		&Icon {
			max-width: 52px;
			max-height: 52px;

			&Container {
				flex: 0 0 76px;
			}
		}

		&Text {
			flex: 1;

			h2 {
				font-size: 16px;
				font-weight: $fontWeight--bold;
				line-height: 1.13;
				padding: 0;
				margin: 0;
			}

			p {
				font-size: 14px;
				font-weight: $fontWeight--regular;
				line-height: 1.43;
				padding: 0;
			}
		}

		a {
			display: block;
			width: 100%;
			text-align: center;
			font-size: 14px;
			font-weight: $fontWeight--bold;
			color: var(--blue);
			border-top: 1px solid #e6e6e6;
			padding: 18px;
			box-sizing: border-box;
		}
	}

	&__coaches {
		display: flex;
		flex-wrap: wrap;
		margin: 36px -7px 0;
	}

	&__coach {
		background: var(--white);
		border-radius: 15px;
		box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
		display: flex;
		width: 238px;
		height: 242px;
		flex: 1 0 140px;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin: 6px 7px;
		font-size: 15px;
		line-height: 1.43;
		font-weight: $fontWeight--regular;
		text-align: center;
		color: inherit;

		&Image {
			width: 96px;
			height: 96px;
			border-radius: 50%;
			margin-bottom: 23px;

			&--history {
				width: 44px;
				height: 44px;
				border-radius: 50%;
				margin: 0 13px 0 0;
			}
		}
	}
}

@media screen and (max-width: 350px) {
	.FinancialCoaching {
		&__tabs {
			button {
				margin-right: 12px;
			}
		}
	}
}

@media screen and (min-width: 420px) {
	.FinancialCoaching {
		&__coach {
			flex: 1 0 190px;
			max-width: 238px;
		}
	}
}

@media screen and (min-width: $size-mobile-max) {
	.FinancialCoaching {
		&__column {
			&--coach {
				flex: 1 0 257px;
				line-height: 1.29;
			}

			&--date {
				flex: 2;
			}

			&--type {
				flex: 0 0 70px;
				max-width: 70px;
				padding-left: 10px;
			}
		}

		&__typeName {
			visibility: visible;
		}

		&__coach {
			flex: 0 0 220px;
		}
	}
}

@media screen and (min-width: $size-desktop-min) {
	.FinancialCoaching {
		padding: 0px 32px 33px;

		&__titleBar {
			margin-left: 0;
		}

		&__tabs {
			margin: 0 0 33px;
		}

		&__banner {
			flex-direction: row;
			padding: 34px 46px;
		}

		&__icon {
			width: 98px;
			height: 98px;
			top: 16px;
			left: 16px;

			&Container {
				margin-right: 42px;
				width: 98px;
				height: 98px;
				flex: 0 0 98px;
			}
		}

		&__actions {
			flex-direction: row;
		}

		&__action {
			flex: 0 0 calc(33.33% - 31px);
			margin: 0 31px 0 0;

			&:last-child {
				width: 33.33%;
				flex: 0 0 33.33%;
				margin-right: 0;
			}
		}
	}
}
