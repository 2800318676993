@import '../../../Variables.scss';

// .EnhancedSearchBadge {
// 	--badge-radius: 30px;

// 	position: relative;
// 	border-radius: var(--badge-radius);
// 	padding: 4px 9px;
// 	display: inline-flex;
// 	align-items: center;

// 	&::before {
// 		content: '';
// 		display: block;
// 		position: absolute;
// 		top: 0;
// 		left: 0;
// 		width: 100%;
// 		height: 100%;
// 		background: linear-gradient(90deg, var(--light-blue) 0%, var(--blue) 100%);
// 		opacity: 0.06;
// 		border-radius: var(--badge-radius);
// 	}

// 	&__icon {
// 		width: 18px;
// 		height: auto;
// 		display: inline-block; 
// 		margin-right: 3px;
// 	}

// 	&__aimee {
// 		font-size: 16px;
// 		font-weight: $fontWeight--bold;;
// 		color: var(--dark-blue);
// 		display: inline-block; 
// 		margin-right: 7px;
// 	}

// 	&__beta {
// 		font-size: 12px;
// 		font-weight: $fontWeight--light;
// 		color: var(--blue);
// 		display: inline-block; 
// 		margin-right: 7px;
// 	}
// }

.EnhancedSearchBadge {
	width: 131px;
	height: auto;
}