@import '../../Variables.scss';

.intlBanner {
	grid-column: span 2;
	border-radius: 15px;
	padding-block: 23px;
	padding-inline: 30px;
	background: linear-gradient(270deg, var(--light-blue), var(--blue));
	box-shadow: 0 3px 10px #70707066;
	color: var(--white);
	position: relative;

	&__title {
		max-width: 95%;
		font-size: 22px;
		font-weight: 600;
	}

	&__close {
		position: absolute;
		top: 12px;
		right: 20px;
		background: none;
		border: 0;
		margin: 0;
		padding: 8px;
		cursor: pointer;
		color: var(--white);

		&Icon {
			width: 10px;
			height: 10px;
		}
	}

	&__body {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 20px;
		margin-block-start: 20px;

		@media screen and (min-width: $size-tablet-min) {
			flex-direction: row;
			align-items: flex-start;
		}
	}

	&__content {
		margin: 0;
		font-size: 15px;
	}

	&__next {
		flex: 1 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		padding-block: 12px;
		padding-inline: 30px;
		background-color: #297398;
		color: var(--white);
		box-shadow: none;
		border: none;
		border-radius: 10px;
		font-size: 13px;
		font-weight: 500;
		cursor: pointer;

		@media screen and (min-width: $size-tablet-min) {
			flex-grow: 0;
			align-self: flex-end;
		}
	}
}

.intlDashboard__startBanner {

	.intlBanner__body {
		flex-direction: column;
		line-height: 1.4em;
	}

	.intlBanner__title {
		line-height: 1.4em;
	}

	.intlBanner__next {
		align-self: flex-start !important;
		text-transform: none !important;
		background-color: #0E6387 !important;
	}

}

.intlDashboard__adobeChromeBanner {
	background: #EAF0F6;
	color: #383838;
	box-shadow: none;

	a {
		color: #007CAB;
		font-weight: 500;
		text-decoration: underline;
	}

	.intlBanner__body {
		flex-direction: column;
		line-height: 1.4em;
	}

	.intlBanner__close {
		color: #383838;
	}

}