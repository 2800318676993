@import "../../Variables.scss";

.filterChips {
	display: flex;
	overflow-x: auto;
	max-width: 100%;
	margin-bottom: 10px;
	padding-bottom: 15px;
}

.filterChip {
	flex: 0 0 auto;
	display: flex;
	min-width: 0;
	align-items: center;
	background-color: #e6e6e6;
	border-radius: 30px;
	font-size: 13px;
	line-height: 16px;
	padding: 7px 12px;
	margin: 0 4px;

	button {
		width: 18px;
		height: 18px;
		cursor: pointer;
		background-color: #707070;
		border: none;
		outline: none;
		border-radius: 50%;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: 5px;
		margin-left: 8px;
		vertical-align: bottom;
	}

	&__close {
		width: 18px;
		height: 18px;
		color: #f7f7fa;
	}
}

@media screen and (max-width: $size-tablet-max) {
	.filterChip {
		&:first-child {
			margin-left: 10px;
		}
	}
}

@media screen and (min-width: $size-desktop-min) {
	.filterChips {
		overflow-x: auto;
		margin-top: -6px;
		margin-left: -4px;
		padding-bottom: 5px;
		scrollbar-width: thin;

			&::-webkit-scrollbar-track {
				background-color: #f0f0f0;
			}

			&::-webkit-scrollbar {
				height: 6px;
				background-color: #f5f5f5;
			}

			&::-webkit-scrollbar-thumb {
				background-color: #cdcdcd;
			}
	}

	.filterChip {
		margin: 6px 4px;
	}
}
