@import "./Variables.scss";

a {
	text-decoration: none;
}

.container--page {
	min-height: calc(100% - 100px);
}

.container--component {
	background-color: var(--white);
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
	padding: 20px;
}

.title--component {
	font-size: 20px;
}

.customField {
	.MuiInputBase-input {
		background-color: var(--white);
	}

	.MuiOutlinedInput-notchedOutline {
		border: 1px solid #a0aec0;
		font-size: 15px;
		border-radius: 5px;
	}

	.MuiCheckbox-root .MuiSvgIcon-root {
		fill: #a0aec0;
	}

	.MuiCheckbox-root.Mui-checked .MuiSvgIcon-root {
		fill: var(--dark-blue);
	}
}

.numberInput {
	.MuiInputBase-input {
		background-color: var(--white);
	}
}

.MuiButton-root {
	border-radius: 5px;
}

.MuiToolbar-root.MuiPickersToolbar-toolbar,
.MuiPickersDay-day.MuiPickersDay-daySelected {
	background-color: var(--blue);
	
	&.MuiPickersDay-day.MuiPickersDay-current {
		color: var(--white);
	}
}

.MuiPickersDay-day.MuiPickersDay-daySelected:hover {
	background-color: var(--dark-blue);
}

.MuiPickersDay-day.MuiPickersDay-current,
.MuiPickersYear-root.MuiPickersYear-yearSelected {
	color: var(--blue);
}

.MuiSelect-select.MuiSelect-select:focus {
	background-color: unset;
}

.header--component {
	display: flex;
	align-items: center;
	font-size: 20px;
	margin: 0 -10px;
	padding: 0 20px 20px 10px;
	border-bottom: 1px solid var(--x-light-grey);
	color: var(--dark-blue);

	h1 {
		font-size: 20px;
		margin: 0;
	}

	.title__icon {
		padding: 15px;
		margin: -15px 10px -15px -15px;

		svg {
			width: 12px;
			height: 18px;
			transform: rotate(180deg);
		}
	}
}

.section--component {
	border-bottom: 1px solid var(--x-light-grey);
}

.article--legal {
	margin-top: 40px;

	a {
		color: var(--blue);
	}

	p,
	li {
		margin: 10px 0;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0;
		color: var(--dark-grey);
	}

	p {
		margin: 30px 0;
	}

	&--back {
		margin-top: 100px;
		text-align: right;
	}
}

.container--component {
	padding: 20px 40px;
	border-radius: 10px;
}

.header--component {
	margin: 0 -39px;
	padding: 15px 20px 25px 39px;
}

.MuiCheckbox-colorSecondary.Mui-checked {
	color: var(--blue) !important;
}