@import '../../../Variables.scss';

.HomeHeader {
	display: flex;
	align-items: center;
	margin-bottom: 33px;

	&__welcome {
		flex: 1;
	}

	&__title {
		margin: 0 0 5px;
		padding: 0;
		font-size: 26px;
		line-height: 1.4;
		color: var(--blue);
	}

	&__subtitle {
		margin: 0;
		padding: 0;
		font-size: 16px;
		line-height: 1.4;
	}

	&__aimee {
		flex: 0 0 234px;

		&Button {
			background: var(--darker-blue);
			display: flex;
			align-items: center;
			border-radius: 50px;
			color: var(--white);
			padding: 14px 16px;
			font-weight: $fontWeight--medium;
			font-size: 18px;
			box-shadow: $shadow4;

			&Icon {
				width: 22px;
				height: 22px;
				margin: 0;
				padding: 0;
				display: block;

				&Container {
					padding: 3px;
					background: var(--white);
					border-radius: 50%;
					margin-right: 19px;
				}
			}
		}
	}
}

@media screen and (max-width: 855px) {
	.HomeHeader {
		flex-direction: column;
		margin-bottom: 31px;
		padding: 0 30px;

		&__welcome {
			flex: 1;
			width: 100%;
			margin-bottom: 21px;
		}

		&__aimee {
			flex: 1;
			width: 100%;

			&Button {
				justify-content: center;
			}
		}
	}
}
