@import '../../Variables.scss';

.HomeQuestionnaire {
	width: 375px;
	height: 464px;
	padding: 25px;
	box-sizing: border-box;
	background: var(--blue);
	border-radius: 20px;
	position: relative;

	&__header {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin-bottom: 26px;
	}

	&__close {
		margin: 0;
		border: 0;
		background: transparent;
		cursor: pointer;

		&Icon {
			width: 10px;
			height: 10px;
			color: var(--white);
		}
	}

	&__title {
		font-size: 22px;
		line-height: 1.23;
		color: var(--white);
		font-weight: $fontWeight--bold;
		padding: 0;
		margin: 0 0 37px;
	}

	&__ratings {
		display: flex;
		justify-content: space-between;
		gap: 2px;
	}

	&__rating {
		width: 62px;
		height: 43px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: var(--white);
		border: 0;
		border-bottom: 4px solid;
		margin: 0;
		padding: 0;
		cursor: pointer;
		font-size: 22px;
		font-weight: $fontWeight--bold;
		font-family: inherit;
		color: #383838;

		&--detractor {
			border-color: var(--red);
		}

		&--passive {
			border-color: var(--yellow);
		}

		&--promoter {
			border-color: var(--green);
		}

		&--selecteddetractor {
			background-color: var(--red);
		}

		&--selectedpassive {
			background-color: var(--yellow);
		}

		&--selectedpromoter {
			background-color: var(--green);
		}
	} 

	&__ratingExplainers {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 8px 0;

		&Explain {
			text-transform: uppercase;
			color: var(--white);
			font-size: 10px;
			letter-spacing: 0.10em;
			font-weight: $fontWeight--medium;
			display: flex;
			align-items: center;
			gap: 10px;

			&Icon {
				width: 16px;
				height: 16px;
			}
		}
	}

	&__comments {

		&Title {
			font-size: 18px;
			font-weight: $fontWeight--bold;
			color: var(--white);
			min-height: 42px;
			display: flex;
			align-items: flex-end;
		}

		&Textarea {
			font-family: inherit;
			width: 100%;
			height: 108px;
			box-sizing: border-box;
			border: 0;
			border-radius: 10px;
			padding: 10px;
		}
	}

	&__thankYouTitle {
		font-size: 22px;
		line-height: 1.23;
		color: var(--white);
		font-weight: $fontWeight--bold;
		padding: 0;
		margin: 80px 0 10px;
		text-align: center;
	}

	&__thankYouDescription {
		font-size: 16px;
		line-height: 1.25;
		color: var(--white);
		font-weight: $fontWeight--regular;
		padding: 0;
		margin: 0 0 10px;
		text-align: center;
	}

	&__submit {
		position: absolute;
		bottom: 50px;
		left: 50%;
		transform: translateX(-50%);
		background-color: var(--dark-blue);
		color: var(--white);
		border: 0;
		border-radius: 50px;
		font-size: 16px;
		font-weight: $fontWeight--bold;
		width: calc(100% - 50px);
		height: 48px;
		cursor: pointer;

		&:disabled {
			cursor: not-allowed;
		}
	}
}