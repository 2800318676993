@import "../../../Variables.scss";

.HomeRecommendations {
	width: 375px;
	height: 464px;
	padding: 25px;
	box-sizing: border-box;
	border-radius: 20px;
	box-shadow: $shadow4;
	background: var(--white);

	&__header {
		display: flex;
		margin: 0 0 24px;
		align-items: center;
		gap: 14px;
	}

	&__icon {
		width: 27px;
		height: 27px;
	}

	&__title {
		color: var(--dark-blue);
		font-size: 22px;
		font-weight: $fontWeight--bold;
		margin: 0;
		padding: 0;
	}

	&__body {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	&__rec {
		color: inherit;
		border-radius: 15px;
		display: flex;
		align-items: flex-start;
		padding: 8px 10px;
		flex: 1;
		box-sizing: border-box;
		border: 1px solid var(--grey2);
		background-color: var(--grey0);

		&Image {
			width: 60px;
			height: 60px;
			border-radius: 10px;
			background-size: cover;
			background-position: 50% 50%;
			flex: 0 0 60px;
			margin-right: 18px;
		}

		&Details {
			flex: 1;
		}

		&Type {
			font-size: 12px;
			color: var(--grey3);
			text-transform: uppercase;
			font-weight: $fontWeight--regular;
			letter-spacing: 0.08em;
		}

		&Title {
			margin: 4px 0 0;
			padding: 0;
			font-weight: $fontWeight--medium;
			font-size: 16px;
			line-height: 1.25;
		}
	}
}