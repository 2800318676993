@import "../../../Variables.scss";

.HelpfulResources {
	display: flex;
	flex-direction: column;
	background-color: #f7f7fa;
	border-radius: 10px;
	margin: 29px -12px 0;

	&__header {
		margin: 24px 24px 10px;

		h2 {
			color: var(--dark-blue);
			font-size: 16px;
			font-weight: $fontWeight--bold;
			margin: 0;
		}
	}

	&__resources {
		display: flex;
		align-items: flex-start;
		flex: 0 0 50%;
		box-sizing: border-box;
		margin: 0 14px;
		flex-wrap: wrap;
	}

	&__resource {
		color: inherit;
		display: flex;
		border-radius: 10px;
		align-items: flex-start;
		margin: 10px;
		flex: 0 0 100%;
		box-sizing: border-box;

		&:nth-child(n + 3) {
			display: none;
		}

		&:hover {
			background-color: #f7f7fa;
		}

		&Image {
			width: 65px;
			height: 57px;
			border-radius: 10px;
			background-size: cover;
			background-position: 50% 50%;
			flex: 0 0 65px;
			margin-right: 17px;
		}

		&Details {
			flex: 1;
			height: 100%;
		}

		&Type {
			font-size: 10px;
			color: #707070;
			border: 1px solid #d3d3d3;
			border-radius: 10px;
			padding: 2px 10px;
			text-transform: capitalize;
			font-weight: $fontWeight--medium;
		}

		&Title {
			margin: 3px 0 0;
			padding: 0;
			font-weight: $fontWeight--regular;
			font-size: 14px;
			line-height: 1.333;
			color: #383838;
		}
	}

	&__callCoach {
		margin: 30px 24px;

		&Text {
			margin: 5px 0;
			color: var(--dark-blue);
			font-size: 16px;
			font-weight: $fontWeight--regular;
		}

		&Link {
			margin: 5px 0;
			font-size: 16px;
			font-weight: $fontWeight--medium;
			color: var(--blue);
		}
	}
}

@media screen and (min-width: $size-tablet-min) {
	.HelpfulResources {
		margin: 29px 0 0;

		&__header {
			margin: 30px 30px 10px;
			h2 {
				font-size: 15px;
			}
		}

		&__resources {
			margin: 0 20px;
		}

		&__resource {
			flex: 0 0 45%;

			&:nth-child(n + 3) {
				display: flex;
			}
		}

		&__callCoach {
			margin: 30px 30px;
			display: flex;
			justify-content: space-between;

			&Link {
				text-align: right;
			}
		}
	}
}

@media screen and (min-width: $size-desktop-min) {
	.HelpfulResources {
		&__resource {
			flex: 0 0 100%;
		}

		&__callCoach {
			display: block;

			&Text {
				font-size: 14px;
			}

			&Link {
				font-size: 13px;
				text-align: left;
			}
		}
	}
}

@media screen and (min-width: $size-desktop-max) {
	.HelpfulResources {
		&__resource {
			flex: 0 0 45%;
		}

		&__callCoach {
			margin: 30px 30px;
			display: flex;
			justify-content: space-between;

			&Link {
				text-align: right;
			}
		}
	}
}
