@import "../../Variables.scss";

.metaEvents {
	min-height: 100%;
	height: 100%;

	&__backToList {
		display: flex;
		align-items: center;
		height: 32px;
		width: 32px;
		color: #073853;
		margin: -2px 10px -2px -10px;
	}

	&__filterIcon {
		height: 16px;
		width: 16px;
		stroke: #383838;

		&--active {
			stroke: var(--blue);
		}
	}

	&__workspace {
		display: flex;
		flex-direction: column;
		min-height: 100%;
		box-sizing: border-box;
	}

	&__content {
		display: flex;
		flex-direction: column;
		min-height: 100%;
	}

	&__sessionList {
		background-color: var(--white);
		height: 100%;
	}

	&__details {
		display: flex;
		flex-direction: column;

		&Container {
			position: relative;
			flex: 1;
			background-color: var(--white);
		}
	}

	&__titleBar {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin: 0 26px;
		height: 88px;

		&__title {
			display: flex;
			align-items: center;
		}

		button {
			background-color: inherit;
			border: none;
			cursor: pointer;
			outline: none;
			display: flex;
			align-items: center;
		}

		h1 {
			margin: 0;
			font-size: 25px;
			font-weight: 600;
			color: #073853;
		}
	}
}

@media screen and (max-width: $size-tablet-max) {
	.metaEvents--hide-tablet {
		display: none;
	}
}

@media screen and (min-width: $size-desktop-min) {
	.metaEvents {
		flex-direction: row;

		&__backToList {
			display: none;
		}

		&__workspace {
			flex: 1;
			margin: 0 1%;
			transition: margin 200ms linear;
			position: relative;
			align-items: unset;
			padding: 0 16px 16px;
		}

		&__leftColumn {
			width: 40%;
			max-width: 343px;
			position: sticky;
			top: 88px;
			margin-right: 25px;
		}

		&__content {
			flex-direction: row;
			align-items: flex-start;
			padding-bottom: 20px;
			flex: 1;
		}

		&__sessionList {
			border-radius: 15px;
			box-shadow: 2px 4px 10px #00000029;
			height: calc(95vh - 125px);
		}

		&__details {
			width: 65%;
			align-self: stretch;
			min-height: 100%;

			&Container {
				border-radius: 15px;
				box-shadow: 0px 3px 10px #00000029;
			}
		}

		&__titleBar {
			width: 40%;
			max-width: 343px;
			margin: 0;
			top: 0;
			position: sticky;
		}
	}
}

@media screen and (min-width: $size-desktop-lg-min) {
	.metaEvents {
		&__workspace {
			margin: 0 45px;
			transition: margin 200ms linear;
		}
	}
}

.metaEventsSession {
	font-size: 14px;
	line-height: 1.5;
	padding: 0 24px 25px 25px;

	&__details a {
		font-weight: 500;
		color: var(--blue);

		&:visited,
		&:active {
			color: var(--blue);
		}
	}

	&__nav {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 10px;
	}

	&__back {
		&__icon {
			padding: 15px;
			margin: -15px 10px -15px -15px;

			svg {
				width: 12px;
				height: 18px;
				transform: rotate(180deg);
				color: #9d9d9d;
			}
		}
	}

	&__status {
		position: absolute;
		top: 19px;
		right: 15px;

		&__icon {
			width: 26px;
			height: 26px;
			color: #e6e6e6;

			&--registered {
				color: var(--blue);
			}
		}
	}

	&__title {
		margin: 38px 0 18px;
		padding: 0;
		font-size: 22px;
		font-weight: $fontWeight--bold;
		color: var(--dark-blue);
	}

	&__meta {
		line-height: 1.2;
		margin-bottom: 24px;

		&Title {
			display: flex;
			align-items: center;
			margin-bottom: 12px;

			h2 {
				margin: 0;
				padding: 0;
				font-size: 14px;
				font-weight: $fontWeight--medium;
			}
		}

		&Icon {
			width: 24px;
			height: 24px;
			color: var(--blue);
			margin-right: 4px;
		}

		&Date {
			font-size: 16px;
		}
	}

	&__appointments {
		margin-bottom: 24px;

		&Title {
			display: flex;
			align-items: center;
			margin-bottom: 12px;

			h2 {
				margin: 0;
				padding: 0;
				font-size: 14px;
				font-weight: $fontWeight--medium;
			}
		}

		&Icon {
			width: 24px;
			height: 24px;
			fill: var(--blue);
			margin-right: 4px;
		}

		&Times {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
			gap: 20px;	
		}
	}

	&__userInfo {
		margin-bottom: 24px;

		&Title {
			display: flex;
			align-items: center;
			margin-bottom: 12px;

			h2 {
				margin: 0;
				padding: 0;
				font-size: 14px;
				font-weight: $fontWeight--medium;
			}
		}

		&Icon {
			width: 24px;
			height: 24px;
			fill: var(--blue);
			margin-right: 4px;
		}

		&Fields {
			display: flex;
			flex-wrap: wrap;
			gap: 20px;
		}

		&Field {
			flex: 0 0 100%;

			&--firstName,
			&--lastName {
				flex: 0 0 100%;
			}
		}
	}

	&__registered {

		&Alert {
			margin-bottom: 30px;
		}

		&Item {
			margin-bottom: 24px;

			&Title {
				display: flex;
				align-items: center;
				margin-bottom: 12px;

				h2 {
					margin: 0;
					padding: 0;
					font-size: 14px;
					font-weight: $fontWeight--medium;
				}
			}

			&Icon {
				width: 24px;
				height: 24px;
				fill: var(--blue);
				color: var(--blue);
				margin-right: 4px;
			}

			&Detail {
				display: flex;
				justify-content: flex-start;
				flex-wrap: wrap;
				gap: 20px;	

				&Link {
					padding: 8px 36px;
					color: var(--white);
					background-color: var(--blue);
					box-shadow: 0px 3px 1px -2px #0003,0px 2px 2px 0px #00000024,0px 1px 5px 0px #0000001f;
					min-width: 64px;
					display: inline-block;
					border-radius: 4px;
					line-height: 1.75;
					font-weight: $fontWeight--medium;
				}
			}
		}
	}
}

@media screen and (min-width: $size-desktop-min) {
	.metaEventsSession {
		padding: 0 38px 38px;

		&__nav {
			justify-content: flex-end;
			margin-top: 30px;
		}

		&__back {
			display: none;
		}

		&__status {
			position: absolute;
			top: 20px;
		}

		&__userInfo {

			&Field {
	
				&--firstName,
				&--lastName {
					flex: 0 0 calc(50% - 10px);
				}
			}
	
		}
	}
}

@media (max-width: 500px) {
	.metaEventsSession {
		&__title {
			margin-right: 24px;
		}
	}
}
