.google-signIn {
	display: inline-flex;
	align-items: center;

	img {
		height: 25px;
		width: 25px;
	}

	div {
		color: #c1c1c1;
		font-size: .9em;
		margin-left: 12px;
	}
}
