@import "../../Variables.scss";

.GoalProgressSummary__container {
	grid-row: span 2;
}

.GoalProgressSummary {
	border-radius: 15px;
	overflow: hidden;
	background-color: var(--blue);
	box-shadow: 0 3px 6px #00000029;
	color: var(--white);

	&__top,
	&__chart,
	&__bottom {
		padding-inline: 24px;
	}

	&__top {
		display: grid;
		grid-template-columns: 1fr 32px;
		gap: 7px;
		padding-top: 29px;

		@media screen and (min-width: $size-desktop-min) {
			padding-inline: 25px;
		}
	}

	&__toggle {
		margin-block: 30px;
	}

	&__chart {
		margin-block: 40px 20px;
	}

	&__bottom {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #0e729a;
		padding-block: 25px 25px;

		@media screen and (min-width: $size-desktop-min) {
			padding-inline: 25px;
		}
	}

	&__title {
		font-size: 30px;
		font-weight: 600;
	}

	&__icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 32px;
		aspect-ratio: 1;
		border-radius: 50%;
		background-color: white;
		color: var(--blue);

		> * {
			width: 22px;
			aspect-ratio: 1;
		}
	}

	&__status {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 8px;
		min-height: 18.39px;
		font-size: 16px;
		font-weight: 500;

		&Icon {
			width: 7px;
			aspect-ratio: 1;
			border-radius: 50%;

			&--green {
				background-color: var(--green);
			}

			&--orange {
				background-color: var(--orange);
			}

			&--red {
				background-color: var(--red);
			}
		}
	}

	&__saved,
	&__completedIn {
		font-size: 15px;

		& > div {
			margin-top: 6px;
			font-weight: 600;
			font-size: 20px;
		}
	}

	&__completedIn {
		text-align: right;
	}
}

.GoalProgressSummary__debtToggle {
	display: flex;
	justify-content: center;
	align-items: center;

	&Container {
		background-color: #459dc0;
		border-radius: 30px;
	}

	&Toggle {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 38px;
		padding: 0 20px;
		border-radius: 30px;
		border: 0;
		cursor: pointer;
		background-color: transparent;
		font-size: 14px;
		font-weight: $fontWeight--bold;
		color: var(--white);

		&:nth-child(1) {
			margin-right: -10px;
		}

		&:nth-child(3) {
			margin-left: -10px;
		}

		&--active {
			background-color: var(--white);
			color: var(--blue);
		}
	}


	input:checked ~ div:nth-child(3) {
		background-color: var(--white);
		color: var(--blue);
	}
}

.GoalPayoffSummary {
	width: 100%;
	font-size: 14px;
	font-weight: $fontWeight--regular;
	margin: 0;
	padding: 0;
	border-collapse: collapse;

	th {
		padding: 30px 8px 8px;
		position: relative;

		&:first-child {
			padding-left: 0;
		}
	}

	td {
		padding: 6px 8px;

		&:first-child {
			padding-left: 0;
		}
	}

	&__minimum,
	&__blaster {
		text-align: right;

		&Icon {
			width: 9px;
			height: 9px;
			position: absolute;
			top: 12px;
			right: 8px;
			display: block;
			background: var(--dark-blue);
			border-radius: 50%;
		}
	}

	&__blaster {
		&Icon {
			background: var(--green)
		}
	}

	&__type,
	&__minimum,
	&__blaster {
		&--saved {
			font-weight: $fontWeight--bold;
		}
	}

	&__blaster {
		background-color: rgba(255, 255, 255, 0.16)
	}

	th.GoalPayoffSummary__blaster {
		border-radius: 10px 10px 0 0;
	}
	td.GoalPayoffSummary__blaster--saved {
		border-radius: 0 0 10px 10px;
		padding-bottom: 12px;
	}
}
