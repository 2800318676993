@import './Variables.scss';

.App {
	min-height: 100%;
	display: flex;
	background-color: #f7f7fa;
	width: 100%;

	&__main {
		min-height: 100%;
		flex: 1;
		box-sizing: border-box;

		&--standard {
			padding-top: $header-desktop-height;
		}

		&--fullscreen {
			padding-top: 0;
		}
	}
}

.noscroll {
	overflow: hidden;
}

@media screen and (max-width: $size-tablet-max) {
	.App {
		flex-direction: column;

		&__main {

			&--standard {
				padding-top: 0;
			}
		}
	}
}
