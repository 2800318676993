@import "../../Variables.scss";

.MainNavigation {
	overflow: hidden;

	&__menu {
		background-color: #fff;
		height: 100vh;
		display: flex;
		flex-direction: column;
	}

	&__header {
		display: flex;
		padding: 0 18px;
		align-items: center;
		justify-content: center;
		height: 88px;
	}

	&__menuButton {
		background-color: inherit;
		border: none;
		cursor: pointer;
		position: absolute;
		left: 18.5px;
		top: 27.5px;
		padding: 0;
		width: 33px;
		height: 33px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__menuIcon {
		width: 22px;
		height: 22px;
	}

	&__closeButton {
		background-color: inherit;
		border: none;
		cursor: pointer;
		position: absolute;
		right: 18.5px;
		top: 27.5px;
		padding: 0;
		width: 33px;
		height: 33px;
		display: none;
	}

	&__closeIcon {
		width: 14px;
		height: 14px;
	}

	&__logo {
		max-height: 30px;
		max-width: 150px;

		&Container {
			flex: 1;
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}
	}

	&__item {
		font-size: 14px;
		font-weight: $fontWeight--regular;
		border-left: 4px solid transparent !important;
		padding: 14px 20px 14px 16px !important;
		color: #707070 !important;
		overflow-x: hidden;

		&Icon {
			width: 24px;
			height: 24px;
			fill: var(--light-grey);
			margin: 0 3px;
			overflow: visible;
		}

		span {
			white-space: nowrap;
		}

		&:hover {
			background: #f7f7fa !important;
		}

		&--active {
			font-weight: $fontWeight--bold;
			border-left-color: var(--blue) !important;
			color: var(--blue) !important;
			background: #f7f7fa !important;

			.MainNavigation__itemIcon {
				fill: var(--blue);
			}
		}

		&--mobileOnly {
			display: none !important;
		}
	}

	&__profile {
		display: flex;
		align-items: center;
		flex: 1;

		&Avatar {
			background: #00000059;
			border-radius: 50%;
			font-size: 17px;
			font-weight: $fontWeight--regular;
			color: #fff;
			width: 38px;
			height: 38px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 9px;
			flex: 0 0 38px;
		}

		&Meta {
			white-space: nowrap;
		}

		&Name {
			font-size: 13px;
			font-weight: $fontWeight--medium;
			margin-bottom: 2px;
		}
	}

	&__submenu {
		border-top: 1px solid #f7f7f7;
		min-height: 70px;

		&.MuiAccordion-root {
			margin: 0 !important;
			padding: 0;
			box-shadow: none;

			&::before {
				display: none;
			}
		}

		& .MuiAccordionSummary-root {
			padding: 0 16px 0 21px;
			background: transparent !important;
		}

		& .MuiAccordionSummary-content.Mui-expanded {
			margin: 0;
		}

		& .MuiAccordionDetails-root {
			padding: 0;
		}

		& .MuiList-root {
			width: 100%;
			padding-top: 0;
		}
	}

	&__score {
		font-size: 11px;
		font-weight: $fontWeight--regular;
		color: #707070;
		max-width: 112px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__aimee {
		margin: 0 16px 20px 16px;

		&Button {
			width: 100%;
			background-color: var(--blue) !important;
			color: var(--white) !important;
			text-transform: none !important;
			border-radius: 10px !important;
			font-weight: $fontWeight--bold !important;
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
			padding: 7px 14px !important;
		}

		&Icon {
			width: 29px;
			height: 29px;
			padding: 3px;
			overflow: visible;

			&Container {
				width: 35px;
				height: 35px;
				background-color: var(--white);
				border-radius: 50%;
				margin-right: 23px;
			}
		}
	}

	&__footer {
		width: 100%;
	}

	&__secondaryLogo {
		width: 100%;
		height: auto;

		&Container {
			text-align: center;
			margin: 10px auto 35px;
			max-height: 130px;
			max-width: 130px;
			display: block;
		}

		&--mobileOnly {
			display: none;
		}
	}

	&__sections {
		flex: 1 1;
		overflow-y: auto;
		overflow-x: hidden;
		scrollbar-width: thin;

		&::-webkit-scrollbar-track {
			background-color: #f0f0f0;
		}

		&::-webkit-scrollbar {
			width: 6px;
			background-color: #f5f5f5;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #cdcdcd;
		}
	}
}

@media screen and (max-width: $size-tablet-max) {
	.MainNavigation {
		margin-top: 53px;

		&__overlay {
			position: fixed;
			left: 0;
			top: 0;
			height: 100%;
			z-index: 100;
		}

		&__menu {
			width: 322px;
			transition: margin-left 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		}

		&__menuButton {
			top: 10px;
			left: 19px;
		}

		&__aimee {
			margin: 20px 20px;

			&Button {
				height: 59px;
				font-size: 19px !important;
			}

			.MuiButton-label {
				justify-content: flex-start;
				padding: 0 6px;
			}

			&Icon {
				width: 36px;
				height: 36px;

				&Container {
					width: 42px;
					height: 42px;
					margin-right: 40px;
				}
			}
		}

		&__item {
			height: 65px;
			font-size: 16px;
			padding: 14px 16px 14px 30px !important;

			&Icon {
				width: 27px;
				height: 27px;
				margin-right: 12px;
			}

			&--mobileOnly {
				display: flex !important;
			}
		}

		&__footer {
			display: none;
		}

		&__secondaryLogo {
			&--mobileOnly {
				display: block;
			}
		}

		&__profile {
			&Avatar {
				margin-right: 17px;
			}

			&Name {
				font-size: 15px;
			}
		}

		&__score {
			font-size: 13px;
		}
	}

	.MainNavigation.MainNavigation--mobile-collapsed {
		.MainNavigation {
			&__overlay {
				width: 0;
				background-color: #38383800;
				transition: background-color 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
					width 0ms cubic-bezier(0.4, 0, 0.2, 1) 240ms;
			}

			&__menu {
				margin-left: -322px;
			}

			&__header {
				position: fixed;
				background-color: #fff;
				left: 0;
				top: 0;
				width: 100%;
				height: 53px;
				z-index: 150;
				box-shadow: 0 1px 4px #0000001a;
				padding: 0px;

				.MainNavigation__logoContainer {
					justify-content: center;
				}
			}

			&__aimee {
				margin: 73px 20px 20px;
			}
		}
	}

	.MainNavigation.MainNavigation--mobile-expanded {
		.MainNavigation {
			&__overlay {
				width: 100%;
				background-color: #383838d4;
				transition: background-color 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			}

			&__menu {
				margin-left: 0;
			}

			&__header {
				height: 53px;
				position: relative;

				.MainNavigation__logoContainer {
					justify-content: center;
				}
			}

			&__closeButton {
				display: block;
				top: 10px;
				right: 19px;
			}
		}
	}
}

@media screen and (min-width: $size-desktop-min) {
	.MainNavigation {
		&__menu {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			transition: width 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		}

		&__logo {
			transition: opacity 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
				visibility 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

			&Container {
				margin-left: 35px;
			}
		}

		&__item {
			overflow: hidden;
			&Label {
				overflow: hidden;
				white-space: nowrap;
				transition: opacity 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
					width 45ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			}
		}

		&__aimee {
			margin: 0 13px 20px 13px;
			transition: margin 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

			&Button {
				padding: 7px !important;
			}

			&Icon {
				&Container {
					transition: margin 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
				}
			}

			.MuiButton-label {
				overflow: hidden;
				justify-content: flex-start;
				white-space: nowrap;
				transition: padding 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

				span {
					width: 95px;
					overflow: hidden;
					transition: opacity 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
						visibility 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
						width 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
				}
			}
		}

		&__secondaryLogo {
			margin: 0 auto 0;
			transition: opacity 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
				visibility 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		}

		&__profile {
			&Meta {
				transition: opacity 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
					visibility 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
					height 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			}
		}
	}

	.MainNavigation.MainNavigation--desktop-collapsed {
		width: 70px;
		flex: 0 0 70px;

		.MainNavigation {
			&__menu {
				width: 70px;
			}

			&__logo {
				opacity: 0;
				visibility: collapse;
			}

			&__item {
				&Label {
					opacity: 1;
					width: 0;
				}

				&Icon {
					overflow: visible;
				}
			}

			&__aimee {
				margin: 0 8px 20px;

				&Button {
					min-width: 54px;
				}

				&Icon {
					&Container {
						margin-right: 2px;
						margin-left: 2px;
					}
				}

				.MuiButton-label {
					span {
						opacity: 0;
						visibility: collapse;
						width: 0;
					}
				}
			}

			&__secondaryLogo {
				opacity: 0;
				visibility: collapse;
			}

			&__submenu {
				& .MuiAccordionSummary-root {
					padding: 0 8px;
					flex-direction: column;
				}

				& .MuiAccordionSummary-expandIcon {
					margin-right: 0;
				}

				& .MuiAccordionSummary-content.Mui-expanded {
					margin: 12px 0;
				}
			}

			&__profile {
				justify-content: center;
				flex-direction: column;

				&Avatar {
					margin-right: 0px;
				}

				&Meta {
					visibility: collapse;
					opacity: 0;
					height: 0;
				}
			}
		}
	}

	.MainNavigation.MainNavigation--desktop-expanded {
		width: 226px;
		flex: 0 0 226px;

		.MainNavigation {
			&__menu {
				width: 226px;
			}

			&__item {
				&Label {
					width: 149px;
					margin-left: 12px;
				}
			}

			&__aimee {
				.MuiButton-label {
					padding: 0 7px;
				}
			}

			&__profile {
				&Meta {
					height: 28px;
				}
			}
		}
	}
}
