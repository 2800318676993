@import "../../Variables.scss";

.MyFinancialsWelcome {
	@media screen and (min-width: $size-tablet-min) {
		display: grid;
		place-content: center;
		min-height: 100vh;
	}

	&__container {
		background: linear-gradient(129deg, var(--light-blue), var(--blue));
		box-shadow: 2px 4px 20px #00000029;
		color: var(--white);
		text-align: center;
		position: relative;

		padding: 74px 22px 20px;
		min-height: 100vh;
		box-sizing: border-box;

		@media screen and (min-width: $size-tablet-min) {
			padding: 54px 112px 34px;
			border-radius: 15px;
			min-height: auto;
		}
	}

	&__close {
		position: absolute;
		top: 24px;
		right: 21px;
		padding: 0;
		border: none;
		background-color: transparent;
		cursor: pointer;

		&Icon {
			width: 14px;
			aspect-ratio: 1;
			color: var(--white);
		}
	}

	&__heading {
		font-size: 30px;
		font-weight: 600;
	}

	&__card {
		display: flex;
		flex-direction: column;
		align-items: center;

		p {
			line-height: 1.3;
			margin: 18px 0 40px;
			max-width: 450px;
		}
	}

	&__image {
		display: grid;
		place-content: center;
	}

	&__circle {
		width: 177px;
		aspect-ratio: 1;
		border-radius: 50%;
		background-color: #ffffff17;
	}

	&__dots {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;

		@media screen and (min-width: $size-desktop-min) {
			gap: 6px;
		}
	}

	&__dot {
		border: none;
		padding: 0;
		width: 10px;
		aspect-ratio: 1;
		border-radius: 50%;
		background-color: #07385333;
		cursor: pointer;

        @media screen and (min-width: $size-desktop-min) {
            width: 8px;
		}

		&--selected {
			background-color: #073853cc;
		}
	}

	&__actions {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 44px;

		.MuiButton-containedPrimary,
		.MuiButton-containedPrimary:hover {
			background-color: #ffffff40;
			border-radius: 10px;
			box-shadow: none;
			margin-top: 26px;
		}

		p {
			margin: 0;
			font-size: 13px;
			font-weight: 500;
			line-height: 1.5;
			opacity: 0.8;

			&:first-of-type {
				margin-top: 18px;
			}
		}
	}
}
