@import "../../../Variables.scss";

.session {
	font-size: 14px;
	line-height: 1.5;
	padding: 0 24px 0 25px;

	h1 {
		font-size: 20px;
		color: var(--dark-blue);
	}

	h2 {
		font-size: 15px;
		color: var(--blue);
	}

	h3 {
		font-size: 14px;
		color: var(--dark-grey);
		margin: 0;
	}

	&__details a {
		font-weight: 500;
		color: var(--blue);

		&:visited,
		&:active {
			color: var(--blue);
		}
	}

	&__nav {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 10px;
	}

	&__back {
		&__icon {
			padding: 15px;
			margin: -15px 10px -15px -15px;

			svg {
				width: 12px;
				height: 18px;
				transform: rotate(180deg);
				color: #9d9d9d;
			}
		}
	}

	&__status {
		position: absolute;
		top: 19px;
		right: 15px;

		&__icon {
			width: 26px;
			height: 26px;
			color: #e6e6e6;

			&--registered {
				color: var(--blue);
			}
		}
	}

	&__title {
		margin: 38px 0 18px;

		h1 {
			margin-top: 0;
		}
	}

	&__details {
		display: flex;
		flex-direction: column;
		padding-top: 20px;
		border-top: 1px solid var(--x-light-grey);
		margin-bottom: 35px;

		h2,
		&__item {
			margin-bottom: 16px;

			h3 {
				margin-bottom: 3px;
			}
		}

		&__map {
			width: 100%;
			height: 200px;
		}

		&__vendorImage {
			max-width: 200px;
			max-height: 100px;
		}
	}

	&__meta {
		line-height: 1.2;
	}
}

@media screen and (min-width: $size-desktop-min) {
	.session {
		padding: 0 29px;

		&__nav {
			justify-content: flex-end;
			margin-top: 30px;
		}

		&__back {
			display: none;
		}

		&__status {
			position: absolute;
			top: 20px;
		}

		&__title,
		&__meta,
		&__form,
		&__details {
			margin-left: 23px;
			margin-right: 23px;
		}

		&__details {
			flex-direction: row;
			flex-wrap: wrap;

			h2 {
				flex: 0 0 100%;
				margin-bottom: 14px;
			}

			&__item {
				flex: 0 0 100%;
				margin-bottom: 23px;
			}

			&__duration {
				flex: 0 0 200px;
			}

			&__facilitator {
				flex: 1;
			}

			&__map {
				height: 200px;
			}
		}
	}
}

@media (max-width: 500px) {
	.session {
		&__title {
			margin-right: 24px;
		}
	}
}
