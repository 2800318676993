@import "../../Variables.scss";

.QuestionFlow {
	background-color: #f7f7fa;
	height: 100%;
	width: 100%;

	.LoadingSpinner {
		height: auto;
	}
	
	&--onboarding {
		min-height: 100%;
		display: flex;
	}

	&__debug {
		position: fixed;
		left: 0;
		bottom: 0;
		margin: 0 0 10px 10px;
		font-size: 12px;
		line-height: 1.35;
		color: #aaa;
	}

	&__container {
		width: 848px;
		height: 100%;
		margin: 0 auto;
	}

	&__questions {
		height: 100%;
		display: flex;
		flex-direction: column;
		padding-top: 25%;
		box-sizing: border-box;

		&--onboarding {
			padding-top: 20px !important;
			padding-bottom: 20px !important;
			justify-content: center;
		}
	}

	&__question {
		width: 100%;
		position: relative;

		.QuestionFlow__chatContainer > * {
			opacity: 0.3;
		}

		.QuestionFlow__reanswer {
			opacity: 1 !important;
		}

		&--active {
			.QuestionFlow__chatContainer > * {
				opacity: 1;
			}

			.QuestionFlow__chat--user .QuestionFlow__chatIcon {
				opacity: 1;
			}
		}

		&--onboarding {
			display: flex;
			justify-content: center;
		}
	}

	&__chat {
		$__chat: &;
		display: flex;
		align-items: flex-end;
		margin-bottom: 20px;

		&MessageContainer {
			flex: 1;
			display: flex;
			padding-right: 49px;

			&--user {
				padding-left: 98px;
				padding-right: 0;
			}
		}

		&Message {
			background-color: var(--white);
			border-radius: 10px;
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.11);
			padding: 15px 20px;
			flex: 1;
			font-size: 15px;
			font-weight: $fontWeight--regular;
			line-height: 1.46;
			min-height: 51px;
			box-sizing: border-box;
		}

		&Description {
			font-size: 13px;
			font-weight: $fontWeight--regular;
			line-height: 1.54;
			margin: -10px 0 10px;
			padding-left: 70px;
			color: #707070;
		}

		&Icon {
			width: 34px;
			height: 34px;
			flex: 0 0 34px;
			margin-right: 15px;
		}

		&--aimee {
			#{ $__chat }Message {
				border-bottom-left-radius: 0;
			}
		}

		&--user {
			flex-direction: row-reverse;

			#{ $__chat }Icon {
				margin-right: 0;
				margin-left: 15px;
				opacity: 0;
			}
		}
	}

	&__next {
		background: var(--blue) !important;
		margin: 0 0 20px 124px !important;
		color: var(--white) !important;
		text-transform: capitalize !important;
		font-size: 15px !important;
		font-weight: $fontWeight--bold !important;
		line-height: 1.33 !important;
		padding: 14px 21px !important;
		border-radius: 5px !important;

		&.Mui-disabled {
			background: var(--x-light-grey) !important;
		}

		&--singleEntry {
			margin-left: 0 !important;
		}
	}

	&__logo {
		height: 40px;
		width: 160px;
		position: fixed;
		top: 18px;
		left: 33px;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		&Img {
			max-height: 40px;
			max-width: 160px;
		}
	}

	&__exit {
		background: transparent;
		position: fixed;
		top: 27px;
		right: 27px;
		color: #707070;
		border: 1px solid #707070;
		text-transform: uppercase;
		font-size: 13px;
		font-weight: $fontWeight--medium;
		display: inline-block;
		border-radius: 11px;
		padding: 3px 14px;
		cursor: pointer;
	}

	&__navAlternative {
		position: fixed;
		background: var(--blue);
		background: linear-gradient(270deg, var(--light-blue) 0%, var(--blue) 100%);
		width: 100%;
		height: 56px;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1000;
		box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
		padding: 0 12px;
		box-sizing: border-box;
		font-size: 12px;
		font-weight: $fontWeight--regular;
		font-style: italic;
		color: var(--white);

		&Exit {
			flex: 0 0 144px;
			font-size: 13px; 
			font-weight: $fontWeight--bold;
			color: var(--white);
			border: 0;
			border-radius: 8px;
			background: var(--dark-blue);
			margin: 0 12px 0 0;
			padding: 7px 5px;
			cursor: pointer;
		}
	}

	&__reanswer {
		margin-bottom: 10px;
		cursor: pointer;
		border: 0;
		background: transparent;
		font-size: 13px;
		font-style: italic;
		color: var(--blue);
		padding: 0;
		margin-left: 49px;

		&Icon {
			width: 10px;
			height: 10px;
			fill: var(--white);

			&Container {
				background: var(--blue);
				border-radius: 50%;
				display: inline-flex;
				width: 14px;
				height: 14px;
				align-items: center;
				justify-content: center;
				margin-right: 3px;
			}
		}
	}

	&__questionEdit {
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.6);
		display: flex;
		align-items: center;
		justify-content: center;

		&Container {
			background-color: #fff;
			width: 800px;
			padding: 30px 40px;
			font-size: 22px;
			line-height: 1.6;
			font-weight: $fontWeight--regular;
			text-align: center;
		}
	}

	&__editItem {
		margin-bottom: 10px;
	}

	&__label {
		display: block;
		text-align: left;
	}

	&__achievementIcon {
		margin-right: 18px;
		width: 15px;
		height: 22px;
		vertical-align: middle;
		margin-top: -3px;
	}

	&__informational {
		margin-top: -8%;

		&Title {
			color: #073853;
			font-size: 35px;
			font-weight: $fontWeight--regular;
			margin: 0 0 25px;
			padding: 0;

			strong {
				font-weight: $fontWeight--bold;
			}

			img {
				width: 38px;
				height: 38px;
				margin: 0 20px -6px 0;
			}
		}

		&Description {
			color: #383838;
			font-size: 18px;
			font-weight: $fontWeight--regular;
			margin: 0 0 54px;
			padding: 0;
		}

		&Content {
			background-color: var(--white);
			border-radius: 10px;
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.11);
			display: flex;
			padding: 35px 50px;
			margin: 0 0 20px;
			align-items: center;

			&Intro {
				flex: 1;
				font-size: 18px;
				line-height: 1.4;
				font-weight: $fontWeight--regular;

				strong {
					font-weight: $fontWeight--bold;
				}
			}

			&Item {
				display: flex;
				flex-direction: column;
				align-items: center;
				font-size: 15px;
				line-height: 1.46;
				font-weight: $fontWeight--medium;
				text-align: center;
				flex: 0 0 148px;
				margin-left: 30px;

				img {
					margin-bottom: 20px;
				}
			}
		}

		&VideoVideo {
			width: 100%;
			aspect-ratio: 16 / 9;
		}

		&Submit {
			display: none;
			margin: 38px 0 0;
			min-width: 141px;
			padding: 15px;
			box-sizing: border-box;
			color: var(--white);
			background: var(--blue);
			border: 0;
			border-radius: 5px;
			text-align: center;
			font-size: 15px;
			font-weight: $fontWeight--bold;
			cursor: pointer;
		}
	}

	&__question--active &__informational {
		&Submit {
			display: inline-block;
		}
	}

	&__accordion {

		&::before {
			display: none;
		}

		.MuiAccordionDetails-root {
			display: block;
			padding-top: 0;
		}

		&.MuiAccordion-root.Mui-expanded {
			// margin: 0;
		}

		&Title {
			min-height: auto !important;
		}

		.MuiAccordionSummary-content {
			font-size: 15px;
			font-weight: $fontWeight--medium;
		}

		.MuiAccordionSummary-content.Mui-expanded {
			margin: 10px 0 !important;
		}

		.MuiIconButton-label {
			svg {
				color: var(--blue);
			}
		}

		&Details {
			font-size: 15px;
			line-height: 1.46;
		}

		&SearchFor {
			margin-bottom: 0;

			a {
				color: var(--blue);
			}
		}
	}

	&__adjustedQuestion {
		margin-bottom: 14px;
	}

	table {
		border-collapse: collapse;
		border: 1px solid var(--blue);
		background: var(--white);
		margin: 16px 0;
		max-width: 100%;
	}

	th, td {
		padding: 4px 6px;
	}

	th {
		background: var(--blue);
		color: var(--white);
	}

	td {
		border: 1px solid #ddd;
	}

	a {
		color: var(--blue);
	}

	&__benefitAnswer {
		border-radius: 10px;
		border: 2px solid #6aaad8;
		background-color: #eaf0f6;
		padding: 60px 25px 25px;
		position: relative;

		&Tag {
			position: absolute;
			background: var(--blue);
			color: var(--white);
			top: 15px;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 178px;
			height: 29px;
			border-radius: 20px 0 0 20px;
			font-size: 14px;
			font-weight: $fontWeight--bold;
		}

		p {
			
			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.questionLoading {
	opacity: 0;
	animation: chatAnimation 0.8s ease forwards;
	transform: translateY(20px);
}

@keyframes chatAnimation {
	to {
		transform: translateY(0);
		opacity: 1;
	}
}

.answers {
	$self: &;
	width: 100%;

	&__container {
		flex: 1 1;
		display: flex;
		padding-right: 0;
		padding-left: 49px;
		justify-content: flex-end;

		&--selectOne,
		&--selectMany,
		&--interstitial,
		&--agent {
			flex-wrap: wrap;
			padding-left: 124px;

			#{ $self }__answer {
				border: 2px solid #a0aec0;
				border-radius: 10px;
				box-shadow: 0 3px 6px rgba(0, 0, 0, 0.11);
				background: var(--white);
				width: 100%;
				margin-bottom: 10px;
				font-size: 15px;
				line-height: 1.46;
				font-weight: $fontWeight--regular;
				padding: 11px 20px;
				text-align: left;
				cursor: pointer;
				display: flex;
				justify-content: space-between;
				min-height: 49px;
				box-sizing: border-box;

				&Icon {
					border: 2px solid #a0aec0;
					border-radius: 50%;
					display: block;
					width: 19px;
					height: 19px;
					flex: 0 0 19px;
					margin-left: 16px;

					&--selected {
						fill: var(--blue);
						color: var(--blue);
						width: 28px;
						height: 28px;
						flex: 0 0 28px;
						margin: -2px -3px -3px 5px;
					}
				}

				&:last-child {
					margin-bottom: 0;
				}

				&:hover,
				&--selected {
					border-color: var(--blue);
				}

				&:only-child {
					#{ $self }__answerIcon,
					#{ $self }__answerIcon--selected {
						display: none;
					}
				}
			}
		}

		&--selectOneFancy,
		&--navigation {
			#{ $self }__answer {
				width: 180px;
				background-color: var(--white);
				border: 2px solid #a0aec0;
				border-radius: 10px;
				margin-left: 10px;
				box-shadow: 0 3px 6px rgba(0, 0, 0, 0.11);
				cursor: pointer;
				font-size: 15px;
				line-height: 1.46;
				font-weight: $fontWeight--regular;
				padding: 25px 22px;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;

				&ImageContainer {
					display: block;
					width: 75px;
					height: 75px;
					margin: 0 auto 18px;
					position: relative;
				}

				&Image {
					max-height: 70px;
					max-width: 70px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 100%;
					height: 100%;
				}

				&Title {
					font-size: 14px;
					font-weight: $fontWeight--medium;
					line-height: 1.43;
					margin: 0 0 12px;
					padding: 0;
					text-align: center;
					width: 100%;
				}

				&:hover,
				&--selected {
					border-color: var(--blue);
				}

				&:disabled {
					opacity: 0.3;
				}
			}
		}

		&--singleEntry {
			flex-wrap: wrap;
			padding-left: 124px;
			justify-content: flex-start;
			flex-direction: column;
			align-items: flex-start;

			&Container {
				width: 100%;
			}

			.answers--singleEntryContainer {
				width: 100%;
			}

			.QuestionFlow__next {
				margin-top: 10px !important;
			}
		}
	}

	&__error {
		margin: 10px 0 19px;
		width: 100%;
		font-size: 13px;
		font-weight: $fontWeight--regular;
		color: #707070;

		&--singleEntry {
			margin-bottom: 10px;
		}
	}
}

.myScore {
	position: absolute;
	top: 0;
	right: -46px;
	display: flex;
	width: 75px;
	background: var(--white);
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
	border-radius: 10px;
	flex-direction: column;
	align-items: center;
	padding: 20px 10px;
	box-sizing: border-box;

	&__icon {
		width: 20px;
		height: 15px;
		margin: 0;
		padding: 0;
	}

	&__title {
		font-size: 13px;
		font-weight: $fontWeight--medium;
		color: #707070;
		margin: 5px 0 5px;
	}

	&__score {
		font-size: 23px;
		font-weight: $fontWeight--bold;
		color: var(--blue);
	}

	&__total {
		font-size: 9px;
		color: #9d9d9d;
		margin-top: 4px;
	}
}

.onboarding {
	max-width: 700px;
	border-radius: 20px;
	box-shadow: 0 24px 65px rgba(0, 0, 0, 0.16);
	padding: 52px 59px;
	box-sizing: border-box;
	background: var(--white) url(https://cms.financialfinesse.com/rafpress/media/ba67a2c7-56b8-4467-94bf-74c83d6994d0/ffhub-onboarding-bg.svg) center center no-repeat;
	background-size: 100% auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;

	&__header {
		display: flex;
		align-items: center;
		width: 100%;
		margin-bottom: 35px;
	}

	&__back {
		width: 30px;
		height: 30px;
		border: 0;
		background: transparent;
		color: var(--x-light-grey);
		cursor: pointer;

		&--hidden {
			opacity: 0;
			cursor: auto;
		}

		&Icon {
			fill: var(--grey);
			width: 16px;
			height: 16px;
		}
	}
	
	&__skip {
		color: var(--light-grey);
		font-size: 12px;
		border: 0;
		background: transparent;
		cursor: pointer;
		letter-spacing: 0.08em;
	}

	&__logo {
		flex: 1;
		display: flex;
		justify-content: center;

		&Icon {
			width: 126px;
		}
	}

	&__image {
		width: 351px;
		height: 228px;
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
	}

	&__indicators {
		margin: 38px 0 31px;
		display: flex;
		justify-content: center;
		gap: 10px;
		height: 6px;
		width: 100%;
	}

	&__indicator {
		width: 6px;
		height: 6px;
		background: var(--light-grey);
		border-radius: 3px;

		&--active {
			width: 28px;
			background: var(--blue);
		}
	}

	&__title {
		font-weight: $fontWeight--bold;
		font-size: 26px;
		color: var(--darker-blue);
		margin: 0 0 19px;
		padding: 0;
		width: 350px;
		text-align: center;
	}

	&__description {
		font-weight: $fontWeight--regular;
		font-size: 16px;
		color: var(--dark-grey);
		margin: 0;
		padding: 0;
		width: 350px;
		text-align: center;
		line-height: 1.625;
	}

	&__submit {
		background: var(--blue) !important;
		color: var(--white) !important;
		font-size: 15px !important;
		border-radius: 50px !important;
		width: 149px;
		height: 54px;
		margin-top: 36px !important;
	}
}

@media (max-width: 1250px) {
	.QuestionFlow {
		&__nav {
			position: fixed;
			background: var(--white);
			width: 100%;
			height: 53px;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 1000;
			box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
		}

		&__logo {
			position: static;
			justify-content: center;
		}

		&__exit {
			top: 15px;
			right: 20px;
		}

		&__debug {
			display: none;
		}
	}
}

@media (max-width: 992px) {
	.QuestionFlow {
		&__informational {
			padding-left: 20px;
			padding-right: 20px;

			&Title {
				img {
					display: none;
				}
			}

			&Description {
				font-size: 16px;
				line-height: 1.6;
			}

			&Content {
				flex-wrap: wrap;
				text-align: center;
				justify-content: center;

				&Intro {
					flex: 0 0 100%;
					margin-bottom: 27px;
				}

				&Item {
					margin-bottom: 27px;
				}
			}
		}
	}
}

@media (max-width: 977px) {
	.QuestionFlow {
		&__container {
			width: calc(100% - 128px);
			box-sizing: border-box;
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	.answers {
		&__container {
			&--selectOneFancy,
			&--navigation {
				flex-wrap: wrap;
				justify-content: flex-start;
			}

			&--selectOneFancy .answers__answer,
			&--navigation .answers__answer {
				font-size: 14px;
				line-height: 1.29;
				padding: 20px;
				width: 148px;
				flex: none;
				min-height: 170px;
				margin: 0 8px 8px 0;

				&:first-child {
					margin-left: 0;
				}
			}

			&--selectOneFancy .answers__answerImageContainer,
			&--navigation .answers__answerImageContainer {
				width: 60px;
				height: 60px;
				margin-bottom: 12px;
			}

			&--selectOneFancy .answers__answerImage,
			&--navigation .answers__answerImage {
				max-width: 55px;
				max-height: 55px;
			}
		}
	}
}

@media (max-width: 768px) {
	.QuestionFlow {
		&__question {
			position: static;
		}

		&__container {
			width: 100%;
		}

		&__chat {
			margin-bottom: 20px;

			&Message {
				font-size: 14px;
				min-height: 50px;
				padding: 12px 15px;

				&Container {
					padding-right: 42px;

					&--user {
						padding-left: 98px;
						padding-right: 0;
					}
				}
			}

			&Icon {
				width: 21px;
				height: 21px;
				flex: 0 0 21px;
				margin-right: 10px;
			}

			&--user &Icon {
				display: none;
			}

			&Description {
				padding-left: 32px;
				margin-top: -5px;
			}
		}

		&__reanswer {
			margin-left: 32px;
		}

		&__next {
			margin-left: 0 !important;
			align-self: right;

			&--selectMany {
				float: right;
			}
		}

		&__achievementIcon {
			margin-right: 5px;
		}

		&__informationalVideoVideo {
			margin-left: -30px;
			margin-right: -30px;
			width: calc(100% + 60px);
		}
	}

	.myScore {
		position: fixed;
		top: auto;
		right: auto;
		bottom: 0;
		left: 0;
		z-index: 100;
		width: 100%;
		padding: 15px 28px;
		flex-direction: row;
		border-radius: 10px 10px 0 0;
		box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);

		&__icon {
			flex: 0 0 20px;
			margin-right: 8px;
		}

		&__title {
			flex: 0 0 55px;
		}

		&__score {
			flex: 1;
			text-align: right;
		}

		&__total {
			display: none;
		}
	}

	.answers {
		&__container {
			box-sizing: border-box;
			margin-left: 32px;
			width: calc(100% - 32px);
			padding-left: 0;

			&--selectOne,
			&--selectMany,
			&--interstitial,
			&--agent {
				padding-left: 0;
			}

			&--selectOne .answers__answer,
			&--selectMany .answers__answer,
			&--interstitial .answers__answer,
			&--agent .answers__answer {
				font-size: 14px;
				min-height: 45px;
				margin-bottom: 11px;
				padding: 10px 15px;
			}

			&--singleEntry {
				justify-content: flex-end;

				.customField {
					width: 100%;
				}
			}
		}
	}
}

@media (max-width: 600px) {
	.QuestionFlow {
		&__benefitAnswer {
			padding: 55px 15px 15px;
		}
	}
}


@media (max-width: 520px) {
	.QuestionFlow {

		&--onboarding {
			background: var(--white);
			min-height: 100vh;
			display: flex;
			align-items: center;
		}

		&__questions--onboarding {
			padding-top: 0;
			padding-bottom: 0 !important;
		}

		&__logo {
			position: fixed;
			top: 7px;
			left: 25px;
		}

		&__informational {
			&Content {
				padding: 24px 20px;

				&Item {
					flex: 0 0 100%;
					padding: 0 20px;
					box-sizing: border-box;
					margin-left: 0;
				}
			}
		}
	}

	.onboarding {
		padding: 32px;
		box-shadow: none;
		border-radius: 0;

		&__title,
		&__description {
			width: 295px;
		}

		&__description {
			font-size: 14px;
			line-height: 1.43;
		}
	}
}
