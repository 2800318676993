.nilDashboard {
	display: grid;
	grid: repeat(5, min-content) / repeat(3, 1fr);
	gap: 30px;
	align-items: flex-start;
	max-width: 1200px;
	margin-block-start: 32px;
	margin-inline: 33px;
	padding-bottom: 70px;

	&__title {
		grid-column: span 2;
		margin: 0;
		color: #073853;
		font-size: 25px;
		font-weight: 600;

	}

	&__nextCourses {
		grid-column: span 2;
	}

	&__adobeChromeBanner,
	&__startBanner,
	&__banner {
		grid-column: span 2;
	}

	&__completedCourses {
		grid-column: span 2;
		.nilCourseCard__arrow {
			color: rgb(157, 157, 157) !important;
		}
	}

	&__quiz {
	}

	&__badge {
	}

	&__certificationProgress {
		grid-column: 3;
		grid-row: 1 / -1;
	}
}

@media (max-width: 1092px) {
	.nilDashboard {
		grid: repeat(5, min-content) / repeat(2, 1fr);
		max-width: 100%;

		&__certificationProgress {
			grid-column: span 2;
			grid-row: 4 / 5;
		}
	}
}

@media (max-width: 798px) {
	.nilDashboard {
		&__quiz,
		&__badge {
			grid-column: span 2;
		}
	}
}