@import "../../Variables.scss";

.intlSessionList {
	height: 100%;
	border-radius: 15px;
	display: flex;
	flex-direction: column;

	&__tabs {
		display: flex;
		justify-content: space-evenly;
		padding: 10px 0 20px;
		height: 37px;

		> button {
			flex: 1;
			background: none;
			border: none;
			border-bottom: 1px solid #efefef;
			color: #9d9d9d;
			font-size: 14px;
			font-weight: 400;
			text-align: center;
			padding: 10px;
			margin: 0;
			cursor: pointer;

			&:focus {
				outline: 0;
			}

			&.active {
				border-bottom: 2px solid var(--blue);
				padding-bottom: 9px;
				color: var(--blue);
				font-weight: 500;
			}
		}
	}

	&__list {
		flex: 1;
		overflow-y: auto;
		padding: 0 12px;
		margin-bottom: 20px;
	}

	&__empty {
		font-size: 14px;
		font-weight: $fontWeight--medium;
		margin-left: 15px;
	}

	&__sessions {
		list-style: none;
		margin-block-start: 0;
		margin-block-end: 0;
		padding-inline-start: 0;

		li > a {
			display: block;
			padding: 0 14px;
			color: #383838;

			&.active {
				background-color: #f7f7fa;
				border-radius: 10px;
				color: var(--grey);
			}
		}

		li + li {
			a > div {
				border-top: 1px solid #f7f7fa;
			}
		}
	}

	&__session {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		padding: 14px 0;

		&__date {
			flex: 0 0 34px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background-color: #077cab26;
			color: var(--blue);
			font-weight: 600;
			border-radius: 5px;
			padding: 6px;
			height: 34px;

			> div:first-child {
				font-size: 12px;
			}
			> div:last-child {
				font-size: 17px;
			}
		}

		&--registered &__date {
			background-color: #f7f7fa;
			color: #707070;
		}

		&__details {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			margin-left: 9px;
		}

		&__chevron {
			flex: 0 0 14px;
			color: var(--blue);
			margin-left: 17px;

			&__icon {
				width: 14px;
				height: 14px;
			}
		}

		&--registered &__chevron {
			color: #9d9d9d;
		}

		&__title {
			font-size: 14px;
			font-weight: 500;
			display: flex;
			align-items: center;
		}

		&__meta {
			display: flex;
			align-items: center;
			color: var(--grey);
			font-size: 12px;
			white-space: nowrap;

			> div {
				display: flex;
				align-items: flex-end;
				margin-top: 5px;
			}

			div + div {
				margin-left: 8px;
			}
		}
	}

	&__meta__icon {
		width: 12px;
		height: 12px;
		padding: 1px;
		fill: var(--grey);
		margin-right: 3px;
	}

	&__registered__icon {
		margin-left: 7px;
		width: 14px;
		height: 14px;
		color: var(--blue);
	}
}

@media screen and (min-width: $size-desktop-min) {
	.intlSessionList {
		&__session {
			&__details {
				min-width: 150px;
			}

			&__title {
				> div:first-child {
					flex: 1 1;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}
	}
}
