@import "../../Variables.scss";

.NotFound {
	min-width: 100%;
	min-height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 6%;
	box-sizing: border-box;

	&__title {
		color: var(--blue);
		font-size: 103px;
		font-weight: $fontWeight--black;
		margin: 0 0 14px;
	}

	&__subtitle {
		color: var(--dark-blue);
		font-size: 24px;
		line-height: 1.4;
		font-weight: $fontWeight--black;
		margin: 0 0 14px;
	}

	&__text {
		font-size: 16px;
		font-weight: $fontWeight--regular;
		margin: 0 0 30px;
	}

	&__link {
		font-size: 12px;
		font-weight: $fontWeight--bold;
		color: var(--white);
		background: var(--blue);
		border-radius: 5px;
		padding: 13px 20px;
	}

	&__url {
		margin-top: 20px;
		font-size: 12px;
	}
}
