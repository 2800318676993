@import "../../../../Variables.scss";

.session {
	&__controls {
		display: flex;
		flex-direction: column;
		margin-top: 17px;
		margin-bottom: 24px;
		justify-content: space-evenly;

		> .MuiButton-contained + .MuiButton-contained {
			margin-top: 13px;
		}

		&__cancel.MuiButton-contained {
			background-color: var(--red);
			color: var(--white);
			text-transform: uppercase !important;

			&:hover {
				background-color: var(--red);
			}
		}
	}

	.MuiButton-root {
		text-transform: none;
		font-size: 13px;
	}

	.form__field {
		display: flex;
		flex-direction: column;
	}

	.form__label {
		margin: 0 0 5px 0;
		display: flex;
		flex-direction: column;
		color: var(--dark-blue);
		font-size: 14px;
		font-weight: 500;

		p {
			margin-bottom: 0;
			color: var(--dark-grey);
			font-weight: 400;
		}
	}

	.form__control {
		display: flex;
		flex-direction: column;
		padding: 8px 0;
	}

	.form__sessionFull {
		margin: 20px 0;
	}

	&__smsReminder {

		&Group {
			margin-bottom: 10px;
		}

		&Label > * {
			font-size: 14px !important;
		}
	}
}

@media screen and (min-width: $size-lg) {
	.session {
		&__controls {
			flex-direction: row;
			justify-content: unset;

			> .MuiButton-contained + .MuiButton-contained {
				margin-top: unset;
				margin-left: 15px;
			}
		}

		.form__row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.form__field {
				flex: 0 0 50%;

				.form__control {
					flex: 0 0 100%;
				}
			}

			.form__field + .form__field { 
				margin-left: 10px;
			}
		}

		.form__field {
			flex-direction: row;
			justify-content: space-between;

			&--centered {
				align-items: center;

				.form__label {
					padding-top: 0;
				}
			}
		}

		.form__label {
			flex: 1 0 250px;
			margin: 0;
			padding-top: 18px;
		}

		.form__control {
			flex: 0 0 50%;
			box-sizing: border-box;
			padding: 10px 0;
		}

		.form__control--fullWidth {
			flex: 1;
		}

		.form__control + .form__control {
			margin-left: 10px;
		}
	}
}
