@import '../../../Variables.scss';

.wellnessScore {
	&__content {
		display: flex;
		// flex-direction: column;
		flex-direction: row;
		align-items: center;
	}

	&__gauge {
		max-width: 350px;
	}

	&__stats {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		flex-wrap: wrap;
		width: 100%;
		margin-top: 35px;
	}

	&__stat {
		&__value {
			display: flex;
			justify-content: center;
			align-items: center;
			// font-size: 18px;
			font-size: 13px;
			// line-height: 22px;
			line-height: 20px;
			// color: #00507c;
			color: #9d9d9d;
			font-weight: 600;
			text-align: center;
			padding-left: 12px;
		}

		&__name {
			// font-size: 11px;
			font-size: 9px;
			// line-height: 15px;
			line-height: 16px;
			// color: #707070;
			color: #9d9d9d;
			text-align: center;
		}
	}

	&__icon {
		height: 12px;
		width: 12px;

		&--green {
			color: var(--green);
		}

		&--red {
			color: var(--red);
		}
	}

	&--compact {
		width: 100%;
	}

	&--compact &__stats {
		width: auto;
		margin-top: 10px;
	}

	&--compact &__stat {
		border: 1px solid #f7f7f7;
		border-radius: 10px;
		padding: 8px 40px;

		&__value {
			// font-size: 18px;
			// line-height: 20px;
			// color: #9d9d9d;
		}

		&__name {
			// font-size: 13px;
			// line-height: 16px;
			// color: #9d9d9d;
		}
	}

	&--compact &__stat + &__stat {
		margin: 0 0 0 15px;
	}

	&--compact &__icon {
		height: 9px;
		width: 9px;
		color: #9d9d9d;
	}

	// remove this and restore commented out values when adding responsive styles
	&--compact &__content {
		flex-direction: row;
		justify-content: center;
	}
	&--compact &__gauge {
		flex: 1;
	}
	&--compact &__stats {
		flex-direction: column;
		justify-content: center;
	}
	&--compact &__stat {
		padding: 8px 14px;
	}
	&--compact &__stat + &__stat {
		margin: 15px 0 0;
	}
}

@media all and (min-width: $size-lg) {
	.wellnessScore {
		&--compact &__content {
			flex-direction: row;
			justify-content: center;
		}

		&--compact &__gauge {
			width: auto;
			margin-top: -15px;
		}

		&--compact &__stats {
			flex-direction: column;
			justify-content: center;
		}

		&--compact &__stat {
			padding: 8px 14px;

			&__value {
				font-size: 13px;
				line-height: 20px;
				color: #9d9d9d;
			}

			&__name {
				font-size: 9px;
				line-height: 16px;
				color: #9d9d9d;
			}
		}

		&--compact &__stat + &__stat {
			margin: 15px 0 0;
		}
	}
}
