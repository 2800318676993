@import "../../../Variables.scss";
/**
 *
 * CONTENTS
 * Note: Not every calculator requires specific overrides.
 *
 * BASE STYLES
 * save_for_goals
 * cost_of_credit
 * eduction_savings
 * mortgage_comparison
 * mortgage_payment
 * pretax_savings
 * retirement_estimator
 * tax_break
 * weighted_avg
 * ira_calc
 *
 */

/**
 * BASE STYLES
 */

.ff-calc {
	/* 
        SHARED STYLES 
	*/

	&-container {
		& * {
			box-sizing: border-box;
		}

		&::after {
			content: "";
			display: block;
			clear: both;
		}

		a {
			color: var(--blue);
		}

		& table {
			border-collapse: separate;
			border-spacing: 2px;
			width: 100%;

			th,
			td {
				padding: 4px 2px;
			}
		}

		#ff-calc-header {
			margin-bottom: 28px;
		}

		h3 {
			margin: 0 0 10px;
			padding: 0 102px 0 0;
			font-size: 22px;
			line-height: 1.27;
			font-weight: $fontWeight--bold;
			color: var(--dark-blue);
		}

		hr {
			display: none;
		}
	}

	/*
        COLUMNS
    */

	&-column-container {
		width: 100%;
	}

	&-tab-container {
		padding: 0 !important;
	}

	// COLUMN WIDTHS
	&-column {
		&1 {
			width: 100%;
			clear: both;
			float: none;
			position: relative;
			padding: 0 !important;
		}

		&2 {
			width: calc(50% - 10px);
			margin-right: 10px;
			float: left;
			position: relative;
			padding: 0 !important;
		}

		&3-1 {
			width: calc(33.33% - 10px);
			margin-right: 10px;
			float: left;
			position: relative;
			padding: 0 !important;
		}

		&3-2 {
			width: calc(66.66% - 10px);
			margin-right: 10px;
			float: left;
			position: relative;
			padding: 0 !important;
		}

		&-last {
			margin-right: 0;
		}
	}

	&-text {
		font-size: 14px;
		font-weight: $fontWeight--regular;
		line-height: 1.57;
	}

	// SECTION TITLE BAR
	&-bar {
		margin: 15px 0 10px;
		color: #383838;
		font-weight: $fontWeight--medium;
	}

	// SECTION BACKGROUND
	&-panel {
		background-color: #f7f7fa;
		margin-bottom: 5px;
		border-radius: 10px;
		padding: 15px !important;
	}

	&-input-container {
		text-align: left;
		margin: 0;
		padding: 10px;
	}

	&-table {
		margin: 0;
		padding: 2px 10px;
	}

	&-label {
		display: block;
	}

	&-input {
		box-sizing: border-box;
		padding: 3px 5px;
		background-color: #fff;
		border: 1px solid #aaa;
		color: var(--dark-blue);
		border-radius: 2px;
		width: 100%;
		text-align: right;

		&:disabled {
			color: #bbb !important;
		}

		&-header {
			padding: 10px;
			color: #077cab;
			font-weight: 400;
		}
	}

	&-button {
		font: inherit;
		margin: 10px 0 0;
		background: var(--dark-blue);
		color: var(--white);
		border-radius: 5px;
		padding: 8px 15px;
		border: 0;
		font-weight: $fontWeight--medium;
		cursor: pointer;
	}

	&-slider-input {
		border: 1px solid #aaa;
		color: var(--dark-blue);
	}

	&-value,
	&-input {
		font-size: 1.5em;
		text-align: right;
		border-radius: 4px;
	}

	&-value-padded {
		padding: 5px 0;
	}

	// COLUMN OVERRIDES FOR INPUTS

	&-column1 &-input-2column {
		width: 50%;
		position: relative;
		float: left;
	}

	&-column2 &-input-2column {
		width: 50%;
		position: relative;
		float: left;
	}

	&-column1 &-input-3column {
		width: 33.33%;
		position: relative;
		float: left;
	}

	&-column1 &-input-3column-2 {
		width: 66.66%;
		position: relative;
		float: left;
	}

	&-highlight {
		position: relative;
		float: left;
		background-color: #04096f;
		padding: 3px;
	}

	&-highlight-success {
		color: #282;
	}

	&-highlight-notice {
		color: #24b;
	}

	&-highlight-alarm {
		color: #c00;
	}

	&-highlight-warning {
		color: #822;
	}

	&-graph {
		position: relative;
		height: 350px;
		border-radius: 10px;
		margin: 5px 0 20px;
	}

	/* helper styles */
	&-right {
		text-align: right;
	}

	&-float-right {
		float: right;
	}

	&-float-left {
		float: left;
	}

	&-centered {
		text-align: center;
	}

	&-bold {
		font-weight: 500;
	}

	&-bigger {
		font-size: 1.5em;
	}

	&-big {
		font-size: 1.5em;
		font-weight: 500;
	}

	&-regular {
		font-size: 1.1em;
	}

	&-small {
		font-size: 0.7em !important;
	}

	&-matrix {
		font-size: 0.6em !important;
		border-spacing: 0;
	}

	&-withnext {
		padding-bottom: 0;
	}

	&-inline {
		display: inline;
	}

	&-clear {
		clear: both;
	}

	&-hidden {
		display: none;
	}

	&-success {
		color: #480;
	}

	&-warning {
		color: #a20;
	}

	&-matrix tbody tr:hover {
		background-color: #eee;
	}

	&-matrix tr td.ff-calc-delete-control div,
	&-matrix tr td.ff-calc-delete-column div {
		width: 16px;
		height: 16px;
		cursor: pointer;
	}
	&-matrix tr:hover td.ff-calc-delete-control div,
	&-matrix tr:hover td.ff-calc-delete-column div {
		background-image: url(https://ffcalcs.com/assets/css/images/ui-icons_cd0a0a_256x240.png);
		background-position: -32px -192px; /* .ui-icon-circle-close */
	}

	&-disclaimer,
	&-copyright {
		padding: 5px 0;
		font-size: 0.75em;
		color: #aaa;
	}

	&-copyright {
		border-top: 1px solid #ccc;
		margin: 15px auto;
		padding: 5px 0 !important;
	}

	&-copyright a {
		text-decoration: none;
		color: #888;
	}

	&-copyright a:hover {
		text-decoration: underline;
	}

	&-note {
		font-size: 0.75em;
		color: #444;
		padding: 5px;
	}

	&-tooltip {
		position: relative;
		float: right;
		overflow: hidden;
		height: 16px;
		width: 16px;
		background: url(https://ffcalcs.com/assets/css/images/ui-icons_2e83ff_256x240.png);
		background-position: -48px -144px;
		background-position: -16px -144px;
		background-repeat: no-repeat;
	}

	&-tooltip-inline {
		float: none !important;
		display: inline-block;
	}

	&-with-tooltip {
		display: inline-block;
	}
}

#ff-calc {
	&-save-status {
		padding: 3px;
	}
}

/* fixes for UI slider and ff copyright logo */

.ui {
	&-tooltip {
		font-size: 0.8em;
		padding: 8px;
		position: absolute;
		z-index: 9999;
		max-width: 300px;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.16);
		background: var(--white);
		border-radius: 5px;
	}

	&-slider-horizontal &-slider-handle {
		top: -8px;
		transform: translateX(-50%);
		background: url(https://ffcalcs.com/assets/img/slider-handle.png) 50% 50%
			no-repeat #e6e6e6;
		cursor: pointer;
		outline: none;
		border: none;
		height: 25px;
		width: 25px;
		background-color: transparent;
		display: block;
		position: absolute;
	}
	&-slider-horizontal {
		height: 8px;
		background: url(https://ffcalcs.com/assets/img/gray-bg-pattern1.png)
			repeat-x #888888;
		margin: 15px 0 5px;
		box-shadow: inset 0 1px 5px 0px rgba(0, 0, 0, 0.5),
			0 1px 0 0px rgba(250, 250, 250, 0.5);
		position: relative;
		border-radius: 4px;
	}
	&-slider-range {
		background: url(https://ffcalcs.com/assets/img/blue-bg-pattern1.png)
			repeat-x #3b5880;
		display: block;
		height: 100%;
		border-radius: 4px;
	}

	&-tabs &-tabs-nav &-tabs-anchor {
		// font-size: 0.8em;
		// margin: 0;
		// padding: 0;
	}

	&-tabs {
		&-nav {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				display: inline-block;
				margin-right: 3px;
			}
		}

		&-anchor {
			color: var(--blue);
			font-size: 12px;
			font-weight: $fontWeight--bold;
			display: block;
			padding: 8px 16px;
			background: #f7f7fa;
			border-radius: 5px;
			margin-top: 5px;
		}

		&-active &-anchor {
			color: var(--white);
			background: var(--blue);
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
		}
	}
}

a#ff-calc {
	&-logo-link {
		float: right;
		display: block;
		width: 106px;
		height: 35px;
		background-image: url(https://ffcalcs.com/assets/img/ff_logo_unbiased_double_sm.png);
		background-size: 100%;
		background-position: 0 35px !important;
		margin: 1px 0 5px 5px;
		text-decoration: none;
	}

	&-logo-link:hover {
		background-position: 0 0 !important;
		background-color: transparent;
	}
}

.clearfix {
	*zoom: 1;

	&:before,
	&:after {
		content: " "; /* 1 */
		display: table; /* 2 */
	}
	&:after {
		clear: both;
	}
}

/* BREAKPOINT: 992px - 769px */
@media (max-width: 992px) {
	#expenseSlidersContainer {
		height: 1850px;
	}

	.step3PaycheckBreakdownRowValue {
		clear: both;
		font-size: 1.2em;
	}

	#ff-calc-container {
		padding: 3px;
	}

	.ff-calc {
		&-tabs-container-ff-calc-graph {
			position: relative;
			width: 48%;
			height: 325px;
		}

		&-outer-container {
			width: 100%;
			margin: auto;
		}

		&-fields {
			width: 100%;
		}

		&-column1 {
			width: 100%;
		}

		&-column2 {
			width: 100%;
			margin-right: 0;
		}

		&-column-container {
			width: 100%;
		}

		&-input {
			text-align: right !important;
		}

		&-input-3column {
			width: 100% !important;
		}

		&-column1 &-input-2column {
			width: 100%;
		}

		&-column2 &-input-2column {
			width: 50%;
		}
	}

	/* Responsive table refloat method. This requires <!DOCTYPE html> -- doesn't work in the quirks mode... */
	/* Force table to not be like tables anymore */
	.ff-calc-container {
		table.ff-responsive-table,
		.ff-responsive-table thead,
		.ff-responsive-table tbody,
		.ff-responsive-table tfoot,
		.ff-responsive-table th,
		.ff-responsive-table td,
		.ff-responsive-table tr {
			display: block;
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		.ff-responsive-table thead tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

		.ff-responsive-table tr {
			border: 1px solid #ccc;
			margin-bottom: 5px;
		}

		.ff-responsive-table td {
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee;
			position: relative;
			padding: 5px 5px 5px 130px;
			width: 100%;
		}

		.ff-responsive-table td:before {
			/* Now like a table header */
			position: absolute;
			display: block;
			/* Top/left values mimic padding */
			top: 6px;
			left: 6px;
			width: 130px;
			white-space: nowrap;
			content: attr(data-label) ": ";
			font-size: 1.3em;
		}

		.ff-responsive-table td.ff-calc-delete-control:before {
			content: "";
		}

		.ff-calc-matrix tr td.ff-calc-delete-control div {
			background-image: url(https://ffcalcs.com/assets/css/images/ui-icons_cd0a0a_256x240.png);
			background-position: -32px -192px; /* .ui-icon-circle-close */
		}
	}
}

/**
 * save_for_goals
 */

.save_for_goals {
	th.ff-calc-first-col,
	td.ff-calc-first-col {
		width: 150px;
	}

	@media all and (min-width: 993px) {
		.ff-calc-matrix tfoot td {
			padding: 10px 6px;
		}

		.ff-calc-matrix tfoot td.ff-calc-total-value {
			padding-right: 0;
		}
	}

	@media all and (max-width: 992px) {
		input.ff-calc-input {
			display: inline-block;
			width: 60%;
			margin-left: 40%;
		}

		.ff-responsive-table tfoot td.ff-calc-no-data-label:before {
			content: attr(data-label);
		}

		td.ff-calc-total-col.ff-calc-hide-mobile {
			display: none;
		}

		input[name*="[name]"] {
			width: 100%;
			margin-left: 0;
		}
	}
}

/**
 * cost_of_credit
 */

.cost_of_credit {
	th.ff-calc-first-col,
	td.ff-calc-first-col {
		width: 100px;
	}

	.ff-calc-matrix tfoot td {
		padding: 10px 6px;
	}

	.ff-calc-matrix tfoot td.ff-calc-total-value {
		padding-right: 0;
	}

	td.ff-calc-delete-control {
		display: none;
	}

	th {
		text-align: right;
	}

	tr td {
		padding-left: 15px;
	}

	th.ff-calc-pad-left {
		padding-left: 20px;
	}

	div#payment-plans-1-months,
	div#payment-plans-2-months,
	div#payment-plans-3-months,
	div#payment-plans-4-months,
	div#payment-plans-5-months,
	div#payment-plans-6-months,
	div#payment-plans-7-months {
		color: blue;
		padding-left: 0px;
	}

	div#payment-plans-1-pmt-amount,
	div#payment-plans-1-pmt-percentage {
		background-color: #ffe100;
	}

	div#payment-plans-1-interest-saved,
	div#payment-plans-2-interest-saved,
	div#payment-plans-3-interest-saved,
	div#payment-plans-4-interest-saved,
	div#payment-plans-5-interest-saved,
	div#payment-plans-6-interest-saved,
	div#payment-plans-7-interest-saved {
		font-weight: bold;
	}

	@media all and (max-width: 992px) {
		#ff-calc-wide-header {
			display: none;
		}
	}
}

/**
 * education_savings
 */

.education_savings {
	div.ff-calc-value {
		padding-right: 5px;
	}

	.ff-calc-delete-column {
		padding-left: 70px;
	}

	.ff-calc-matrix tfoot td {
		padding: 10px 6px;
	}

	.ff-calc-matrix tfoot td.ff-calc-total-value {
		padding-right: 0;
	}

	td.ff-calc-bold {
		text-align: left;
		font-size: 1.2em;
	}

	table#students-matrix {
		margin: auto;
	}

	div#ff-calc-main-panel {
		text-align: center;
	}

	th {
		padding-left: 10px;
	}

	@media all and (max-width: 650px) {
		table,
		thead,
		tbody,
		th,
		td,
		tr {
			display: block;
		}

		#student-add,
		tr#row-annual-contrib,
		tr#row-monthly-contrib,
		tr#row-delete,
		th {
			display: none;
		}

		td.ff-calc-bold {
			min-width: 200px;
		}
	}
}

/**
 * mortgage_comparison
 */

.mortgage_comparison {
	div.ff-calc-value {
		padding-right: 5px;
	}

	.ff-calc-delete-column {
		padding-left: 70px;
	}

	tr#row-total-monthly-pmt .ff-calc-value {
		font-size: 2.3em;
	}

	td.ff-calc-bold {
		width: 185px;
		text-align: left;
		font-size: 1.2em;
	}

	table#loans-matrix {
		margin: auto;
	}

	div#ff-calc-main-panel {
		text-align: right;
	}

	@media all and (min-width: 993px) {
		.ff-calc-input {
			width: 120px;
			margin-left: 10px;
		}

		#loan-add-mobile {
			display: none;
		}
	}

	@media all and (max-width: 992px) {
		table,
		thead,
		tbody,
		th,
		td,
		tr {
			display: block;
		}

		#loan-add,
		th,
		tr#row-delete {
			display: none;
		}

		td {
			text-align: left;
		}

		td.ff-calc-bold {
			width: 100%;
		}

		input.ff-calc-input {
			display: inline-block;
			width: 60%;
			margin-left: 40%;
		}

		tr[id^="row-loans"] {
			margin-bottom: 20px;
		}
	}
}

/**
 * mortgage_payment
 */

.mortgage_payment {
	#ff-calc-graph-container-1 {
		margin-bottom: 5px;
	}

	#ff-calc-graph-container-2 {
		margin-bottom: 0px;
	}

	#payment-tip {
		margin-left: 10px;
	}

	#ff-calc-container #accordion h3 {
		font-size: 18px;
		padding: 5px 0 5px 10px;
		color: #000;
	}

	.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
		left: auto;
		right: 0.5em;
	}

	#accordion p {
		font-size: 0.75em;
	}

	.ui-accordion .ui-accordion-content {
		padding: 5px;
	}
}

/**
 * pretax_savings
 */

.pretax_savings {
	select.ff-calc-input {
		font-size: 1.3em;
	}

	.ff-calc-with-tooltip {
		display: inline-block;
	}
}

/**
 * retirement_estimator
 */

.retirement_estimator {
	.ff-calc-label {
		font-size: 0.95em;
	}

	.ff-calc-highlight-success {
		color: #480;
	}

	.ff-calc-highlight-alarm {
		color: #c00;
	}

	.ff-calc-highlight-warning {
		color: #a20;
	}
}

/**
 * tax_break
 */

.tax_break {
	select.ff-calc-input {
		font-size: 1.3em;
	}

	.ff-calc-with-tooltip {
		display: inline-block;
	}

	.ff-calc-input {
		font-size: 1.2em;
	}

	#ff-calc-upper-panel {
		padding-top: 10px;
		padding-bottom: 10px;
	}
}

/**
 * weighted_avg
 */

.weighted_avg {
	@media all and (min-width: 993px) {
		#ff-calc-historical-rate {
			position: absolute;
			display: inline-block;
			top: 0;
			margin-top: 100px;
		}
		#ff-calc-narrow-header {
			display: none;
		}
	}

	@media all and (max-width: 992px) {
		#ff-calc-wide-header {
			display: none;
		}
	}

	.ff-calc-margin-bottom {
		margin-bottom: 10px;
	}
}

/**
 * ira_calc
 */

.ira_calc {
	#qa {
		color: red;
	}

	.sublabel {
		font-size: 0.75em;
	}

	.tax-label {
		font-size: 0.75em;
		font-weight: 200;
	}
}
