.gauge {
	width: 249px;
	height: 149px;
	position: relative;

	&__indicator {
		position: absolute;
		width: 10px;
		height: 10px;
		border: 7px solid red;
		background: var(--white);
		border-radius: 50%;
		transform: translateX(-6px) translateY(-12px);

		&Container {
			position: absolute;
			transform-origin: bottom right;
			height: 0px;
		}
	}
}