@import '../../../Variables.scss';

.libraryFilter {
	&__parent {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		&--dropdown {
			flex: 0 0 auto;
			width: 12px;
			height: 8px;
			color: #9d9d9d;
			margin-top: 18px;
		}
	}

	&__child {
		margin: 0 0 0 25px;
	}
}
