@import '../../Variables.scss';

.scheduleASession {
	background: var(--white);
	padding: 24px;
	border-radius: 15px;
	box-shadow: 2px 4px 10px rgba(0,0,0,0.16);
	margin: 0 0 20px;

	&__header {
		display: flex;
		align-items: center;
		margin-bottom: 12px;
	}

	&__icon {
		width: 68px;
		height: 68px;
		margin-right: 12px;
	}

	&__meta {
		flex: 1;
	}

	&__title {
		font-size: 20px;
		font-weight: $fontWeight--bold;
		color: var(--blue);
		margin: 0 0 3px;
		padding: 0;
	}

	&__subtitle {
		font-size: 16px;
		font-weight: $fontWeight--medium;
		margin: 0;
		padding: 0;
	}

	&__link {
		color: var(--white);
		font-size: 14px;
		font-weight: $fontWeight--bold;
		background: var(--blue);
		height: 39px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 20px;
	}
}