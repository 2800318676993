@import "../../Variables.scss";

.MyFinancialsDashboard {
	display: grid;
	place-content: center;
	padding-inline: 10px;
	padding-bottom: 68px;

	@media screen and (min-width: $size-tablet-min) {
		padding-inline: 32px;
	}

	&__header {
		display: inline-block;
		margin-left: 12px;
	}

	&__title {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		height: 88px;
		color: #073853;

		h1 {
			margin: 0;
			font-size: 25px;
			font-weight: 600;
		}
	}

	&__layout {
		display: grid;
		grid: repeat(3, auto) / 1fr;
		gap: 30px;

		@media screen and (min-width: $size-tablet-min) {
			grid: repeat(2, auto) / minmax(0, 548px) 420px;
		}
	}

	&__chart {
		grid-row: 1;
		grid-column: 1 / -1;
		min-width: 0;
	}
}

.MyFinancialsDashboardAccounts {
	background-color: var(--white);
	box-shadow: 0 3px 20px #00000026;
	border-radius: 15px;
	padding: 0 24px 24px;

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 75px;

		h2 {
			margin: 0;
			font-size: 20px;
			font-weight: 600;
			color: #073853;
		}

		a,
		a:visited {
			color: var(--blue);
			font-size: 14px;
			font-weight: 500;
		}
	}

	&__connect {
		background-color: #e6f2f6;
		border: 1px solid #f0f0f0;
		border-radius: 15px;
		padding: 24px;
		text-align: center;

		div {
			font-size: 18px;
			font-weight: 600;
		}

		.MuiButton-root {
			border-radius: 8px;
			padding: 9px 30px;
		}

		.MuiButton-label {
			text-transform: none;
		}
	}

	&__list {
		& + & {
			border-top: 1px solid #e6e6e6;
		}
	}

	&__listItem {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-block: 15px;
		padding-left: 12px;

		&--clickable {
			cursor: pointer;
		}
	}

	&__typeHeader {
		padding-block: 17px;
		padding-left: 0;
		font-weight: 600;
	}

	&__listAddAccount {
		margin-bottom: 10px;
	}

	&__netWorth &__typeBalance {
		color: #073853;
		margin-right: 24px;
	}

	&__typeExpand {
		flex-shrink: 1;
		flex-basis: 0;
		border: none;
		padding: 0;
		margin: 0 0 0 10px;
		background-color: transparent;
		cursor: pointer;
		filter: invert(8%) sepia(35%) saturate(0%) hue-rotate(195deg)
			brightness(13%) contrast(90%);
	}

	&__listItem--clickable &__typeBalance {
		margin-left: auto;
	}

	&__typeExpand &__listExpand {
		width: 14px;
		aspect-ratio: 1;
	}

	&__addAccount {
		text-align: center;
	}
}

.MyFinancialsDashboardChart {
	background-color: var(--blue);
	color: var(--white);
	border-radius: 15px;
	padding: 25px;
	box-shadow: 2px 4px 10px #00000029;

	&__header {
		margin: 10px 0 25px;

		@media screen and (min-width: $size-desktop-min) {
			margin-left: 10px;
		}
	}

	&__title {
		display: flex;
		gap: 3px;
		align-items: center;

		h2 {
			margin: 0;
			font-size: 25px;
			font-weight: 500;
		}
	}

	& &__info {
		color: var(--white);
	}

	&__value {
		margin-top: 6px;
		font-size: 40px;
		font-weight: 600;
	}

	&__chart {
		overflow: hidden;
		direction: rtl;

		> div {
			direction: ltr;
			width: 968px;
			height: 241px;
		}
	}

	&__invisible {
		display: none;
	}
}
