@import "../../Variables.scss";

.LoadingSpinner {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;

	&__container {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-weight: $fontWeight--regular;
	}

	&__spinner {
		$size: 2em;
		$speed: 0.7s;
		margin: 1em;
		display: inline-block;
		width: $size;
		height: $size;
		border-radius: 50%;
		border: 4px solid var(--x-light-grey);
		border-bottom-color: var(--dark-blue);
		animation: loadingSpinnerAnimation $speed linear infinite;
	}
}

@keyframes loadingSpinnerAnimation {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}
