@import '../../../Variables.scss';

.enhancedSearch {
	width: 100%;

	&__search {
		width: 100%;
		text-align: right;

		.MuiAutocomplete-inputRoot {
			border: 2px solid var(--x-light-grey) !important;
			border-radius: 40px !important;
			background: var(--white);
			padding-top: 8px !important;
			padding-bottom: 8px !important;
			padding-left: 12px !important;
			padding-right: 12px !important;
		}

		.MuiOutlinedInput-notchedOutline {
			border: 0;
		}

		.MuiAutocomplete-input {
			font-size: 14px;
		}

		.MuiInputAdornment-root {
			color: #9d9d9d;
		}
	}
	
	&__searchOption {
		display: flex;
		align-items: center;
		font-size: 16px;
		font-weight: $fontWeight--regular;
		color: #383838 !important;

		&ImageContainer {
			width: 31px;
			height: 31px;
			margin-right: 15px;
			overflow: hidden;
			background-size: cover;
			background-position: center center;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 5px;
			flex: 0 0 31px;
		}

		&Image {
			width: 17px;
			height: 17px;
			color: var(--light-grey);
		}

		&Type {
			font-size: 13px;
			color: var(--light-grey);
			text-transform: capitalize;
		}
	}
}
