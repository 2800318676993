@import '../../../Variables.scss';

.HomeContact {
	width: 375px;
	height: 464px;
	padding: 25px;
	box-sizing: border-box;
	border-radius: 20px;
	box-shadow: $shadow4;
	background: var(--white);
	position: relative;

	&__title {
		text-transform: uppercase;
		font-size: 14px;
		color: var(--white);
		font-weight: $fontWeight--bold;
		letter-spacing: 0.08em;
		margin: 0 0 15px -25px;
		padding: 8px 28px 8px 25px;
		background: var(--blue);
		border-radius: 0 20px 20px 0;
		display: inline-block;
	}

	&__tagline {
		font-size: 22px;
		color: var(--dark-blue);
		font-weight: $fontWeight--bold;
		line-height: 1.23;
		margin: 0 0 12px;
		padding: 0;
	}

	&__options {
		margin: 27px 0 28px;
		display: flex;
		justify-content: space-between;
	}

	&__call {
		color: var(--white);
		width: 248px;
		flex: 0 0 248px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: var(--dark-blue);
		border-radius: 50px;
		font-size: 18px;
		font-weight: bold;
	}

	&__chat {
		color: var(--white);
		width: 50px;
		flex: 0 0 50px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: var(--blue);
		border-radius: 50px;

		&Icon {
			width: 24px;
			height: 24px;
		}
	}

	&__spanish {
		text-align: center;
		font-size: 16px;
		font-weight: $fontWeight--bold;
		margin-bottom: 9px;
	}

	&__hours {
		text-align: center;
		color: var(--darker-blue);
		font-size: 14px;
		font-weight: $fontWeight--medium;
	}

	&__image {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 249px;
		height: 160px;
	}
}