.progress {
	height: 100%;
	width: 100%;
	box-sizing: border-box;

	h1 {
		font-size: 16px;
		line-height: 22px;
		color: #073853;
	}

	select {
		color: #c1c1c1;
		font-size: 12px;
		line-height: 22px;
		border: none;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__chart {
		margin: 25px 0 5px;
		width: 100%;
	}
}
