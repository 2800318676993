@import '../../Variables.scss';

.badge {
	padding: 25px;
	background-color: var(--white);
	box-shadow: 2px 4px 10px #00000029;
	border-radius: 15px;
	overflow: hidden;

	&__title {
		color: #073853;
		font-weight: 600;
		margin-bottom: 14px;
	}

	&__description {
		font-weight: $fontWeight--regular;
		font-size: 14px;
		line-height: 1.35;
		margin-bottom: 15px;
	}

	&__badge {
		max-height: auto;
		width: 100%;
		border: dashed 2px var(--x-light-grey);

		&--complete {
			border: 0;
		}

		& img {
			max-height: auto;
			width: 100%;
		}
	}

	&__badge:empty {
		height: 320px;
	}

	&__button {
		width: 100%;
		border-radius: 5px;
		border: 0;
		background: var(--x-light-grey);
		color: #44565D;
		font-size: 13px;
		padding: 10px;
		box-sizing: border-box;
		font-weight: $fontWeight--bold;
		display: block;
		text-align: center;
	}

	&__download {
		margin: 14px 0;

		&--available {
			background: var(--blue);
			color: var(--white);
			cursor: pointer;
		}
	}

	&__certificateDownload {
		margin-bottom: 0;

		&--available {
			background: var(--blue);
			color: var(--white);
		}
	}

}