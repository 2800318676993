.moduleSummary {
	display: grid;
	grid: repeat(2, 1fr) / 1fr auto auto;
	align-items: center;
	gap: 15px 0;
	padding: 15px;
	background-color: #f7f7fa;
	border-radius: 10px;
	overflow: hidden;
	color: #3E3E3E;

	&__name {
		font-weight: 500;
	}

	&__courses {
		grid-column: span 2;
		font-size: 14px;
	}

	&__progress {
		grid-column: span 2;
	}

	&__percentage {
		padding-inline-start: 25px;
		font-weight: 500;
		text-align: right;
	}
}
