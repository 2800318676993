.textClamp {
	display: flex;
	flex-direction: column;

	&__content {
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		
		p {
			text-overflow: ellipsis;
		}
	}

	button {
		align-self: flex-end;
		margin: 5px 30px 0 0;
		background-color: inherit;
		color: var(--blue);
		border: none;
		text-transform: uppercase;
		z-index: 10;
		cursor: pointer;
		padding: 0;
		font-weight: 600;
	}
}
