@import '../../../Variables.scss';

.Advisor {
	padding: 33px 45px;
	max-width: 1040px;

	h1 {
		color: var(--dark-blue);
		font-size: 25px;
		font-weight: $fontWeight--bold;
		margin: 0 0 23px;
		padding: 0;
	}

	h2 {
		color: var(--dark-blue);
		font-size: 20px;
		font-weight: $fontWeight--bold;
		margin: 30px 0 15px;
		padding: 0;
		line-height: 1.4;
	}

	h3 {
		margin: 0;
		font-size: 22px;
		line-height: 1.22;
		color: #073853;
	}

	p {
		line-height: 1.625;
	}

	ul {
		margin: 0 0 16px;
		padding: 0 0 0 25px;
	}

	li {
		line-height: 1.5;
	}

	li::marker {
		font-size: 0.6em;
	}

	img {
		object-fit: contain;
	}

	a {
		color: #007cab;
		font-size: 16px;
		font-weight: 700;
		line-height: 26px;
	}

	&__container {
		display: grid;
		grid-gap: 25px;
	}

	&__button {
		display: flex;
		padding: 10px 0;
		justify-content: center;
		align-items: center;
		gap: 10px;
		color: var(--white) !important;
		border-radius: 50px;
		font-weight: $fontWeight--medium !important;
	}

	&__banner {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding: 40px;
		gap: 20px;
		border-radius: 20px;
		background: #007cab;

		h3 {
			color: var(--white);
		}

		&Link {
			background-color: #073853;
			width: 160px;
		}
	}

	&__group {
		display: flex;
		flex-direction: row;
		grid-gap: 25px;
	}

	&__halfPanel,
	&__panel {
		padding: 40px;
		background-color: var(--white);
		border-radius: 20px;
		box-shadow: 0px 14px 42px 0px rgba(0, 0, 0, 0.16);

		.Advisor__imageContainer {
			cursor: pointer;
		}
	}

	&__launchMarketing {
		.Advisor__imageContainer {
			cursor: initial;
		}
	}

	&__panel {
		grid-gap: 25px;
		&Container {
			display: flex;
			flex-direction: column;
			grid-gap: 25px;
		}
	}

	&__halfPanel {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		max-width: 430px;

		.Advisor__imageContainer {
			height: 250px;
			max-height: 250px;

			img {
				max-height: 240px;
			}
		}
	}

	&__subTitle {
		color: #073853;
	}

	&__webcast h3 {
		color: #007cab;
	}

	&__thinkTank,
	&__educationAction,
	&__benefitSlides {
		display: flex;
		flex-direction: row;
		align-items: center;

		.Advisor__textGroup,
		.Advisor__imageContainer {
			width: 50%;
		}
	}

	&__educationAction,
	&__benefitSlides {
		.Advisor__imageContainer {
			max-height: 250px;

			img {
				padding: 20px;
			}
		}
	}

	&__thinkTank {
		.Advisor__imageContainer {
			text-align: center;
			padding: 40px 0;

			h3 {
				margin: 0 10px 20px;
			}
		}

		.Advisor__thinkLink {
			background: #007cab;
			margin: 20px 0 0;
			width: 160px;
		}

		h3 {
			margin: 0 0 20px;
		}

		img {
			height: auto;
			max-height: 250px;
		}
	}

	&__imageContainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 10px;
		background: #f4f7fe;

		img {
			width: 80%;
		}
	}

	&__advisor {
		&Webcasts {
			display: flex;
			flex-direction: column;
			gap: 20px;
			font-size: 16px;
			line-height: 1.26;

			h3 {
				margin: 0 0 11px;
				font-size: 22px;
				font-weight: 700;
				line-height: 27px;
			}

			h4 {
				margin: 0 0 11px;
				font-size: 18px;
				font-weight: 500;
				line-height: normal;
			}
		}
	}

	&__webcast {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 30px 40px;
		border-radius: 10px;
		border: 1px solid #ebeef4;
		background: #f4f7fe;
		box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
	}
}

@media (max-width: 1155px) {
	.Advisor {
		&__getStartedContainer {
			flex-direction: column;
		}

		&__getStarted {
			width: 100%;
		}
	}
}

@media (max-width: 768px) {
	.Advisor {
		padding: 20px 20px;

		&__banner {
			flex-wrap: wrap;
			box-shadow: 0px 14px 42px 0px rgba(0, 0, 0, 0.16);

			&Link {
				width: 100%;
			}
		}

		&__group {
			flex-wrap: wrap;
		}

		&__halfPanel {
			max-width: 100%;
		}

		&__panel {
			flex-wrap: wrap;
		}

		&__textGroup,
		&__imageContainer {
			width: 100% !important;
			max-width: 630px;

			img {
				height: auto;
				max-width: 410px;
			}
		}
	}
}

@media (max-width: 500px) {
	.Advisor {
		padding: 20px 10px;
	}
}
