@import "../../Variables.scss";

.MyFinancials__AccountForm {
	padding: 20px 0 0 43px;

	label {
		display: block;
		font-size: 14px;
		font-weight: 500;

		& + label {
			margin-top: 14px;
		}
	}

	&__label {
		margin-bottom: 6px;
	}

	&__input {
		.MuiOutlinedInput-input {
			padding: 13px 15px;
			border-radius: 8px;
		}

		.MuiOutlinedInput-notchedOutline {
			border-radius: 8px;
		}

		.MuiInputBase-root.Mui-disabled {
			color: currentColor;
		}

		.MuiInputBase-input.Mui-disabled {
			background-color: #f5f5f5;
		}

		&--subtype {
			.MuiInputBase-input {
				text-transform: capitalize;
			}

			.MuiOutlinedInput-notchedOutline {
				border: none;
			}
		}
	}

	&__actions {
		display: flex;
		align-items: center;
		margin-top: 17px;

		.MuiButton-text {
			padding-inline: 0;
		}

		.MuiButton-label {
			text-transform: none;
			font-weight: 600;
			letter-spacing: normal;
		}
	}

	& &__reconnect {
		.MuiButton-label {
			color: #b21010;
		}
	}

	& &__delete {
		margin-left: auto;
		.MuiButton-label {
			color: var(--blue);
		}
	}
}

.MyFinancials__Account {
	display: grid;
	grid-template-columns: 30px 1fr max-content max-content;
	grid-template-rows: repeat(2, max-content);
	gap: 5px 13px;

	&__icon {
		grid-area: 1 / 1 / 3 / 2;
		place-self: center;
		display: grid;
		place-items: center;
		width: 30px;
		height: 30px;
		overflow: hidden;
	}

	&__title {
		grid-area: 1 / 2;
		font-size: 14px;
		font-weight: 500;
		overflow-x: hidden;
		text-overflow: ellipsis;
	}

	&__name {
		grid-area: 2 / 2;
		font-size: 12px;
		line-height: 1.2;
		color: #707070;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__issues {
		grid-area: 1 / 3 / 3 / 4;
		width: 24px;
		height: 24px;
		place-self: center;
		color: #b21010;
	}

	&__balance {
		grid-area: 1 / 4;
		text-align: right;
		align-self: flex-end;
		font-size: 14px;
		font-weight: 600;
	}

	&__modified {
		grid-area: 2 / 4;
		text-align: right;
		font-size: 12px;
		color: #cecece;
	}

	&__bankIcon {
		display: grid;
		place-items: center;
		width: 30px;
		aspect-ratio: 1;
		border-radius: 50%;
		overflow: hidden;
		color: white;

		&--default {
			background-color: #e6e6e6;
		}
	}
}

.MyFinancials__Accounts {
	margin: 0 10px;
	padding-bottom: 60px;
	max-width: 500px;

	@media screen and (min-width: 520px) {
		margin: 0 auto;
	}

	&--choose {
		margin: unset;
		@media screen and (max-width: $size-mobile-landscape-max) {
			border-top: 1px solid #cecece;
		}
	}

	&__title {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		height: 88px;
		color: #073853;

		h1 {
			margin: 0;
			font-size: 25px;
			font-weight: 600;
		}
	}

	&__backButton {
		background-color: transparent;
		border: none;
		cursor: pointer;
	}

	&__back {
		display: flex;
		align-items: center;
		height: 32px;
		width: 32px;

		@media screen and (min-width: $size-desktop-min) {
			margin: -2px 10px -2px -10px;
		}
	}

	> p {
		margin: -16px 0 30px;
		padding-left: 38px;
		font-size: 14px;
	}

	&__list {
		background-color: var(--white);
		border-radius: 15px;
		box-shadow: 0 3px 6px #00000029;
		overflow: hidden;
		padding: 0 24px;

		& + & {
			margin-top: 20px;
		}

		&Item {
			&--heading {
				padding: 20px 0 24px;
			}

			&--selectable {
				padding: 20px 0;
				display: flex;
				align-items: center;
				gap: 17px;
				cursor: pointer;

				&:hover {
					background-color: #fafafa;
				}

				> *:first-child {
					flex: 1;
				}
			}

			& + & {
				border-top: 1px solid #f0f0f0;
			}
		}

		&AddNewAccount {
			padding-block: 15px;

			button {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				border: none;
				background: transparent;
				font-weight: 600;
				color: var(--blue);
				cursor: pointer;
			}
		}

		&AddAccount {
			text-align: center;
			padding: 18px 0 20px;
			.MuiButton-label {
				line-height: 1;
				text-transform: none;
				color: var(--blue);
			}
		}
	}

	&__type {
		&Header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: 10px;
		}

		&Name {
			font-size: 18px;
			font-weight: 600;
		}

		&Balance {
			font-size: 16px;
			font-weight: 500;
		}
	}

	&__squarePlus {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 31px;
		height: 31px;
		border-radius: 8px;
		background-color: var(--blue);
		color: var(--white);

		&--connected {
			background-color: var(--light-grey)
		}
	}
}
