@import "../../Variables.scss";

#designstudio-button {
	display: none;
}

.Contact {
	position: fixed;
	bottom: 36px;
	right: 36px;
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;

	.OnlineStatus {
		margin-top: 6px;
	}

	&__action {
		width: 56px;
		height: 56px;
		border-radius: 50%;
		border: 0;
		padding: 0;
		margin: 0;
		color: var(--white);
		background: var(--light-blue);
		position: relative;
		z-index: 1;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.48);
		cursor: pointer;

		&::before {
			display: block;
			content: "";
			top: 0;
			left: 0;
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			background: linear-gradient(
				135deg,
				rgba(0, 0, 0, 0.4) 0%,
				transparent 100%
			);
			z-index: -1;
		}

		&Icon {
			&--closed {
				margin-top: 4px;
				width: 25px;
				height: 25px;
				fill: var(--white);
				color: var(--white);
			}

			&--open {
				width: 12px;
				height: 12px;
				fill: var(--white);
				color: var(--white);
			}
		}
	}

	&__menu {
		width: 328px;
		height: 521px;
		border-radius: 20px;
		position: absolute;
		right: 0;
		bottom: 74px;
		background: #f7f7fa;
		box-shadow: 0 3px 12px rgba(0, 0, 0, 0.16);

		&Header {
			background-color: var(--blue);
			color: var(--white);
			border-radius: 20px 20px 0 0;
			padding: 47px 11px 40px;

			&Icon {
				position: absolute;
				top: 45px;
				left: 21px;
			}

			&Title {
				margin: 0 0 7px 50px;
				padding: 0;
				font-size: 17px;
				font-weight: $fontWeight--bold;
				line-height: 1.35;
			}

			&Subtitle {
				margin: 0 0 0 50px;
				padding: 0;
				font-size: 14px;
				font-weight: $fontWeight--regular;
				line-height: 1.36;
			}
		}

		&Body {
			margin-top: -25px;
		}

		&Item {
			margin: 0 11px 15px;
			border-radius: 10px;
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
			background-color: var(--white);
			padding: 21px;
			display: flex;
			align-items: center;

			&--offline > * {
				opacity: 0.5;
			}

			&--chat {
				border: 0;
				text-align: left;
				cursor: pointer;
			}

			&Title {
				margin: 0;
				padding: 0 0 2px;
				font-size: 14px;
				font-weight: $fontWeight--bold;
				line-height: 1.36;
			}

			&Details {
				font-size: 13px;
				font-weight: $fontWeight--regular;
				line-height: 1.38;
				margin: 0;
				padding: 0;

				&PhoneNumber {
					font-size: 15px;
					font-weight: $fontWeight--bold;
					color: inherit;
				}
			}

			&Icon {
				width: 48px;
				flex: 0 0 48px;
				max-height: 47px;
				margin-right: 20px;
			}
		}

		&Footer {
			background-color: var(--white);
			font-size: 13px;
			font-weight: $fontWeight--regular;
			line-height: 1.38;
			text-align: center;
			padding: 10px 0;
			border-radius: 0 0 20px 20px;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
		}
	}
}

@media (max-width: 768px) {
	.Contact {
		bottom: 20px;
		right: 20px;
	}
}
