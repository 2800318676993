@import "../../../Variables.scss";

.LibraryIndex {
	height: 100%;

	&__content {
		padding: 0px 10px 70px 10px;
	}

	&__titleBar {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin: 0 12px 20px 12px;
		height: 88px;

		&__title {
			display: flex;
			align-items: center;
		}

		button {
			background-color: inherit;
			border: none;
			cursor: pointer;
			outline: none;
			display: flex;
			align-items: center;
		}

		h1 {
			margin: 0;
			font-size: 25px;
			font-weight: $fontWeight--bold;
			color: #073853;
		}
	}

	&__enhancedSearch {
		--enhancedSearchPadding: 25px;

		background: var(--white);
		border-radius: 10px;
		padding: var(--enhancedSearchPadding);
		margin-bottom: 45px;
		margin-top: -20px;

		.MuiInputBase-input {
			font-size: 16px !important;
		}

		&Header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 15px;
		}

		&Intro {
			font-weight: $fontWeight--regular;
			font-size: 19px;
			display: flex;
			align-items: center;

			&Icon {
				width: 28px;
				height: auto;
				margin-right: 6px;
			}
		}

		&Examples {
			margin-top: 18px;

			&Container {
				display: flex;
				flex-wrap: wrap;
				gap: 12px;
			}

			&Title {
				margin: 0 0 15px;
				padding: 0;
				font-size: 16px;
				font-weight: $fontWeight--medium;
				line-height: 1.33;
			}

			&Example {
				background-color: #ececef;
				border-radius: 30px;
				padding: 9px 14px 9px 34px;
				font-size: 16px;
				line-height: 1.46;
				flex: 0 1 auto;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				position: relative;
				color: inherit;

				&Icon {
					position: absolute;
					top: 50%;
					left: 12px;
					width: 18px !important;
					height: 18px !important;
					transform: translateY(-50%);
					color: var(--light-grey);
				}
			}
		}
	}

	&__filterIcon {
		height: 16px;
		width: 16px;
		stroke: #383838;

		&--active {
			stroke: var(--blue);
		}
	}

	&__filters {
		--filterBorderRadius: 30px;
		--filterFontSize: 15px;
		margin-bottom: 43px;
		white-space: nowrap;
		overflow-x: auto;
		scrollbar-width: thin;

		&Track {
			width: 200px;
			display: inline-flex;
		}

		&Action {
			display: inline-flex;
			align-items: center;
			background: var(--x-light-grey);
			border-radius: var(--filterBorderRadius);
			padding: 0 9px;
			margin-right: 12px;
		}

		&Show {
			display: flex;
			align-items: center;
			border: 0;
			background: none;
			cursor: pointer;
			font-family: inherit;
			color: #000;
			font-size: var(--filterFontSize);
			font-weight: $fontWeight--bold;
			padding: 9px;
		}

		&Icon {
			width: 18px;
			height: 18px;
			margin-right: 9px;
		}

		&Clear {
			border: 0;
			background: none;
			cursor: pointer;
			background: var(--grey);
			color: var(--white);
			border-radius: 50%;
			margin: 0;
			padding: 2px;
			width: 22px;
			height: 22px;
			box-sizing: border-box;

			&Icon {
				width: 10px;
				height: 10px;
			}
		}

		&Type {

			&Filter {
				border: 1px solid var(--x-light-grey);
				background: none;
				border-radius: var(--filterBorderRadius);
				color: #000;
				font-size: var(--filterFontSize);
				font-weight: $fontWeight--bold;
				padding: 0 15px;
				height: 36px;
				font-family: inherit;
				margin-right: 12px;
				cursor: pointer;

				&--checked {
					color: var(--white);
					background: var(--dark-blue);
					border-color: var(--dark-blue);
				}
			}
		}
	}

	&__noFilteredResults {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 27vh;
	}

	&__noResults {
		margin: 27vh 13px 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;

		&Icon {
			width: 43px;
			height: 35px;
			margin-bottom: 20px;
		}

		&Title {
			font-size: 16px;
			font-weight: $fontWeight--medium;
			padding: 0;
			margin: 0 0 14px;
			color: #454f63;
		}

		&Description {
			font-size: 14px;
			line-height: 1.44;
			font-weight: $fontWeight--regular;
			margin: 0 0 12px;
			text-align: center;
		}
	}

	&__items {
		display: flex;
		flex-wrap: wrap;
		margin-top: -15px;
		margin-bottom: 60px;
	}

	&__filterChips {
		margin-left: -10px;
		margin-right: 10px;
	}

	&__item {
		background: var(--white);
		border-radius: 10px;
		overflow: hidden;
		box-sizing: border-box;
		box-shadow: 0 3px 10px #70707033;
		color: inherit;
		position: relative;
		flex: 1 1 290px;
		margin: 15px 0;

		&Flag {
			position: absolute;
			bottom: 0;
			left: 0;
			color: var(--white);
			font-size: 13px;
			font-weight: $fontWeight--bold;
			background: var(--blue);
			padding: 5px 16px;

			&--ofColor {
				background: var(--darker-blue);
			}
		}

		&Title {
			font-size: 15px;
			font-weight: $fontWeight--bold;
			line-height: 1.29;
			margin: 14px 24px 7px;
		}

		&Summary {
			font-size: 14px;
			font-weight: $fontWeight--regular;
			line-height: 1.31;
			margin: 0 24px 37px;
			height: 58px;
			overflow: hidden;
		}

		&Type {
			font-size: 9px;
			font-weight: $fontWeight--medium;
			color: #707070;
			display: inline-block;
			width: 70px;
			padding: 2px 0;
			text-align: center;
			border: 1px solid #d3d3d3;
			border-radius: 10px;
			text-transform: capitalize;
			position: absolute;
			left: 24px;
			bottom: 13px;
		}

		&Metadata {
			font-size: 9px;
			font-weight: $fontWeight--medium;
			color: #9d9d9d;
			position: absolute;
			right: 24px;
			bottom: 15px;
		}
	}

	&__imageContainer {
		display: block;
		height: 131px;
		overflow: hidden;
		position: relative;
	}

	&__image {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}

	&__pagination {
		width: 100%;
		text-align: center;

		.MuiPagination-ul {
			justify-content: center;

			li {
			}
		}

		.MuiPaginationItem-page {
			font-size: 13px;
			font-weight: $fontWeight--medium;
			background: var(--white);
			border-radius: 8px;
			border: 1px solid var(--x-light-grey);

			&.Mui-selected {
				color: var(--blue);
				border-color: var(--blue);
				background: var(--white);
			}

			&:hover {
				color: var(--blue);
			}
		}
	}

	&__search {
		.MuiOutlinedInput-notchedOutline {
			border-radius: 10px;
			border-color: #d3d3d3;
		}

		.MuiInputAdornment-root {
			color: #9d9d9d;
		}

		&Option {
			&.MuiAutocomplete-option {
				font-weight: $fontWeight--medium;
				font-size: 14px;
				padding-left: 25px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow-x: hidden;
				display: block;
			}

			&--seeAll {
				color: var(--blue);
			}
		}

		&--enhanced {
			margin-top: 16px;
		}
	}
}

@media screen and (max-width: $size-sm) { 
	.LibraryIndex {
		
		&__enhancedSearch {

			&Header {
				flex-direction: column;
				align-items: flex-start;
			}

			&Intro {
				order: 2;
				margin-top: 18px;
			}
		}
	}
}

@media screen and (max-width: $size-tablet-max) {
	.LibraryIndex--hide-tablet {
		display: none;
	}

	.LibraryIndex {
		&__titleBar {
			flex-wrap: wrap;
			margin: 25px 12px;
			height: auto;
		}

		&__search {
			flex: 1 0 100%;
			margin: 20px 0 15px;
		}

		&__enhancedSearch {
			margin-top: 0;
		}

		&__noResults {
			margin-top: 20vh;
		}
	}
}

@media screen and (min-width: $size-sm) {
	.LibraryIndex {

		&__titleBar {
			margin-left: 13px;
		}

		&__filterChips {
			margin-left: 0px;
		}

		&__item {
			flex: 1 1 252px;
			margin: 13px;
		}
	}
}

@media screen and (min-width: $size-desktop-min) {
	.LibraryIndex {
		&__titleBar {
			margin-left: 15px;
			justify-content: flex-end;
			position: relative;

			&__title {
				position: absolute;
				left: 0;
			}
		}

		&__icon {
			order: 1;
			margin-right: 1vw;
		}

		&__search {
			flex: 1 0 25%;
			margin-right: 20px;
			max-width: 400px;
		}

		&__filterChips {
			margin-left: 10px;
		}

		&__content {
			max-width: 1400px;
			padding: 0 44px 70px;
		}

		&__item {
			flex: 1 1 28%;
			max-width: 30%;
		}
	}
}

@media screen and (min-width: 1354px) {
	.LibraryIndex {
		&__item {
			flex: 1 1 22.5%;
			max-width: 22.5%;
		}

		&__icon {
			order: 1;
			margin-right: 1.5vw;
		}
	}
}
