@import "../../Variables.scss";

.actionSteps {
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	height: 100%;

	&__filters {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 70px;
		padding: 0 23px;

		&__completion {
			background-color: #f7f7fa;
			border-radius: 20px;
			min-width: 162px;

			> button {
				background: transparent;
				border: none;
				color: #9d9d9d;
				font-size: 13px;
				font-weight: $fontWeight--medium;
				text-align: center;
				margin: 0;
				cursor: pointer;
				border-radius: 20px;
				height: 32px;

				&:first-child {
					padding: 5px 14px 5px 21px;
					&.active {
						padding: 5px 21px;
					}
				}

				&:last-child {
					padding: 5px 13px 5px 7px;
					&.active {
						padding: 5px 13px;
					}
				}

				&:focus {
					outline: 0;
				}

				&.active {
					color: var(--white);
					background-color: var(--blue);
					box-shadow: 2px 0 3px rgba(0, 0, 0, 0.24);
				}
			}
		}

		&__level {
			.MuiSelect-root {
				font-size: 13px;
				padding-left: 10px;
			}

			&Menu {
				.MuiMenu-list {
					max-height: 200px;
				}

				.MuiListItem-root {
					font-size: 14px;
				}
			}
		}
	}

	&__getStarted {
		background: #f7f7fa;
		border-radius: 15px;
		padding: 20px;
		font-size: 14px;
		line-height: 1.38;
		font-weight: $fontWeight--regular;
		margin: 20px;

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 183px;
			height: 39px;
			border-radius: 8px;
			background-color: var(--blue);
			color: var(--white);
			font-size: 13px;
			font-weight: $fontWeight--bold;
			margin-top: 20px;
		}
	}

	&__steps {
		padding: 0 10px;
		overflow-y: auto;
		flex: 1;
	}

	&__step {
		display: flex;
		flex-direction: column;
		min-height: 95px;
		border-radius: 10px;
		border: 1px solid #efefef;
		margin: 12px 0;
		cursor: pointer;
		position: relative;
		justify-content: space-between;
		padding: 13px 15px 12px 43px;
		box-sizing: border-box;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 7px;
			height: 100%;
			background: var(--blue);
			border-radius: 10px 0 0 10px;
			visibility: hidden;
		}

		&:hover {
			box-shadow: 0 2px 5px rgba(0, 0, 0, 0.11);
		}

		&.active {
			&::before {
				visibility: visible;
			}
		}

		&Status {
			display: flex;
			width: 18px;
			height: 18px;
			position: absolute;
			top: 13px;
			left: 12px;
			border: 1px solid #d3d3d3;
			border-radius: 50%;
			align-items: center;
			justify-content: center;

			&--complete {
				background: var(--blue);
				border-color: var(--blue);
			}

			&Icon {
				width: 14px;
				height: 14px;
				fill: var(--white);
				color: var(--white);
			}
		}

		&Title {
			font-size: 14px;
			font-weight: $fontWeight--medium;
			line-height: 1.36;
			color: #383838;
		}

		&Meta {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&Level {
			padding: 4px 0;
			width: 50px;
			text-align: center;
			font-size: 9px;
			font-weight: $fontWeight--medium;
			position: relative;
			z-index: 1;
			border: 1px solid #d3d3d3;
			border-radius: 10px;

			// &::before {
			// 	content: "";
			// 	display: block;
			// 	position: absolute;
			// 	top: 0;
			// 	left: 0;
			// 	z-index: -1;
			// 	height: 18px;
			// 	width: 50px;
			// 	border-radius: 3px;
			// 	background-color: #d4eab2;
			// 	width: 100%;
			// 	height: 100%;
			// 	opacity: 0.5;
			// }

			&.level1 {
				color: #195a80;
			}
			&.level2 {
				color: #ff7600;
			}
			&.level3 {
				color: #5a33db;
			}
			&.level4 {
				color: #b21010;
			}
			&.level5 {
				color: #558014;
			}
			&.level6 {
				color: #a8004c;
			}
			&.level7 {
				color: #1aa395;
			}
			&.level8 {
				color: #00507c;
			}
			&.level9 {
				color: #c81ed6;
			}
			&.level10 {
				color: #a31a28;
			}
		}

		&Date {
			font-size: 10px;
			font-weight: $fontWeight--medium;
			color: #707070;
		}
	}
}

@media screen and (min-width: $size-desktop-min) {
	.actionSteps {
		&__filters {
			padding: 0 20px;
		}

		&__steps {
			padding: 0 20px;
		}
	}
}
