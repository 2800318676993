@import "../../Variables.scss";

.Login {
	display: flex;
	width: 100%;
	min-height: 100vh;

	&__oneTimePasswordMessage {
		margin-bottom: 20px;
	}

	&__header {
		display: none;
	}

	&__award {
		display: flex;
		position: fixed;
		bottom: 100px;
		left: 0;
		z-index: 100;
		padding: 5px 56px;
		background: white;
		border-radius: 0 46px 46px 0;
		align-items: center;
		box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);

		&Image {
			height: 83px;
			width: auto;
		}

		&Meta {
			margin-left: 26px;
		}

		&Title {
			margin: 0 0 4px;
			padding: 0;
			font-size: 26px;
			font-weight: $fontWeight--bold;
			color: var(--blue);
		}

		&Subtitle {
			margin: 0;
			padding: 0;
			font-size: 16px;
			color: var(--grey)
		}
	}

	&__oneTimeStatusMessage {
		margin-bottom: 20px;
	}

	&__about,
	&__login {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	&__about {
		background: var(--blue);
		background: linear-gradient(180deg, var(--dark-blue) 0%, var(--blue) 100%);
		color: var(--white);
		position: relative;

		&Logo {
			max-width: 138px;
			max-height: 44px;
			width: auto;
			height: auto;
			position: absolute;
			top: 80px;
			left: 50%;
			transform: translateX(-50%);
		}

		&Meta {
			width: 67%;
			text-align: center;
		}

		&Title {
			font-size: 32px;
			font-weight: $fontWeight--black;
			line-height: 1.34;
		}

		&Intro {
			font-size: 18px;
			font-weight: $fontWeight--regular;
			line-height: 1.78;
		}
	}

	&__login {
		position: relative;
		padding-top: 134px;

		&Form {
			width: 67%;
		}

		&Logo {
			max-width: 138px;
			max-height: 44px;
			width: auto;
			height: auto;
			position: absolute;
			top: 80px;
			left: 50%;
			transform: translateX(-50%);
		}

		&Title {
			font-size: 25px;
			font-weight: $fontWeight--bold;
			line-height: 1.32;
			text-align: center;
			margin: 0 0 20px;
			color: #073853;
		}

		&Subtitle {
			text-align: center;
			margin: 20px 0;
			color: var(--black);
			font-size: 14px;
			font-weight: $fontWeight--regular;
			line-height: 1.4;

			a {
				color: var(--blue);
			}
		}

		.customField {
			margin-bottom: 22px;
		}

		&Signup {
			font-size: 13px;
			font-weight: $fontWeight--regular;
			line-height: 1.54;
			text-align: center;

			a {
				font-weight: $fontWeight--bold;
				color: var(--dark-blue);
			}
		}

		&inputs--ssoOnly {
			display: none;
		}

		&Btn.MuiButton-root {
			font-size: 15px;
			font-weight: $fontWeight--bold;
			padding: 11px 16px;
			color: var(--white);
			background-color: var(--blue);
			box-shadow: none;
			border-radius: 10px;
			margin-bottom: 0px;

			&:hover {
				color: var(--white);
				background-color: var(--blue);
			}

			& + & {
				margin-top: 15px;
			}
		}

		&Btn--secondary.MuiButton-root {
			font-size: 15px;
			font-weight: $fontWeight--bold;
			padding: 11px 16px;
			color: var(--blue);
			border: 1px solid var(--blue);
			background-color: transparent;
			box-shadow: none;
			border-radius: 10px;
			margin-top: 0;

			&:hover {
				color: var(--white);
				background-color: var(--blue);
			}

		}

		&Btn--sso.MuiButton-root {
			background-color: transparent;
			color: var(--blue);
			border: 1px solid var(--blue);
			border-radius: 10px;
			margin-bottom: 0;
		}

		&Separator {
			width: 100%;
			text-align: center;
			border-bottom: 1px solid #cbd5e0;
			line-height: 0.1em;
			margin: 25px 0;

			span {
				color: #718096;
				background: #f7f7fa;
				padding: 0 10px;
				font-weight: $fontWeight--bold;
				font-size: 15px;
			}
		}

		& .MuiOutlinedInput-notchedOutline {
			border-radius: 10px;
		}
	}

	&__ssoInstructions {
		background-color: var(--white);
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
		padding: 20px;
		border-radius: 10px;
		font-size: 14px;
		line-height: 1.4;
		margin-bottom: 30px;
	}

	&__error {
		font-size: 11px;
		font-weight: $fontWeight--regular;
		line-height: 1.3;
		color: #e53e3e;
		margin-bottom: 20px;
	}

	&__requestPassword {
		font-weight: $fontWeight--bold;
		color: var(--blue);
		cursor: pointer;
	}

	&__requestOneTimePasswordBtn.MuiButton-root {
		font-size: 15px;
		font-weight: $fontWeight--bold;
		padding: 11px 16px;
		color: var(--white);
		background-color: var(--blue);
		box-shadow: none;
		border-radius: 10px;
		margin-bottom: 22px;
		margin-left: auto;
		margin-right: auto;
		display: block;

		&:hover {
			color: var(--white);
			background-color: var(--blue);
		}
	}
}

@media (max-width: 1033px) {
	.Login {
		&__award {
			padding: 5px 25px;

			&Image {
				height: 63px;
			}

			&Meta {
				margin-left: 26px;
			}

			&Title {
				font-size: 22px;
			}

			&Subtitle {
				font-size: 14px;
			}
		}
	}
}

@media (max-width: 768px) {
	.Login {
		display: block;

		&__award {
			position: static;
			margin-bottom: 30px;
			padding: 5px 56px 5px 16px;
			max-width: 430px;
			margin-right: 20px;

			&Image {
				height: 83px;
			}

			&Meta {
				margin-left: 26px;
			}

			&Title {
				font-size: 26px;
			}

			&Subtitle {
				font-size: 16px;
			}
		}

		&__about {
			display: none;
		}

		&__header {
			display: flex;
			background: var(--white);
			width: 100%;
			flex: 0 0 100%;
			align-items: center;
			justify-content: center;
			height: 53px;
			box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

			&Logo {
				width: 107px;
				height: 29px;
			}
		}

		&__login {
			padding-top: 22px;
			padding-bottom: 22px;
			height: 100%;

			&Logo {
				position: relative;
				top: auto;
				left: auto;
				transform: none;
				display: block;
				margin: 0 auto 30px;
			}
		}
	}
}

@media (max-width: 500px) {
	.Login {
		&__award {
			padding: 5px 56px 5px 16px;

			&Image {
				height: 73px;
			}

			&Meta {
				margin-left: 26px;
			}

			&Title {
				font-size: 22px;
			}

			&Subtitle {
				font-size: 16px;
			}
		}

		&__login {
			padding-left: 22px;
			padding-right: 22px;

			&Form {
				width: 100%;
			}
		}
	}
}
