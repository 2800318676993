.progressBar {
	height: 6px;
	border-radius: 100vw;
	overflow: hidden;
	background-color: #e2e2e2;

	&__fill {
		height: 100%;
		border-radius: 100vw;
	}
}
