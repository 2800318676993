@import '../../Variables.scss';

.SecureSave {
	padding: 33px 45px;
	max-width: 1200px;

	h1 {
		color: var(--dark-blue);
		font-size: 25px;
		font-weight: $fontWeight--bold;
		margin: 0 0 23px;
		padding: 0;
	}

	h2 {
		color: var(--dark-blue);
		font-size: 20px;
		font-weight: $fontWeight--bold;
		margin: 30px 0 15px;
		padding: 0;
		line-height: 1.4;
	}

	iframe {
		display: none;
		width: 100%;
		height: calc(100vh - 200px);
		max-height: 530px;
		border: none;
		border-radius: 8px;
		box-shadow: 0 3px 10px #00000029;
	}

	iframe.iframe-success {
		display: block;
	}

	&Error {
		width: 100%;
		max-width: 800px;
		min-height: 100%;
		display: flex;
		margin: 0 auto;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 15% 9%;
		box-sizing: border-box;

		&__image {
			width: 100%;
			max-width: 400px;
		}

		&__title {
			color: #454f63 !important;
			font-size: 24px !important;
			font-weight: $fontWeight--black;
			margin: 40px 0 !important;
		}

		&__text {
			text-align: center;
			line-height: 1.5;
			font-size: 15px;
			font-weight: $fontWeight--regular;
			margin: 0 0 30px;
		}

		&__link {
			font-size: 12px;
			font-weight: $fontWeight--bold;
			color: var(--white);
			background: var(--blue);
			border-radius: 20px;
			padding: 13px 20px;
		}

		&__url {
			color: #a2a2a2 !important;
			text-align: center;
			margin-top: 20px;
			font-size: 12px;
		}
	}
}

@media (max-width: 1155px) {
	.SecureSave {
		&__getStartedContainer {
			flex-direction: column;
		}

		&__getStarted {
			width: 100%;
		}
	}
}

@media (max-width: 768px) {
	.SecureSave {
		padding: 20px 20px;
	}
}

@media (max-width: 500px) {
	.SecureSave {
		padding: 20px 10px;
	}
}
