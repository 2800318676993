@import "../../../Variables.scss";

.emptyDetails {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	text-align: center;
	padding: 30px 0;
	box-sizing: border-box;

	&__icon {
		width: 70px;
		height: 70px;
		margin: 16px 0 0 24px;

		&Container {
			background: #f7f7fa;
			width: 89px;
			height: 89px;
			border-radius: 50%;
			overflow: hidden;
			margin-bottom: 27px;
		}
	}

	h1 {
		margin: 0 0 13px;
		padding: 0;
		color: #073853;
		font-size: 20px;
		font-weight: $fontWeight--bold;
	}

	p {
		max-width: 370px;
		margin: 0;
		padding: 0;
		font-size: 14px;
		font-weight: $fontWeight--regular;
		line-height: 1.57;
	}

	a {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 183px;
		height: 39px;
		border-radius: 8px;
		background-color: var(--blue);
		color: var(--white);
		font-size: 13px;
		font-weight: $fontWeight--bold;
		margin-top: 20px;
	}
}
