@import '../../../Variables.scss';

.intlMyCourses {
    margin: 25px 33.33% 25px 32px;

    &__title {
        font-size: 25px;
        font-weight: $fontWeight--bold;
        color: var(--dark-blue);
        margin: 0 0 22px;
        padding: 0;
    }

    .MuiAccordion-root {
        margin-bottom: 25px !important;
        box-shadow: 2px 4px 10px rgba(0,0,0,0.16) !important;
        border-radius: 10px !important;
    }

    .MuiAccordionSummary-root {
        padding: 0 25px !important;
        align-items: flex-start !important;
    }

    .MuiAccordionSummary-content {
        margin: 25px -35px 25px 0 !important;
        flex-wrap: wrap !important;
    }

    .MuiAccordionSummary-expandIcon {
        margin-top: 15px !important;
    }

    .MuiAccordionDetails-root {
        padding: 0 25px 25px !important;
    }
}

@media (max-width: 1024px) {
    .intlMyCourses {
        margin: 25px auto;
        max-width: 686px;
    }
}

@media (max-width: 768px) {
    .intlMyCourses {
        margin: 25px 10px;
        max-width: 100%;
    }
}