@import "../../Variables.scss";

.pwaBanner {
	position: fixed;
	bottom: 20px;
	background-color: var(--blue);
	color: var(--white);
	z-index: 1500;
	padding: 23px 30px 20px 20px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	width: 90%;
	max-width: 380px;
	left: 50%;
	transform: translateX(-50%);
	box-shadow: 0px 3px 10px #00000029;

	&__close {
		width: 40px;
		height: 40px;
		position: absolute;
		top: 0;
		right: 0;
		background-color: transparent;
		color: var(--white);
		border: none;
		outline: none;
		padding: 0;

		svg {
			width: 11px;
			height: 11px;
		}
	}

	&__logo {
		margin-right: 20px;
		display: flex;
		align-items: center;

		img {
			width: 50px;
			height: 50px;
		}
	}

	&__instructions {
		font-size: 14px;
		line-height: 1.4;

		&Title {
			font-weight: $fontWeight--bold;
		}

		svg {
			width: 13px;
			height: 15px;
			margin: 0 4px;
			vertical-align: text-top;
		}
	}
}

@media screen and (min-width: $size-desktop-min) {
	.pwaBanner {
		display: none;
	}
}
