@import "../../Variables.scss";

.MyFiancialsInfoPopup {
	display: inline-block;
	color: var(--blue);

	&__container {
		padding: 24px;
		position: relative;
		background-color: white;
		box-shadow: 0 3px 10px #00000029;
		border-radius: 10px;
		overflow: hidden;
		box-sizing: border-box;
		width: 85vw;
		max-width: 360px;
	}

	&__title {
		display: flex;
		align-items: center;
		gap: 10px;
		font-size: 15px;
		font-weight: 600;
		color: var(--blue);
	}

	&__text {
		margin: 10px 0 0;
		font-size: 14px;
		line-height: 22px;
	}

	&__button {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		background-color: transparent;
		border: none;
		padding: 0;
		cursor: pointer;
		color: inherit;

		&--info {
			width: 20px;
			aspect-ratio: 1;
		}

		&--close {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 20px;
			aspect-ratio: 1;
		}
	}

	&__icon {
		&--info.MuiSvgIcon-root {
			width: 16px;
			height: 16px;
			color: inherit;
		}

		&--blue.MuiSvgIcon-root {
			color: var(--blue);
		}

		&--close {
			width: 10px;
			aspect-ratio: 1;
			color: #707070;
		}
	}
}
