.backNavigation {
	display: inline-flex;
	align-items: center;
	gap: 7px;
	padding: 0;
	border: none;
	background-color: transparent;
	cursor: pointer;

	> * {
		flex: 1;
		white-space: nowrap;
	}

	&__left {
		height: 40px;
		aspect-ratio: 13 / 20;
	}
}