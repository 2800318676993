@import '../../Variables.scss';

.Invite {
    padding: 35px 32px;

    &__title {
        margin: 0;
        font-size: 25px;
        font-weight: $fontWeight--bold;
        color: var(--dark-blue);
    }

    &__container {
        margin: 25px 0;
        background: var(--white);
        border-radius: 15px;
        padding: 34px 53px 47px;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);

        h2 {
            margin: 0 0 10px;
            padding: 0;
            font-size: 20px;
            line-height: 1.27;
            font-weight: $fontWeight--bold;
        }

        h3 {
            margin: 0 0 10px;
            padding: 0;
            font-size: 16px;
            font-weight: $fontWeight--medium;
        }

        p {
            line-height: 1.4;
            font-size: 14px;
        }
    }

    &__input {
        margin-bottom: 20px !important;
    }

    &__submit {
        margin-top: 20px !important;
    }

    &__status {
        background: #f2f2f2;
        border-radius: 15px;
        padding: 20px;
        margin-top: 30px;
    }
}

@media (max-width: 500px) {
    .Invite {

        &__container {
            padding-left: 30px;
            padding-right: 30px;
            margin-left: -32px;
            margin-right: -32px;
            border-radius: 0;
        }
    }
}