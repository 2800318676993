@import "../../Variables.scss";

.MyFinancialsSavingsGoalNotification {
	color: var(--white);
	background: var(--light-blue);
	background: linear-gradient(180deg, var(--light-blue) 0%, var(--blue) 100%);
	border-radius: 15px;
	box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.16);
	padding: 20px 24px;
	position: relative;
	order: -1;

	&__close {
		width: 24px;
		height: 24px;
		border: 0;
		margin: 0;
		padding: 0;
		background: none;
		position: absolute;
		top: 12px;
		right: 12px;
		cursor: pointer;

		&Icon {
			width: 14px;
			height: 14px;
			color: var(--white);
		}
	}

	&__icon {
		width: 20px;
		height: 20px;

		&Container {
			background: var(--white);
			border-radius: 50%;
			width: 24px;
			height: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&__title {
		margin: 0;
		padding: 0;
		font-size: 18px;
		font-weight: $fontWeight--bold;
		margin: 10px 0 8px;
	}

	&__description {
		margin: 0;
		padding: 0;
		font-size: 15px;
		font-weight: $fontWeight--regular;
		line-height: 1.4;
		margin-bottom: 13px;
	}

	&__cta {
		width: 100%;
		background: var(--dark-blue) !important;
		color: var(--white) !important;
		font-size: 16px !important;
		font-weight: $fontWeight--bold !important;
		border-radius: 10px !important;
		padding-top: 7px !important;
		padding-bottom: 7px !important;
	}

	@media screen and (min-width: $size-tablet-min) {
		order: 0;
	}
}

.MyFinancialsSavingsGoal__form {
	background-color: var(--white);
	border-radius: 15px;
	box-shadow: 0 3px 6px #00000029;
	overflow: hidden;
	padding: 30px 24px 95px;

	h2 {
		margin-top: 0;
		font-size: 20px;
	}

	> * + * {
		margin-top: 10px;
	}

	&__label {
		display: flex;
		align-items: center;
		gap: 5px;
		font-size: 16px;
		font-weight: 600;
	}

	* + &__label {
		margin-top: 30px;
	}

	&__input {
		.MuiOutlinedInput-input {
			padding: 13px 15px;
			border-radius: 8px;
		}

		.MuiOutlinedInput-notchedOutline {
			border-color: #cecece;
			border-radius: 8px;
		}

		.MuiInputBase-root.Mui-disabled {
			color: currentColor;
		}

		.MuiInputBase-input.Mui-disabled {
			background-color: #f5f5f5;
		}

		.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
			border: none;
		}

		.MuiInputAdornment-positionEnd {
			color: #cecece;
		}

		&Adornment {
			color: #707070;
		}

		&Date {
			.MuiOutlinedInput-input {
				padding-right: 5px;
			}

			.MuiInputAdornment-positionEnd {
				margin-left: 0;

				.MuiIconButton-root{
				padding-inline: 0px;
				margin-right: -5px;
			}}
		}

		&--goalAmount {
			margin-top: 10px !important;
		}
	}

	&__selectMenu .MuiMenu-paper {
		border-radius: 8px;
	}

	&--alignRight {
		text-align: right;

		input {
			text-align: right;
		}
	}

	&__separator {
		border: 1px solid #f5f5f5;
		margin: 30px -24px;
	}

	&__darkBg {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: 8px;
		background-color: #f5f5f5;
		overflow: hidden;
		padding: 13px 15px;

		& > *:first-child:not(:only-child) {
			color: #707070;
		}
	}

	&__connectAccount {

		margin-top: 10px !important;

		&.MuiButton-text {
			justify-content: flex-start;
			border-radius: 8px;
			background-color: #f5f5f5;
			overflow: hidden;
			padding: 13px 15px;
		}

		.MuiButton-label {
			font-size: 16px;
			text-transform: none;
			line-height: 1;
		}
	}

	&__save {
		&.MuiButtonBase-root {
			margin-top: 30px;
			border-radius: 8px;
			box-shadow: none;
		}
	}

	&__disclaimer {
		margin-top: 95px;
		font-size: 13px;
		color: #cecece;

		span {
			font-weight: 600;
		}
	}
}

.MyFinancialsSavingsGoal {
	display: grid;
	grid-template-columns: minmax(10px, auto) 1fr minmax(10px, auto);
	padding-bottom: 60px;

	@media screen and (min-width: $size-tablet-min) {
		grid-template-columns: minmax(25px, auto) minmax(500px, 1025px) minmax(
				25px,
				auto
			);
	}

	&__header {
		grid-column: 2;
		display: inline-block;
	}

	&__title {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		height: 88px;
		color: #073853;

		@at-root button#{&} {
			border: none;
			background-color: transparent;
			cursor: pointer;
		}

		h1 {
			margin: 0;
			font-size: 25px;
			font-weight: 600;
		}
	}

	&__back {
		display: flex;
		align-items: center;
		height: 32px;
		width: 32px;

		@media screen and (min-width: $size-desktop-min) {
			margin: -2px 10px -2px 0px;
		}
	}

	&__goal {
		grid-column: 2;
		justify-self: center;

		display: grid;
		grid-template-columns: minmax(auto, 500px);
		gap: 25px;

		@media screen and (min-width: $size-tablet-min) {
			grid-template-columns: repeat(2, minmax(0, 500px));
			align-items: start;
		}

		&--new {
			> *:first-child {
				display: none;
			}

			@media screen and (min-width: $size-tablet-min) {
				> *:first-child {
					display: block;
				}
			}
		}
	}
}
