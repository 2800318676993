@import "../../Variables.scss";

.MyFinancialsGoalSetup {
	&__choose {
		display: grid;
		grid-template-columns: minmax(10px, auto) 1fr minmax(10px, auto);
		padding-bottom: 60px;

		@media screen and (min-width: $size-tablet-min) {
			grid-template-columns: 32px 1fr 32px;
		}
	}

	&__header {
		grid-column: 2;
		display: inline-block;
	}

	&__title {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		height: 88px;
		color: #073853;

		h1 {
			margin: 0;
			font-size: 25px;
			font-weight: 600;
		}
	}

	&__back {
		display: flex;
		align-items: center;
		height: 32px;
		width: 32px;

		@media screen and (min-width: $size-desktop-min) {
			margin: -2px 10px -2px 0px;
		}
	}

	&__options {
		grid-column: 2;

		display: grid;
		grid-template-columns: repeat(auto-fill, 170px);
		gap: 15px;

		@media screen and (max-width: 560px) {
			justify-content: center;
		}

		button {
			cursor: pointer;
		}
	}

	&__option {
		display: grid;
		justify-content: center;
		grid-template-columns: 80px;
		grid-template-rows: auto max-content;
		justify-items: center;
		gap: 30px;
		place-items: center;
		height: 180px;
		background-color: var(--white);
		border: none;
		border-radius: 10px;
		box-shadow: 0 3px 6px #00000029;
		padding: 25px 20px 20px;

		&Icon {
			align-self: center;

			width: 65px;
			aspect-ratio: 1;

			// &--my-financials-savings {
			// 	width: 56px;
			// }

			// &--my-financials-car {
			// 	width: 85px;
			// 	margin-bottom: -10px;
			// }

			// &--my-financials-vacation {
			// 	width: 60px;
			// }

			// &--my-financials-home {
			// 	width: 56px;
			// }

			// &--my-financials-piggy-bank {
			// 	width: 56px;
			// }
		}

		&Title {
			align-self: start;
			line-height: 1.3;
		}
	}
}
