@import '../../../Variables.scss';

.intlMyCourseModules {
	&__title {
		color: var(--dark-blue);
		font-size: 18px;
		font-weight: $fontWeight--bold;
	}

	&__module {
		background: var(--white);
		box-shadow: 2px 4px 10px rgba(0,0,0,0.16);
		border-radius: 10px;
		display: flex;
		align-items: center;
		padding: 15px;
		margin-bottom: 8px;
		color: #383838;

		&Image {
			width: 75px;
			height: 75px;
			margin-right: 20px;
			position: relative;
		}

		&Icon {
			width: 68px;
			height: 68px;
		}

		&Letter {
			font-size: 12px;
			font-weight: $fontWeight--bold;
			color: var(--white);
			width: 20px;
			height: 20px;
			display: grid;
			align-items: center;
			justify-content: center;
			border-radius: 20px;
			background: var(--grey);
			position: absolute;
			bottom: 0;
			right: 0;
		}

		&Meta {
			flex: 1;
		}

		&Label {
			font-size: 14px;
			font-weight: $fontWeight--bold;
			margin: 0 0 5px;
			padding: 0;
		}

		&Title {
			font-size: 16px;
			font-weight: $fontWeight--bold;
			margin: 0;
			padding: 0;
		}

		&Description {
			flex: 0 0 100%;
			font-size: 14px;
			font-weight: $fontWeight--regular;
			line-height: 1.4;
		}

		&Progress {
			display: flex;
			align-items: center;
			margin-top: 5px;
			flex: 0 0 100%;

			&Bar {
				flex: 1;
				height: 6px;
				border-radius: 3px;
				background: var(--x-light-grey);
				position: relative;

				&Progress {
					height: 6px;
					border-radius: 3px;
					position: absolute;
					top: 0;
					left: 0;
					min-width: 6px;
				}
			}

			&Label {
				width: 45px;
				margin-left: 10px;
				font-size: 16px;
				font-weight: $fontWeight--medium;
				text-align: right;
			}
		}
	}

	&__courses {
		width: 100%;
	}

	&__separator {
		height: 1px;
		background: var(--x-light-grey);
		border: 0;
		padding: 0;
		margin: 0 0 20px;
	}

	&__courses {

		&Title {
			font-size: 14px;
			font-weight: $fontWeight--bold;
			margin: 0 0 15px;
			padding: 0;
		}
	}

	&__course {
		display: flex;
		align-items: center;
		border-radius: 15px;
		border: 1px solid var(--x-light-grey);
		padding: 15px;
		color: inherit;

		&Meta {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
		}

		&Image {
			width: 73px;
			height: 73px;
			background-position: 50% 50%;
			background-size: cover;
			border-radius: 10px;
			margin-right: 13px;
		}

		&Title {
			font-size: 16px;
			font-weight: $fontWeight--medium;
			margin: 0 0 18px;
			padding: 0;
		}

		&Duration {
			font-size: 14px;
			font-weight: $fontWeight--medium;
			color: var(--light-grey);
			margin: 0;
			padding: 0;
		}

		&Cta {
			height: 34px;
			border-radius: 17px;
			color: var(--white);
			background: var(--blue);
			padding: 0 20px;
			display: flex;
			font-size: 14px;
			font-weight: $fontWeight--bold;
			justify-content: center;
			align-items: center;
			margin-left: 13px;

			&--completed {
				color: var(--blue);
				background: #007CAB17
			}
		}
	}
}

@media (max-width: 550px) {
	.intlMyCourseModules {

		&__course {
			flex-wrap: wrap;

			&Cta {
				margin-top: 10px;
				width: 100%;
			}
		}
	}
}