@import '../../Variables.scss';

.intlFinancialCoaching {
	max-width: 700px;
	margin: 33px auto 60px;
	padding: 0 10px 0;

	&__title {
		font-size: 25px;
		font-weight: $fontWeight--bold;
		margin: 0 0 22px;
		padding: 0;
		color: var(--blue);
	}

	&__schedule {
		background: var(--white);
		border-radius: 25px;
		box-shadow: 2px 4px 10px rgba(0,0,0,0.16);
		margin-bottom: 25px;
		padding: 25px;
		display: flex;
		align-items: center;

		&Icon {
			flex: 0 0 68px;
			height: 68px;
			margin-right: 15px;
		}

		&Meta {
			flex: 1
		}

		&Title {
			font-size: 24px;
			font-weight: $fontWeight--bold;
			color: var(--blue);
			margin: 0 0 8px;
			padding: 0;
		}

		&Description {
			font-size: 16px;
			font-weight: $fontWeight--regular;
			line-height: 1.4;
		}

		&Cta {
			color: var(--white);
			background: var(--blue);
			font-size: 14px;
			font-weight: $fontWeight--bold;
			width: 152px;
			height: 39px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 20px;
			margin-left: 15px;
		}
	}

	&__experience {
		color: var(--white);
		background: linear-gradient(270deg, var(--light-blue), var(--blue));
		border-radius: 15px;
		box-shadow: 0 3px 10px rgba(0,0,0,0.16);
		margin-bottom: 25px;
		padding: 25px;

		&Title {
			font-size: 22px;
			font-weight: $fontWeight--bold;
			margin: 0 0 10px;
			padding: 0;
		}

		&Description {
			font-size: 15px;
			font-weight: $fontWeight--regular;
			line-height: 1.4;
		}
	}

	&__about {
		background: var(--white);
		border-radius: 25px;
		box-shadow: 0 4px 8px rgba(0,0,0,0.16);
		margin-bottom: 25px;
		padding: 25px;

		&Title {
			color: var(--dark-blue);
			margin: 0 0 15px;
			font-size: 18px;
			font-weight: $fontWeight--bold;
		}

		&List {
			margin-bottom: 35px;

			&Item {
				display: flex;
				margin-bottom: 28px;

				&Icon {
					background: #F7F7FA;
					border-radius: 8px;
					display: flex;
					justify-content: center;
					align-items: center;
					height: 68px;
					flex: 0 0 68px;
					margin-right: 40px;

					img {
						height: 41px;
						width: auto;
					}
				}

				&Meta {
					flex: 1;
				}
	
				&Title {
					color: var(--blue);
					font-size: 17px;
					font-weight: $fontWeight--bold;
					margin: 0 0 6px;
					padding: 0;
				}

				&Meta {
					flex: 1;
				}
	
				&Description {
					font-size: 15px;
					font-weight: $fontWeight--regular;
					line-height: 1.4;
					margin: 0;
					padding: 0;
				}
			}
		}

		&Video {
			border-radius: 15px;
			overflow: hidden;
		}
	}
}

@media (max-width: 550px) {

	.intlFinancialCoaching {

		&__schedule {
			flex-wrap: wrap;

			&Cta {
				flex: 0 0 100%;
				margin-top: 15px;
				margin-left: 0;
			}
		}
	}
}