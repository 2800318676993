@import "../../Variables.scss";

.MilestonesActionItems {
	margin-top: 52px;
	background: var(--white);
	padding: 32px 22px;
	box-sizing: border-box;
	flex: 1;

	&Container {
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	&__close {
		color: #383838;
		display: inline-block;
		padding: 5px;
		position: absolute;
		top: 66px;
		right: 14px;

		svg {
			width: 14px;
			height: 14px;
		}
	}

	&__header {
		display: flex;
	}

	&__icon {
		height: 60px;
		flex: 0 0 60px;
		padding-right: 8px;
	}

	&__titles {
		flex: 1;
	}

	&__level,
	&__title {
		font-weight: $fontWeight--bold;
		margin: 0;
		padding: 0;
	}

	&__level {
		font-size: 15px;
		margin-top: 8px;

		&--level1 {
			color: var(--level1);
		}
		&--level2 {
			color: var(--level2);
		}
		&--level3 {
			color: var(--level3);
		}
		&--level4 {
			color: var(--level4);
		}
		&--level5 {
			color: var(--level5);
		}
		&--level6 {
			color: var(--level6);
		}
		&--level7 {
			color: var(--level7);
		}
		&--level8 {
			color: var(--level8);
		}
		&--level9 {
			color: var(--level9);
		}
		&--level10 {
			color: var(--level10);
		}
	}

	&__title {
		font-size: 16px;
		margin-top: 3px;
	}

	&__description {
		font-size: 14px;
		line-height: 1.43;
		margin: 10px 0 25px;
	}

	&__status {
		margin: 25px 0 0;
		background: #007cab1a;
		border-radius: 8px;
		padding: 14px 12px;
		font-weight: $fontWeight--bold;
		font-size: 15px;
		color: var(--blue);
		text-align: center;
		border: 0;
		cursor: pointer;

		&--achievedState {
			margin-top: 0;
		}
	}

	&__add {
		color: var(--blue);
		cursor: pointer;
		padding-inline-start: 4px;
		font-weight: $fontWeight--medium;

		&Container {
			font-size: 14px;
		}
	}

	&__achieved {
		background: #f7f7fa;
		border-radius: 15px;
		margin: 39px 0;
		padding: 30px 25px;
		text-align: center;

		&Icon {
			width: 118px;
			height: 107px;

			&--achievedState {
				width: 70px;
				height: 63px;
			}
		}

		&Title {
			font-size: 20px;
			font-weight: $fontWeight--bold;
			margin: 0 0 15px;
			padding: 0;
			color: #073853;
		}

		&Description {
			font-size: 15px;
			line-height: 1.33;
			font-weight: $fontWeight--regular;
			margin: 0 0 20px;
			padding: 0;
		}

		&Link {
			color: var(--blue);
			font-weight: $fontWeight--bold;
			font-size: 13px;
			line-height: 1.38;
		}
	}

	&__associated {
		font-weight: $fontWeight--bold;
		font-size: 15px;
		margin: 39px 0 0;
		padding: 0;
	}

	&__step {
		display: flex;
		flex-direction: column;
		min-height: 95px;
		border-radius: 10px;
		border: 1px solid #efefef;
		margin: 12px 0 0;
		position: relative;
		justify-content: space-between;
		padding: 13px 15px 12px 43px;
		box-sizing: border-box;
		cursor: pointer;

		&--hasAction {
			border-radius: 10px 10px 0 0;
			box-shadow: 0 2px 5px rgba(0, 0, 0, 0.11);
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 7px;
			height: 100%;
			background: var(--blue);
			border-radius: 10px 0 0 10px;
			visibility: hidden;
		}

		&:hover {
			box-shadow: 0 2px 5px rgba(0, 0, 0, 0.11);
		}

		&.active {
			&::before {
				visibility: visible;
			}
		}

		&Status {
			display: flex;
			width: 18px;
			height: 18px;
			position: absolute;
			top: 13px;
			left: 12px;
			border: 1px solid #d3d3d3;
			border-radius: 50%;
			align-items: center;
			justify-content: center;

			&--complete {
				background: var(--blue);
				border-color: var(--blue);
			}

			&Icon {
				width: 14px;
				height: 14px;
				fill: var(--white);
				color: var(--white);
			}
		}

		&Title {
			font-size: 14px;
			font-weight: $fontWeight--medium;
			line-height: 1.36;
			color: #383838;
		}

		&Meta {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&Level {
			padding: 4px 0;
			width: 50px;
			text-align: center;
			font-size: 9px;
			font-weight: $fontWeight--medium;
			position: relative;
			z-index: 1;
			border: 1px solid #d3d3d3;
			border-radius: 10px;

			&--level1 {
				color: var(--level1);
			}
			&--level2 {
				color: var(--level2);
			}
			&--level3 {
				color: var(--level3);
			}
			&--level4 {
				color: var(--level4);
			}
			&--level5 {
				color: var(--level5);
			}
			&--level6 {
				color: var(--level6);
			}
			&--level7 {
				color: var(--level7);
			}
			&--level8 {
				color: var(--level8);
			}
			&--level9 {
				color: var(--level9);
			}
			&--level10 {
				color: var(--level10);
			}
		}

		&Date {
			font-size: 10px;
			font-weight: $fontWeight--medium;
			color: #707070;
		}

		&Footer {
			text-align: right;
			font-size: 13px;
			line-height: 1.38;
			font-weight: $fontWeight--medium;
			margin: 6px 0 12px;

			&--inProgress {
				color: #558014;
			}

			&--added,
			&--add,
			&--aimee {
				text-align: center;
				color: var(--white);
				min-height: 37px;
				border: 0;
				padding: 0;
				margin: 0;
				border-radius: 0 0 10px 10px;
				display: flex;
				width: 100%;
				align-items: center;
				justify-content: center;
			}

			&--add {
				cursor: pointer;
				background: var(--blue);

				&:hover {
					box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
				}

				svg {
					width: 18px;
					height: 18px;
					margin-right: 5px;
				}
			}

			&--added {
				background: #9d9d9d;
			}

			&--aimee {
				background: linear-gradient(to right, var(--dark-blue), var(--blue));

				&:hover {
					box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
				}

				img {
					width: 18px;
					height: 18px;
					margin-right: 10px;
				}
			}
		}
	}
}

@media screen and (min-width: $size-tablet-min) {
	.MilestonesActionItems {
		&__items {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		&__stepContainer {
			flex-basis: 49%;
		}
	}
}

@media screen and (min-width: $size-desktop-min) {
	.MilestonesActionItems {
		margin: 25px 12%;
		border-radius: 15px;
		box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
		padding: 50px;
		max-width: 708px;

		&Container {
			.SiteDisclaimer {
				max-width: 708px;
				margin-left: 12%;
				margin-right: 12%;
				margin-bottom: 25px;
				box-sizing: border-box;
			}
		}

		&__close {
			top: 27px;
			right: 33px;

			svg {
				width: 14px;
				height: 14px;
			}
		}

		&__status {
			width: 200px;
		}

		&__achieved {
			padding: 30px 30px 30px 154px;
			text-align: left;
			position: relative;

			&--achievedState {
				padding-left: 100px;
			}

			&Icon {
				position: absolute;
				top: 20px;
				left: 15px;
			}

			&Title {
				font-size: 16px;
				margin-bottom: 5px;
			}

			&Description {
				font-size: 14px;
				margin-bottom: 15px;
			}

			&Link {
				font-size: 14px;
			}
		}

		&__stepContainer {
			flex-basis: 48.5%;
		}

		&__step {
			&Footer {
				margin-bottom: 20px;
			}
		}
	}
}
