@import "../../Variables.scss";

.MyFinancialsDebtPayoffGoalNotification {
	color: var(--white);
	background: var(--light-blue);
	background: linear-gradient(180deg, var(--light-blue) 0%, var(--blue) 100%);
	border-radius: 15px;
	box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.16);
	padding: 20px 24px;
	position: relative;
	order: -1;

	&__close {
		width: 24px;
		height: 24px;
		border: 0;
		margin: 0;
		padding: 0;
		background: none;
		position: absolute;
		top: 12px;
		right: 12px;
		cursor: pointer;

		&Icon {
			width: 14px;
			height: 14px;
			color: var(--white);
		}
	}

	&__icon {
		width: 20px;
		height: 20px;

		&Container {
			background: var(--white);
			border-radius: 50%;
			width: 24px;
			height: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&__title {
		margin: 0;
		padding: 0;
		font-size: 18px;
		font-weight: $fontWeight--bold;
		margin: 10px 0 8px;
	}

	&__description {
		margin: 0;
		padding: 0;
		font-size: 15px;
		font-weight: $fontWeight--regular;
		line-height: 1.4;
		margin-bottom: 13px;
	}

	&__cta {
		width: 100%;
		background: var(--dark-blue) !important;
		color: var(--white) !important;
		font-size: 16px !important;
		font-weight: $fontWeight--bold !important;
		border-radius: 10px !important;
		padding-top: 7px !important;
		padding-bottom: 7px !important;
	}

	@media screen and (min-width: $size-tablet-min) {
		order: 0;
	}
}

.MyFinancialsDebtPayoffGoal__form {
	background-color: var(--white);
	border-radius: 15px;
	box-shadow: 0 3px 6px #00000029;
	overflow: hidden;
	padding: 30px 24px 95px;

	h2 {
		margin-top: 0;
		font-size: 20px;
	}

	> * + * {
		margin-top: 10px;
	}

	&__label {
		display: flex;
		align-items: center;
		gap: 5px;
		font-size: 16px;
		font-weight: 600;
	}

	* + &__label {
		margin-top: 30px;
	}

	&__input {
		.MuiOutlinedInput-input {
			padding: 13px 15px;
			border-radius: 8px;
		}

		.MuiOutlinedInput-notchedOutline {
			border-color: #cecece;
			border-radius: 8px;
		}

		.MuiInputBase-root.Mui-disabled {
			color: currentColor;
		}

		.MuiInputBase-input.Mui-disabled {
			background-color: #f5f5f5;
		}

		.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
			border: none;
		}

		&__value {
			float: right;
		}
	}

	&__selectMenu .MuiMenu-paper {
		border-radius: 8px;
	}

	&--alignRight {
		text-align: right;

		input {
			text-align: right;
		}
	}

	&__separator {
		border: 1px solid #f5f5f5;
		margin: 30px -24px;
	}

	&__accountColor {
		width: 10px;
		aspect-ratio: 1;
		border-radius: 50%;
		background-color: currentColor;
		margin: 0 auto;
	}

	$accountColors: #ffe200, #2eb872, #5333db, #fd5d89, #55abdd, #ff9300,
		#d01010, #955dfc, #43d5be, #073853, #a7d129, #930077;

	&__connectedAccounts {
		margin: 30px 0;

		table {
			width: 100%;
			border-collapse: collapse;
			font-size: 15px;
		}

		th {
			font-size: 14px;
			font-weight: 500;
			text-align: left;
			padding-left: 8px;
		}

		@for $i from 1 through length($accountColors) {
			tbody:nth-of-type(#{length($accountColors)}n + #{$i})
				.MyFinancialsDebtPayoffGoal__form__accountColor {
				color: nth($accountColors, $i);
			}
		}

		tbody:not(:first-of-type)::before {
			content: "";
			display: table-row;
			height: 11px;
		}

		tr {
			box-sizing: border-box;
			height: 35px;

			&:first-child {
				font-size: 16px;
			}
		}

		td:first-child {
			width: 34px;
		}

		td:nth-child(2) {
			max-width: 0;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		td:nth-child(3) {
			text-align: center;
			width: 80px;
		}

		td[colspan="2"]:nth-child(2) + td,
		td:nth-child(4) {
			text-align: right;
		}

		td:last-child {
			width: 24px;
		}
	}

	&__account {
		&Detail {
			background-color: #f5f5f5;

			tbody & {
				height: 42px;
			}

			td:first-child {
				border-top-left-radius: 8px;
			}

			td:last-child {
				border-top-right-radius: 8px;
			}

			button {
				display: flex;
				justify-content: center;
				align-items: center;
				aspect-ratio: 1;
				border: none;
				padding: 0;
				margin: 0 auto;
				background-color: transparent;
				color: #cecece;
				cursor: pointer;
			}
		}

		&Due {
			font-weight: 500;
			background-color: #e6e6e6;

			tbody & {
				height: 32px;
			}

			td:first-child {
				border-bottom-left-radius: 8px;
			}

			td:last-child {
				border-bottom-right-radius: 8px;
			}
		}

		&Expand {
			width: 14px;
			aspect-ratio: 1;
		}

		&Time {
			font-weight: 600;
		}
	}

	&__addAccount {
		&Container {
			border-radius: 8px;
			overflow: hidden;
			padding: 8px 10px;

			&--dark {
				background-color: #f5f5f5;
			}
		}

		&.MuiButton-root {
			.MuiButton-label {
				line-height: 1;
				text-transform: none;
				font-weight: 500;
				color: var(--blue);
			}
		}
	}

	&__darkBg {
		border-radius: 8px;
		background-color: #f5f5f5;
		overflow: hidden;
		padding: 13px 15px;
	}

	&__connectAccount {
		&.MuiButton-text {
			justify-content: flex-start;

			border-radius: 8px;
			background-color: #f5f5f5;
			overflow: hidden;
			padding: 13px 15px;
		}

		.MuiButton-label {
			font-size: 16px;
			text-transform: none;
			line-height: 1;
		}
	}

	&__save {
		&.MuiButtonBase-root {
			margin-top: 30px;
			border-radius: 8px;
			box-shadow: none;
		}
	}

	&__disclaimer {
		margin-top: 95px;
		font-size: 13px;
		color: #cecece;

		span {
			font-weight: 600;
		}
	}

	&__totalMinPayment,
	&__totalAdditionalPayments,
	&__totalPaidTowardDebt {
		background-color: #f5f5f5;
		color: #3f3f3f;
		border-radius: 8px;
		padding: 13px 15px;

		&__total {
			float: right;
		}
	}

	&__totalPaidTowardDebt {
		font-weight: 500;

		&__total {
			font-weight: 400;
		}
	}

	&__additionalPayments {
		width: 100%;

		&__label {
			position: absolute;
			padding: 13px 0 0 15px;
			color: #3f3f3f;
			pointer-events: none;
			z-index: 100;
		}

		input {
			text-align: right;
			background-color: transparent!important;
		}
	}

	@media screen and (min-width: $size-desktop-min) {
		grid-column: 2;
	}
}

.MyFinancialsDebtPayoffGoal {
	display: grid;
	grid-template-columns: minmax(10px, auto) 1fr minmax(10px, auto);
	padding-bottom: 60px;

	@media screen and (min-width: $size-tablet-min) {
		grid-template-columns: minmax(25px, auto) minmax(500px, 1025px) minmax(
				25px,
				auto
			);
	}

	&__header {
		grid-column: 2;
		display: inline-block;
	}

	&__title {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		height: 88px;
		color: #073853;

		@at-root button#{&} {
			border: none;
			background-color: transparent;
			cursor: pointer;
		}

		h1 {
			margin: 0;
			font-size: 25px;
			font-weight: 600;
		}
	}

	&__back {
		display: flex;
		align-items: center;
		height: 32px;
		width: 32px;

		@media screen and (min-width: $size-desktop-min) {
			margin: -2px 10px -2px 0px;
		}
	}

	&__goal {
		grid-column: 2;
		justify-self: center;

		display: grid;
		grid-template-columns: minmax(auto, 500px);
		gap: 25px;

		@media screen and (min-width: $size-tablet-min) {
			grid-template-columns: repeat(2, minmax(0, 500px));
			align-items: start;
		}

		&--new {
			> *:first-child {
				display: none;
			}

			@media screen and (min-width: $size-tablet-min) {
				> *:first-child {
					display: block;
				}
			}
		}
	}
}
