.nilMyCourses {
	display: grid;
	grid: repeat(5, min-content) / 2fr 1fr;
	gap: 30px;
	align-items: flex-start;
	max-width: 1200px;
	margin-block-start: 32px;
	margin-inline: 33px;
	padding-bottom: 70px;

	&__title {
		margin: 0;
		color: #073853;
		font-size: 25px;
		font-weight: 600;
	}

	&__module {
		grid-column: 1;
	}
	
	&__certificationProgress {
		grid-column: 2;
		grid-row: 1 / -1;
	}
}

@media (max-width: 1092px) {
	.nilMyCourses {
		grid: repeat(5, min-content) / 1fr;

		&__title,
		&__module {
			grid-column: span 5;
		}
		
		&__certificationProgress {
			grid-column: span 5;
			grid-row: -1;
		}
	}
}

@media (max-width: 500px) {
	.nilMyCourses {

		.nilCoursesGrid__list {
			grid-template-columns: repeat(auto-fit, 100%);
		}
		
		.nilCoursesGrid__list__item,
		.nilCourseCard {
			width: 100%;
		}
	}
}