@import "../../Variables.scss";

.MyFinancialsDashboardGoals {
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-block: 26px;
		margin-left: 12px;

		@media screen and (max-width: $size-mobile-landscape-max) {
			padding-top: 0;
		}

		h2 {
			margin: 0;
			font-size: 20px;
			font-weight: 600;
			color: #073853;
		}

		a,
		a:visited {
			color: var(--blue);
			font-weight: 500;
		}
	}

	&__goals {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(138px, 1fr));
		gap: 15px;
	}

	&__goal {
		display: grid;
		justify-content: center;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr repeat(3, auto);
		justify-items: center;
		gap: 10px;
		place-items: center;
		box-sizing: border-box;
		height: 193px;
		background-color: var(--white);
		border: none;
		border-radius: 15px;
		box-shadow: 0 3px 6px #00000029;
		padding: 23px 16px 25px;
		color: var(--dark-grey);
		text-align: center;

		&--new {
			grid-template-rows: auto auto;
			align-items: center;
			border: 2px solid var(--blue);
		}

		&Icon {
			width: 33px;
			aspect-ratio: 1;

			&--plus {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 45px;
				border-radius: 50%;
				background-color: var(--blue);
				color: var(--white);

				> * {
					width: 30px;
					aspect-ratio: 1;
				}
			}

			&--my-financials-savings {
				width: 36px;
			}

			&--my-financials-car {
				width: 43px;
				margin-bottom: -4px;
			}

			&--my-financials-vacation {
				width: 31px;
			}

			&--my-financials-home {
				width: 30px;
			}

			&--my-financials-piggy-bank {
				width: 30px;
			}
		}

		&Title {
			width: 80px;
			line-height: 1.3;

			span {
				font-weight: 600;
			}
		}

		&Amount {
			font-size: 12px;

			span {
				font-weight: 600;
			}
		}

		&Progress {
			display: flex;
			align-items: center;
			gap: 15px;
			font-size: 14px;
			width: 100%;

			&__bar {
				flex: 1;
				border-radius: 100vh;
				background-color: #cecece;
				height: 8px;
				overflow: hidden;

				&Fill {
					height: 100%;
					background-color: var(--blue);
				}
			}

			&__precentage {
				flex: 0 0 auto;
			}
		}
	}
}
