.emptyDetails {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	text-align: center;
	padding: 30px 0;

	img {
		width: 65px;
		height: 65px;
		margin-bottom: 27px;
	}

	h1 {
		margin: 0;
		color: #073853;
		font-size: 20px;
	}

	p {
		max-width: 342px;
	}
}
