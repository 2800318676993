@import "../../Variables.scss";

.IntlHomeRecommendations {
	display: flex;
	flex-direction: row;

	&__header {
		display: flex;
		margin: 0 0 13px;
	}

	&__title {
		color: var(--dark-blue);
		width: 50%;
		text-align: left;
		font-size: 16px;
		font-weight: $fontWeight--bold;
		margin: 0;
		padding: 0;
	}

	&__seeAll {
		width: 50%;
		color: var(--blue);
		text-align: right;
		font-weight: $fontWeight--medium;
		font-size: 13px;
	}

	&__body {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin: 0 -13px;
	}

	&__rec {
		color: inherit;
		border-radius: 10px;
		display: flex;
		align-items: flex-start;
		padding: 10px 13px;
		flex: 0 0 50%;
		box-sizing: border-box;

		&:hover {
			background-color: #f7f7fa;
		}

		&Image {
			width: 65px;
			height: 57px;
			border-radius: 10px;
			background-size: cover;
			background-position: 50% 50%;
			flex: 0 0 65px;
			margin-right: 17px;
		}

		&Details {
			flex: 1;
		}

		&Type {
			font-size: 9px;
			color: #707070;
			border: 1px solid #d3d3d3;
			border-radius: 10px;
			padding: 2px 5px;
			text-transform: capitalize;
			font-weight: $fontWeight--medium;
		}

		&Title {
			margin: 4px 0 0;
			padding: 0;
			font-weight: $fontWeight--regular;
			font-size: 13px;
			line-height: 1.38;
		}
	}
}

@media (max-width: 768px) {
	.IntlHomeRecommendations {
		&__rec {
			flex: 0 0 100%;
		}
	}
}
