@import "../../../../Variables.scss";

.pdfViewer__container {
    height: 870px;
}

@media screen and (min-width: $size-md) {
    .pdfViewer__container {
        height: 880px;
    }
}

@media screen and (min-width: $size-lg) {
    .pdfViewer__container {
        height: 1030px;
    }
}

@media screen and (min-width: $size-xl) {
    .pdfViewer__container {
        height: 1540px;
    }
}