@import '../../Variables.scss';

.OnlineStatus {
	font-size:  10px;
	font-weight: $fontWeight--regular;
	color: #9D9D9D;
	border: 1px solid #D3D3D3;
	border-radius: 15px;
	padding: 2px 10px;
	display: flex;
	align-items: center;
	max-width: 44px;
	line-height: 1;

	&__icon {
		width: 5px;
		height: 5px;
		border-radius: 50%;
		margin-right: 5px;

		&--online {
			background: var(--green);
		}

		&--offline {
			background: #9D9D9D;
		}
	}
}