@import '../../../Variables.scss';

.submenu {
	background-color: #f2f2f2;
	position: relative;

	.header--submenu {
		display: flex;
		align-items: center;
		margin: 23px 0;
	}

	.title {
		color: var(--dark-blue);
		font-size: 20px;
	}

	.title__icon {
		width: 50px;
	}

	.icon--arrow {
		justify-content: flex-end;

		svg {
			width: 10px;
			height: 16px;
			stroke: currentColor;
		}
	}

	.icon--menu {
		padding: 12px;

		svg {
			width: 24px;
			height: 24px;
		}
	}

	.icon--signOut {
		width: 24px;
		height: 24px;
		fill: var(--light-grey);
	}

	.active {
		color: var(--blue);
	}

	&--hidden {
		display: none;
	}
}

@media all and (min-width: $size-lg) {
	.submenu {
		min-width: 256px;
		max-height: 100vh;
		top: 0;
		position: sticky;

		.header--submenu {
			padding-left: 16px;
		}

		.title__icon {
			display: none;
		}

		&--hidden {
			display: block;
		}
	}
}
