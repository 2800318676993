@import "../../../Variables.scss";

.Banner {
	display: flex;
	flex-direction: column;
	background: var(--blue);
	color: var(--white);
	position: relative;
	z-index: 1;
	border-radius: 15px;
	padding: 23px 36px 23px 42px;
	margin-bottom: 25px;
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);

	&::after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 1) 100%
		);
		z-index: -1;
		opacity: 0.2;
		border-radius: 15px;
	}

	&__close {
		position: absolute;
		top: 12px;
		right: 20px;
		background: none;
		border: 0;
		margin: 0;
		padding: 8px;
		cursor: pointer;
		color: var(--white);

		&Icon {
			width: 10px;
			height: 10px;
		}
	}

	&__aimee {
		margin: 0;

		&Icon {
			width: 24px;
			height: 24px;
			padding: 2px;

			&Container {
				display: inline-block;
				width: 28px;
				height: 28px;
				background-color: var(--white);
				border-radius: 50%;
				margin: -6px 13px 0 0;
				vertical-align: middle;
			}
		}
	}

	&__title {
		font-size: 22px;
		font-weight: $fontWeight--bold;
		line-height: 1.4;
		color: var(--white);
		margin: 0 0 16px;
		padding: 0;
	}

	&__aimee {
		box-shadow: none !important;
		color: var(--white) !important;
		position: relative;
		z-index: 1;
		background: none !important;
		font-size: 9px !important;
		font-weight: $fontWeight--medium !important;
		text-transform: capitalize !important;
		border-radius: 3px !important;
		width: auto !important;
		align-self: flex-start !important;
		min-width: auto !important;
		padding: 2px 6px !important;
		display: inline-block !important;
		text-align: center !important;
		margin: 0 0 5px !important;

		& .MuiButton-label {
			display: flex;
			padding-top: 1px;
		}

		&::after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: var(--white);
			z-index: -1;
			opacity: 0.3;
			border-radius: 3px;
		}
	}

	&__body {
		display: flex;
		align-items: flex-start;

		&--rating {
			flex-direction: column;
		}
	}

	&__intro,
	&__text {
		font-size: 15px;
		line-height: 1.46;
		font-weight: $fontWeight--regular;
		margin: 5px 20px 0 0;
		flex: 1;

		& strong {
			font-weight: $fontWeight--bold;
		}
	}

	&__getStarted {
		flex: 0 0 130px;
		box-shadow: none !important;
		color: var(--white) !important;
		position: relative;
		z-index: 1;
		background: none !important;
		font-size: 13px !important;
		font-weight: $fontWeight--bold !important;
		text-transform: capitalize !important;
		border-radius: 10px !important;
		padding: 9px 2px !important;
		text-align: center;

		&::after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #000;
			z-index: -1;
			opacity: 0.25;
			border-radius: 10px;
		}
	}

	&__ratings {
		margin: 10px -10px 0 0;
	}

	&__rating {
		border: 1px solid var(--white);
		color: var(--white);
		background: transparent;
		width: 40px;
		height: 40px;
		border-radius: 10px;
		margin: 0 10px 10px 0;
		padding: 0;
		cursor: pointer;
		transition: 200ms all ease-in-out;

		&:hover,
		&--selected {
			background: var(--white);
			color: #000;
		}
	}

	&__comments {
		margin: 10px 0 15px;
		padding: 10px;
		width: 100%;
		border: 0;
		border-radius: 10px;
		background-color: var(--white);
		min-height: 120px;
		box-sizing: border-box;
	}

	&__submit {
		font-size: 15px;
		font-weight: $fontWeight--bold;
		color: var(--white);
		border: 0;
		border-radius: 8px;
		background: #38383850;
		padding: 10px 24px;
		cursor: pointer;
	}
}

@media (max-width: 500px) {
	.Banner {
		margin-bottom: 20px;
		padding: 24px 32px;

		&__close {
			top: 16px;
			right: 24px;
		}

		&__title {
			margin-top: 30px;
			font-size: 24px;
			margin-bottom: 20px;
		}

		&__aimeeIcon {
			width: 19px;
			height: 19px;
			display: block;

			&Container {
				width: 23px;
				height: 23px;
				position: absolute;
				top: 26px;
				left: 32px;
			}
		}

		&__aimee {
			display: none !important;
		}

		&__body {
			flex-direction: column;
		}

		&__intro {
			width: 100%;
			flex: 0 0 100%;
			margin: 0 0 20px;
			font-size: 17px;
			line-height: 1.47;
		}

		&__getStarted {
			width: 100%;
			flex: 0 0 100%;
			font-size: 16px !important;
		}
	}
}
