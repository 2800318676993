@import '../../Variables.scss';

.intlProgressCircle {
	display: grid;
	place-items: center;
	margin-top: 20px;

	> * {
		grid-area: 1 / 1;
	}

	&__chart {
		width: 100px;
		aspect-ratio: 1 / 1;
	}

	&__total {
		color: #9d9d9d;
		font-size: 12px;
		font-weight: 500;
		text-align: center;
	}

	&__value {
		color: var(--blue);
		font-size: 27px;
		font-weight: 600;
	}
}

.intlProgress {
	grid-column: span 2;
	background: var(--white);
	box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.16);
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 25px;
	margin-bottom: 25px;
	position: relative;

	&__meta {
		flex: 1;
		margin: 17px 12px 0 12px;
		text-align: center;
	}

	&__title {
		font-size: 25px;
		font-weight: $fontWeight--bold;
		margin:  0 4px 5px;
		padding: 0;
		color: var(--blue);
	}

	&__progressTitle {
		color: var(--dark-blue);
		width: 100%;
		text-align: left;
		font-size: 18px;
		font-weight: $fontWeight--bold;
		margin: 0;
	}

	&__text {
		font-size: 16px;
		font-weight: $fontWeight--regular;
		margin: 0;
		padding: 0;
	}

	&__link {
		color: var(--white);
		background: var(--blue);
		font-size: 14px;
		font-weight: $fontWeight--bold;
		width: 100%;
		height: 39px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 20px;
		margin-top: 25px;
	}

	&__progressBadge {
		width: 55px;
		height: 55px;
		position: absolute;
		top: 25px;
		right: 25px;
	}
}

@media (max-width: 1074px) {
	.intlProgress {
		flex-direction: column;

		&__meta {
			text-align: center;
			width: 100%;
			margin: 0 0 12px;
		}
	}

	.intlProgressCircle {
		margin-bottom: 12px;
	}
}
