@import '../../Variables.scss';

.intlDashboardModules {
	grid-column: span 2;

	&__title {
		color: var(--dark-blue);
		font-size: 18px;
		font-weight: $fontWeight--bold;
	}

	&__module {
		background: var(--white);
		box-shadow: 2px 4px 10px rgba(0,0,0,0.16);
		border-radius: 10px;
		display: flex;
		align-items: center;
		padding: 15px;
		margin-bottom: 8px;
		color: #383838;

		&Image {
			width: 75px;
			height: 75px;
			margin-right: 20px;
			position: relative;
		}

		&Icon {
			width: 68px;
			height: 68px;
		}

		&Letter {
			font-size: 12px;
			font-weight: $fontWeight--bold;
			color: var(--white);
			width: 20px;
			height: 20px;
			display: grid;
			align-items: center;
			justify-content: center;
			border-radius: 20px;
			background: var(--grey);
			position: absolute;
			bottom: 0;
			right: 0;
		}

		&Meta {
			flex: 1;
		}

		&Label {
			font-size: 14px;
			font-weight: $fontWeight--bold;
			margin: 0 0 5px;
			padding: 0;
		}

		&Title {
			font-size: 16px;
			font-weight: $fontWeight--bold;
			margin: 0;
			padding: 0;
		}

		&Progress {
			display: flex;
			align-items: center;
			margin-top: 5px;

			&Bar {
				flex: 1;
				height: 6px;
				border-radius: 3px;
				background: var(--x-light-grey);
				position: relative;

				&Progress {
					height: 6px;
					border-radius: 3px;
					position: absolute;
					top: 0;
					left: 0;
					min-width: 6px;
				}
			}

			&Label {
				width: 45px;
				margin-left: 10px;
				font-size: 16px;
				font-weight: $fontWeight--medium;
				text-align: right;
			}
		}
	}
}