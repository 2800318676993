@import '../../../Variables.scss';

.LibraryRelatedResources {
	display: flex;
	flex-direction: column;
	background-color: #f7f7fa;
	border-radius: 10px;

	&__header {
		margin: 24px 24px 10px;

		h1 {
			color: var(--dark-blue);
			font-size: 14px;
			font-weight: $fontWeight--bold;
		}
	}

	&__body {
		display: flex;
		align-items: flex-start;
		flex: 0 0 50%;
		box-sizing: border-box;
		margin: 0 14px;
		flex-wrap: wrap;
	}

	&__footer {
		margin: 30px 24px;

		&--left {
			margin: 5px 0;
			color: var(--dark-blue);
			font-size: 14px;
		}

		&--right {
			margin: 5px 0;
			color: var(--light-blue);
			font-size: 13px;
		}
	}

	&__rec {
		color: inherit;
		display: flex;
		border-radius: 10px;
		align-items: flex-start;
		margin: 10px;
		flex: 0 0 100%;
		box-sizing: border-box;

		&:nth-child(n + 3) {
			display: none;
		}

		&:hover {
			background-color: #f7f7fa;
		}

		&Image {
			width: 65px;
			height: 57px;
			border-radius: 10px;
			background-size: cover;
			background-position: 50% 50%;
			flex: 0 0 65px;
			margin-right: 17px;
		}

		&Details {
			flex: 1;
			height: 100%;
		}

		&Type {
			font-size: 9px;
			color: #707070;
			border: 1px solid #d3d3d3;
			border-radius: 10px;
			padding: 2px 5px;
			text-transform: capitalize;
			font-weight: $fontWeight--medium;
		}

		&Title {
			margin: 4px 0 0;
			padding: 5px 0 0;
			font-weight: $fontWeight--regular;
			font-size: 13px;
			line-height: 1.38;
		}
	}
}

@media screen and (min-width: $size-sm) {
	.LibraryRelatedResources {
		&__rec {
			flex: 0 0 45%;

			&:nth-child(n + 3) {
				display: flex;
			}
		}
	}
}

@media screen and (min-width: $size-md) {
	.LibraryRelatedResources {
		&__header {
			margin: 30px 30px 10px;
		}

		&__body {
			margin: 0 20px;
		}

		&__footer {
			margin: 30px 30px;

			> a {
				display: flex;
				justify-content: space-between;
			}

			&--left {
				color: var(--dark-blue);
			}

			&--right {
				text-align: right;
				color: var(--light-blue);
			}
		}
	}
}
