@import '../../Variables.scss';

.filter {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1500;

	&__box {
		background-color: var(--white);
		height: 100%;
		display: flex;
		flex-direction: column;
		min-width: 280px;
		width: 100%;
	}

	&__header {
		display: flex;
		justify-content: center;
		align-items: baseline;
		color: #383838;
		border-bottom: 1px solid #efefef;
		padding: 19px 22px;
		flex: 0 1 auto;

		h1 {
			font-size: 20px;
			font-weight: 600;
			margin: 0;
		}
	}

	&__close {
		position: absolute;
		left: 22px;
		top: 22px;
		background-color: inherit;
		border: none;
		outline: none;
		cursor: pointer;

		&Icon {
			height: 14px;
			width: 14px;
			color: #000000;
		}
	}

	&__content {
		padding: 0 22px;
		flex: 1 1 auto;
		overflow-y: auto;
	}

	&__filterGroup {
		margin-top: 30px;
		padding-bottom: 30px;
		color: #383838;
		font-size: 15px;

		&:not(:last-child) {
			border-bottom: 1px solid #efefef;
		}

		h2 {
			font-size: 15px;
			font-weight: 600;
			margin-bottom: 22px;
		}

		.customField {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&__bottomControls {
		border-top: 1px solid #efefef;
		padding: 14px 22px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex: 0 1 125px;
		box-sizing: border-box;
	}

	&__done {
		width: 124px;
	}

	.MuiButton-root {
		text-transform: none;
		font-size: 13px;
	}

	.customField {
		.MuiTypography-body1 {
			font-size: 15px;
		}

		& .MuiFormControlLabel-root {
			margin-left: -5px;
			align-items: center;
		}
	}
}

@media screen and (min-width: $size-desktop-min) {
	.filter {
		&__overlay {
			width: 100%;
			height: 100%;
			background-color: #383838d4;
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;
			position: fixed;
		}

		&__box {
			display: block;
			width: 600px;
			height: 500px;
			position: relative;
			box-shadow: 0px 3px 6px #00000029;
			border-radius: 15px;
		}

		&__content {
			overflow-y: auto;
			max-height: 370px;
			padding: 0 45px;
		}

		&__filterGroup {
			margin-top: 20px;
		}

		&__bottomControls {
			padding: 14px 40px;
		}

		&__done {
			width: 167px;
		}
	}
}
