@import '../../../Variables.scss';

.LibraryContent {
	display: flex;
	flex-direction: column;
	align-items: center;

	&__content {
		width: 100%;
		max-width: 100%;
		overflow: hidden;
	}

	&__header {
		margin: 0 15px;
		font-size: 24px;
		font-weight: $fontWeight--bold;
		display: flex;
		align-items: center;
		height: 88px;

		> a {
			display: flex;
			align-items: center;
			color: var(--dark-blue);
		}

		&Icon {
			width: 30px;
			height: 30px;
		}

		&Title {
			color: var(--dark-blue);
		}
	}

	&__item {
		color: var(--dark-grey);
		background-color: var(--white);
		font-size: 16px;
		font-weight: $fontWeight--regular;
		position: relative;

		&Image {
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			padding-top: 56.25%;
			height: 0;
			overflow: hidden;
		}

		&Video {
			position: relative;
			padding-bottom: 56.25%;

			iframe,
			object,
			embed {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 0;
			}
		}

		&Flag {
			position: absolute;
			top: 30px;
			left: 0;
			font-size: 14px;
			color: var(--white);
			background: var(--blue);
			font-weight: $fontWeight--bold;
			padding: 6px 16px;

			&--ofColor {
				background: var(--darker-blue);
			}
		}

		&Body {
			padding: 30px 22px 40px;
			box-sizing: border-box;
			position: relative;
		}

		&Title {
			margin: 0 0 10px;
			padding: 0 102px 0 0;
			font-size: 22px;
			line-height: 1.27;
			font-weight: $fontWeight--bold;
			color: var(--dark-blue);
		}

		&Meta {
			font-size: 14px;
			font-weight: $fontWeight--regular;
			line-height: 1.92;
			color: #707070;
			margin: 0 0 28px;
			padding: 0;
		}

		&CopyLinkContainer {
			display: inline-block;
			position: absolute;
			top: 30px;
			right: 22px;
			z-index: 5;
		}

		&Content {
			font-size: 15px;
			font-weight: $fontWeight--regular;
			line-height: 1.57;

			h1,
			h2,
			h3,
			h4 {
				font-size: 19px;
				font-weight: $fontWeight--medium;
			}

			h3,
			h4 {
				font-size: 16px;
				font-weight: $fontWeight--bold;
			}

			a {
				color: var(--blue);
			}

			img {
				max-width: 100%;
				height: auto !important;
			}
			table {
				max-width: 100%;
				width: 100% !important;
				border-collapse: collapse;
				font-size: clamp(12px, 2vw, 15px);
			}
			table td,
			table th {
				padding: 4px;
				border: 1px solid;
				box-sizing: border-box;
			}
		}

		&Podcast {
			audio {
				width: 100%;
			}
		}
	}

	&__copyLink {
		border: 0;
		border-radius: 4px;
		padding: 6px 12px;
		background-color: var(--x-light-grey);
		color: var(--blue);
		font-size: 14px;
		cursor: pointer;

		&Icon {
			width: 14px;
			height: 14px;
			vertical-align: middle;
			margin-right: 4px;
		}
	}

	&__Author {
		font-size: 15px;
		font-weight: $fontWeight--bold;
		margin-top: 70px;

		&Container {
			width: 300px;
			height: 85px;
			display: flex;
			border: 1px solid var(--x-light-grey);
			border-radius: 10px;
			margin-top: 10px;
		}

		&Img {
			width: 56px;
			height: 56px;
			border-radius: 50px;
			margin: 14px 17px;
		}

		> a {
			color: var(--dark-grey);
			font-size: 15px;
			font-weight: $fontWeight--regular;
		}

		&Info {
			display: flex;
			flex-direction: column;
			margin: 14px auto 14px 0;
			color: var(--dark-grey);
			font-size: 15px;
			font-weight: $fontWeight--regular;

			&--name {
				font-size: 15px;
				font-weight: $fontWeight--bold;
				color: var(--blue);
			}
		}
	}

	&__RelatedResourcesContainer {
		margin-top: 47px;
	}

	&__RelatedResourcesContainer:empty {
		margin: 0;
	}
}

@media screen and (max-width: $size-tablet-min) {
	.LibraryContent {
		&__item {
			&Content {
				table {
					max-width: 100%;
					width: auto !important;
				}
			}
		}
	}
}

@media screen and (min-width: $size-desktop-min) {
	.LibraryContent {
		&__content {
			max-width: 800px;
			margin: 0 20px 120px;
		}

		&__header {
			margin: 0 -7px;
		}

		&__item {
			border-radius: 15px;
			box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
			overflow: hidden;

			&Flag {
				top: 40px;
				font-size: 19px;
				padding: 9px 25px;
			}

			&Body {
				padding: 34px 53px 47px;
			}
		}
	}
}
