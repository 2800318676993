@import "../../Variables.scss";

.coachDetails {
	display: flex;
	flex-direction: row;

	&__workspace {
		width: 100%;
		min-height: 100%;
	}

	&__titleBar {
		height: 88px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin: 0 13px;

		h1 {
			margin: 0;
			font-size: 25px;
			font-weight: 600;
			color: var(--dark-blue);
		}

		a {
			display: flex;
			align-items: center;
			color: var(--dark-blue);
		}

		&__chevronRight {
			height: 30px;
			width: 30px;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
	}

	&__info {
		background-color: var(--white);
		box-shadow: 0px 4px 8px #00000034;
		text-align: center;
		padding-bottom: 25px;
		font-size: 15px;
		line-height: 20px;
		font-weight: 400;
		color: #464657;

		&Image {
			min-width: 130px;
			min-height: 130px;
			width: 35%;
			height: 35%;
			max-width: 200px;
			max-height: 200px;
			border-radius: 50%;
			margin-bottom: 25px;
			margin-top: 50px;
		}

		h2 {
			color: var(--dark-blue);
			font-size: 17px;
			font-weight: $fontWeight--bold;
			margin: 3px 0;
		}

		&Links {
			margin-top: 24px;
		}

		a + a {
			margin-left: 20px;
		}
	}

	&__video {
		margin-top: 20px;
		box-shadow: 0px 4px 8px #00000034;
		overflow: hidden;
	}

	&__details {
		background-color: var(--white);
		position: relative;
		box-shadow: 0px 4px 8px #00000034;
		margin-top: 20px;
		padding: 30px 22px 30px;
		font-size: 14px;
		line-height: 20px;

		h2 {
			color: var(--dark-blue);
			font-size: 18px;
			margin: 0 0 20px;
		}

		h3 {
			font-size: 14px;
			margin: 0 0 2px;
		}

		&Text {
			margin: 5px 0 20px;

			h4 {
				margin: 0;
			}

			p {
				margin: 5px 0 20px;
			}

			ul {
				list-style: none;
				padding: 0;
				margin: 5px 0 20px;
			}

			&--bio {
				h3 {
					margin-top: 20px;
				}
			}
		}
	}
}

@media screen and (min-width: $size-desktop-min) {
	.coachDetails {
		min-width: 900px;
		flex-direction: row;

		&__workspace {
			flex: 1;
			margin: 0 45px;
			position: relative;
			align-items: unset;
			padding: 0 16px;
		}

		&__titleBar {
			margin: 0 -2px;
		}

		&__content {
			flex-direction: row;
			align-items: flex-start;
			padding-bottom: 36px;
		}

		&__leftColumn {
			width: 35%;
			max-width: 350px;
			margin-right: 25px;
			overflow: auto;
			padding: 0 5px 8px;
		}

		&__info {
			max-width: 350px;
			overflow: auto;
			border-radius: 15px;

			&Image {
				width: 200px;
				height: 200px;
				margin-top: 25px;
			}
		}

		&__video {
			max-width: 350px;
			border-radius: 10px;
		}

		&__details {
			width: 65%;
			margin-top: 0;
			border-radius: 15px;
			padding: 30px 50px 30px;
		}
	}
}
