@import "../../Variables.scss";

.LifeEventContent {
	display: flex;
	flex-direction: column;
	align-items: center;

	&__header {
		margin: 0 15px;
		font-size: 24px;
		font-weight: $fontWeight--bold;
		display: flex;
		align-items: center;
		height: 88px;

		> a {
			display: flex;
			align-items: center;
			color: var(--dark-blue);
		}

		&Icon {
			width: 30px;
			height: 30px;
		}

		&Title {
			color: var(--dark-blue);
		}
	}

	&__event {
		color: var(--dark-grey);
		background-color: var(--white);
		font-size: 16px;
		font-weight: $fontWeight--regular;

		&Image {
			background-size: cover;
			background-position: center right;
			background-repeat: no-repeat;
			padding-top: 41.25%;
			height: 0;
			overflow: hidden;
		}

		&Body {
			padding: 30px 22px 40px;
			box-sizing: border-box;
			position: relative;
		}

		&Title {
			margin: 0 0 17px;
			padding: 0 112px 0 0;
			font-size: 22px;
			line-height: 1.27;
			font-weight: $fontWeight--bold;
			color: var(--dark-blue);
		}

		&Message {
			margin-bottom: 26px;
			line-height: 1.56;
		}

		&CopyLinkContainer {
			display: inline-block;
			position: absolute;
			top: 28px;
			right: 22px;
			z-index: 5;
		}
	}

	&__copyLink {
		border: 0;
		border-radius: 4px;
		padding: 6px 12px;
		background-color: var(--x-light-grey);
		color: var(--blue);
		font-size: 14px;
		cursor: pointer;

		&Icon {
			width: 14px;
			height: 14px;
			vertical-align: middle;
			margin-right: 4px;
		}
	}

	&__steps {
		border: 0;
		box-shadow: none !important;
		border: 1px solid #e6e6e6;
		border-radius: 10px !important;
		margin-bottom: 15px;

		&::before {
			display: none;
		}

		&.MuiAccordion-root {
			&.Mui-expanded {
				background-color: #f7f7fa;
				border-color: #f7f7fa;
			}
		}

		& .MuiSvgIcon-root {
			color: #9d9d9d;
		}

		.MuiAccordionSummary {
			&-root {
				padding: 0 24px;
			}

			&-content {
				margin: 20px 0;
			}
		}

		.MuiAccordionDetails-root {
			padding: 0 24px 25px;
		}

		&Title {
			font-size: 16px;
			font-weight: $fontWeight--medium;
			line-height: 1.38;
		}

		&Details {
			display: block !important;
			font-size: 14px;
			font-weight: $fontWeight--regular;
			line-height: 1.57;
		}

		&Subtitle {
			font-size: 14px;
			font-weight: $fontWeight--bold;
			margin: 26px 0 6px;
			padding: 0;
		}

		& &LearnMore {
			ul {
				list-style: none;
				margin-block-start: 0;
				margin: 0;
			}
		}

		ul {
			margin: 0 0 0 12px;
			padding: 0;

			li {
				margin: 0 0 6px;
				padding: 0;
			}
		}

		a {
			color: var(--blue);
		}
	}

	&__mybenefits {
		background-color: #007cab0d;
		border-radius: 10px;
		font-size: 14px;
		padding: 25px 24px;

		&--header {
			font-size: 15px;
			font-weight: $fontWeight--bold;
			color: var(--dark-blue);
			padding-bottom: 0;
		}

		&--content {
			margin-top: 14px;

			> h1 {
				font-size: 14px;
				margin: 6px 0;
			}

			> div {
				margin: 6px 0 20px;
			}
		}
	}
}

@media screen and (min-width: $size-sm) {
	.LifeEventContent {
		&__content {
			max-width: 800px;
			margin: 0 20px 75px;
		}

		&__header {
			margin: 0 -7px;
		}

		&__event {
			border-radius: 15px;
			box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
			overflow: hidden;

			&Body {
				padding: 28px 50px 47px;
			}
		}
	}
}
