@import '../../../Variables.scss';

.knowledgeBase {
	padding: 40px 50px;
	max-width: 1400px;

	.LoadingSpinner {
		min-height: 30vh;
	}

	h1 {
		margin: 0 0 24px;
		padding: 0;
		color: var(--dark-blue);
		font-size: 25px;
		font-weight: $fontWeight--bold;
	}

	h2 {
		margin: 75px 0 11px;
		padding: 0;
		font-weight: $fontWeight--bold;
		font-size: 18px;
	}

	h3 {
		margin: 0;
		padding: 0;
		color: var(--blue);
		font-weight: $fontWeight--regular;
		font-size: 22px;
		line-height: 27px;

		&.knowledgeBase__articlesSectionTitle {
			font-weight: $fontWeight--bold;
			color: var(--dark-blue);
		}

		span {
			color: var(--dark-grey);
		}
	}

	h4 {
		font-size: 18px;
		font-weight: $fontWeight--medium;
		line-height: normal;
		color: var(--blue);
		margin: 0;
		padding: 0;
	}


	p {
		font-size: 15px;
		line-height: 1.46;
		font-weight: $fontWeight--regular;
	}

	em {
		font-weight: $fontWeight--medium;
	}

&__content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

		&__answerGroup {
			padding: 0.75rem 0;
			p {
				font-size: 16px;
				line-height: 26px;
				margin-bottom: 10px;
		}
		}

	&__container {	
		display: flex;
		flex-direction: column;
		background-color: #FFF;
		border-radius: 1.25rem;
		box-shadow: 0 0 42px 0 rgba(0, 0, 0, 0.1);
		padding: 2.25rem 2.4375rem;
		gap: 2rem;

		@media (max-width: $size-sm) {
			padding: 1.25rem 1.25rem;
		}
	}
		
	.MuiIconButton-root {
		padding: 1.3125rem auto 0.9375rem auto;
	}
	.MuiAccordionSummary-root {
		padding: 0.25rem 1.25rem 0.25rem 1.5rem;
		margin: 0;
		border-radius: 10px;
		border: 1px solid var(--Grey-Grey-4, #DBE1EE);
		background: var(--grey0);

		@media (max-width: $size-sm) {
			padding: 0.625rem 1.25rem 0.625rem 1.25rem;
		}
	}
	.MuiAccordionSummary-content {
		padding: 0;
		margin: 0;
		color: var(--dark-grey);

		font-size: 16px;
		font-style: normal;
		font-weight: $fontWeight--medium;
		line-height: 20px; /* 125% */
	}

	&__questionAnswer {
		box-shadow: none;
		border-radius: 0;
		border: 0 ;
		margin: 0;

	}
	&__header {
		display: flex;
		justify-content: space-between;

		&Search {
			flex: 1;
			max-width: 500px;
		}
	}

  &__feedback {
    display: flex;
    flex-direction: row-reverse;

    aside {
      display: flex;
      flex-direction: row;
    }

		fieldset {
			padding-top: 20px;
			padding-bottom: 20px;
		}

    .knowledgeBase__feedbackForm {
      fieldset.knowledgeBase__feedbackFormFieldset {
        display: flex;
        flex-direction: row;
				align-items: center;
        gap: 0.4rem;
        height: 0.9375rem;
				border: none;


        legend {
          float: left;
          width: 100%;
          padding: 0;
          margin: 0;
          color: var(--dark-grey);
          font-size: 0.875rem;
          font-style: normal;
          font-weight: $fontWeight--regular;
          line-height: 1.25rem;
        }
      }

      .knowledgeBase__feedbackFormButtons {
        display: flex;
        flex-direction: row;
        gap: 0;

      .knowledgeBase__feedbackFormButton {
          position: relative;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: transparent;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: transform 0.3s ease-in-out 0.1s,
          background-color 0.3s ease-in-out 0.1s;
          border: none;
          padding: 0;
          margin: 0;
          cursor: pointer;
          font: inherit;

          &:focus {
            outline: none;
          }

          svg {
            transition: transform 0.3s ease-in-out 0.1s, fill 0.3s ease-in-out 0.1s;
            width: 15px;
            height: 15px;
            display: block;
						fill: var(--grey3);
          }

          &--selected {
            background-color: var(--grey3);

            svg {
              fill: var(--grey1);
            }
          }

          &:hover {
            background-color: var(--grey3);
            transform: scale(1.167);

            svg {
              fill: var(--grey1);
              transform: rotate(-45deg);
            }

            .knowledgeBase__feedbackFormButtonTooltip {
              transform: translateX(-50%) scale(1);
              opacity: 1;
            }
          }

          .knowledgeBase__feedbackFormButtonTooltip {
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%) scale(0);
            padding: 6px 10px;
            background-color: var(--white);
            border-radius: 6px;
            white-space: nowrap;
            margin-top: 4px;
            opacity: 0;
						box-shadow: 0px 14px 42px 0px rgba(0, 0, 0, 0.14);
            border: 1px solid var(--gray2);
            transition: transform 0.2s ease-out, opacity 0.2s ease-out;
            fill: var(--gray2);
            stroke-width: 1px;
            stroke: var(--gray2);
            pointer-events: none;

            color: var(--dark-grey);
            text-align: center;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: $fontWeight--bold;
            line-height: normal;

						@media (max-width: 1090px) and (min-width: 1025px) {
							left: -18px;
						}

						@media (max-width: 1024px) and (min-width: 799px) {
							left: 50%;
						}

						@media (max-width: 798px) {
							left: 0px;
						}
          }
        }
      }
    }
  }


	&__back {
		cursor: pointer;
		display: flex;
		align-items: center;
		color: var(--dark-blue);

		&Icon {
			width: 30px;
			height: 30px;
		}
	}

	&__aimee {
		display: flex;

		&Icon {
			margin-right: 11px;
			margin-top: 13px;

			img {
				background: var(--white);
				border-radius: 50%;
				width: 28px;
				height: 28px;
				padding: 2px;
			}
		}
	}

	&__intro {
		margin-bottom: 30px;

		p {
			margin: 0 0 10px;
		}
	}

	&__answer {
		margin-top: 0;
		padding: 20px;
		background: var(--white);
		border-radius: 10px;

		p {
			margin: 0;
		}
	}

	&__card {
		padding: 0;
		margin: 0;
		display: flex;
		flex-direction: row;
		gap: 2.25rem;


		@media (max-width: $size-mobile-max) {
			flex-direction: column;
		}

    &:not(:last-child) {
			  padding-bottom: 1.5rem;
        border-bottom: 1px solid var(--x-light-grey);
    }

    &Image {
				aspect-ratio: 1;
				object-fit: cover;
				height: 5rem;
				width: 5rem;
        border-radius: 20px;
    }

    &Body {
        flex: 1;
				display: flex;
				flex-direction: column;
				gap: 0.625rem;

        h3 {
            font-size: 16px;
            font-weight: $fontWeight--bold;
            margin: 0;
            padding: 0;
            line-height: 1.4;
        }

        p {
            margin: 8px 0 10px;
        }

        a {
            color: var(--blue);
        }

        button {
            margin-right: 0;
        }
    }

    &Summary {
        margin: 0;
        font-size: 16px;
        line-height: 26px;
        font-weight: $fontWeight--regular;
				color: #000;
    }

    &Type {
        font-size: 10px;
        border: 1px solid var(--grey3);
        border-radius: 50px;
        padding: 1px 11px;
        margin-right: 12px;
        color: var(--grey3);
				text-align: center;
				font-weight: $fontWeight--medium;
				line-height: 10px;
				letter-spacing: 1px;
				text-transform: uppercase;
    }


    &TimeToRead {
        font-size: 10px;
        color: var(--grey3);
				font-weight: $fontWeight--medium;
				line-height: 10px;
				letter-spacing: 1px;
				text-transform: uppercase;
				
    }
}
	&__alsoSearchFor {
		margin: 0;
		display: flex;
		flex-direction: column;
		gap: 2rem;
		h3 {
			color: var(--dark-blue);
			font-size: 22px;
			font-style: normal;
			font-weight: $fontWeight--bold;
			line-height: 27px; /* 122.727% */
		}
	}

	&__questionContainer {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}

	&__questionAnswer {
		box-shadow: none !important;
		border-radius: 10px !important;
		border: 0 !important;
		margin: 0 0 8px;

		&::before {
			display: none;
		}

		.MuiAccordionDetails-root {
			display: block;
			padding: 8px 0;
		}

		&.MuiAccordion-root.Mui-expanded {
			margin: 0 0 8px;
		}

		&Title {
			min-height: auto !important;
		}

		.MuiAccordionSummary-content {
			font-size: 16px;
			font-weight: $fontWeight--medium;
			color: var(--dark-grey);
			font-style: normal;
			line-height: 20px; /* 125% */
		}

		.MuiAccordionSummary-content.Mui-expanded {
			margin: 10px 0 !important;
		}

		.MuiIconButton-label {
			svg {
				color: var(--gray3);
				fill: var(--gray3);
				stroke: var(--gray3);
				height: 24px;
				width: 24px;
			}
		}

		&Details {
			font-size: 15px;
			line-height: 1.46;

			table {
				border-collapse: collapse;
				border: 1px solid var(--blue);
				background: var(--white);
				margin: 16px 0;
				max-width: 100%;
			}

			th, td {
				padding: 4px 6px;
			}

			th {
				background: var(--blue);
				color: var(--white);
			}

			td {
				border: 1px solid #ddd;
			}

			a {
				color: var(--blue);
			}
		}

		&SearchFor {
			margin-top: 14px;
			margin-bottom: 0;

			a {
				color: var(--blue);
			}
		}
	}

	&__initialSearch {
		display: flex;
		flex-direction: column;
		align-items: center;

		h3 {
			font-size: 15px;
			line-height: 1.4;
			margin: 0 0 8px;
			font-weight: $fontWeight--regular;
			text-align: center;
		}
	}

	&__search {
		max-width: 620px;
		width: 100%;
	}

	&__featuredItems {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		gap: 25px;
	}

	&__featuredItem {
		background-size: cover;
		background-position: center center;
		border-radius: 10px;
		display: flex;
		align-items: flex-end;
		padding: 10vw 20px 18px 20px;
		font-size: 15px;
		font-weight: $fontWeight--bold;
		color: var(--white) !important;
		line-height: 1.4;
		position: relative;
		z-index: 0;

		&::before {
			display: block;
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 10px;
			background-color: var(--darker-blue);
			opacity: 0.6;
			z-index: -1;
		}
	}

	&__additional {
		&Header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 26px 0 11px;

			h2 {
				margin: 0;
			}

			a {
				font-size: 14px;
				font-weight: $fontWeight--medium;
				color: var(--blue);
			}
		}
	}

	&__items {
		display: flex;
		flex-wrap: wrap;
		gap: 25px 30px;
	}

	&__item {
		background: var(--white);
		border-radius: 10px;
		overflow: hidden;
		box-sizing: border-box;
		box-shadow: 0 3px 10px #70707033;
		color: inherit;
		position: relative;
		flex: 1 1 290px;

		&ImageContainer {
			display: block;
			height: 131px;
			overflow: hidden;
			position: relative;
		}

		&Image {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}

		&Flag {
			position: absolute;
			bottom: 0;
			left: 0;
			color: var(--white);
			font-size: 13px;
			font-weight: $fontWeight--bold;
			background: var(--blue);
			padding: 5px 16px;
		}

		&Title {
			font-size: 15px;
			font-weight: $fontWeight--bold;
			line-height: 1.29;
			margin: 14px 24px 7px;
		}

		&Summary {
			font-size: 14px;
			font-weight: $fontWeight--regular;
			line-height: 1.31;
			margin: 0 24px 37px;
			height: 58px;
			overflow: hidden;
		}

		&Type {
			font-size: 9px;
			font-weight: $fontWeight--medium;
			color: #707070;
			display: inline-block;
			width: 70px;
			padding: 2px 0;
			text-align: center;
			border: 1px solid #d3d3d3;
			border-radius: 10px;
			text-transform: capitalize;
			position: absolute;
			left: 24px;
			bottom: 13px;
		}

		&Metadata {
			font-size: 9px;
			font-weight: $fontWeight--medium;
			color: #9d9d9d;
			position: absolute;
			right: 24px;
			bottom: 15px;
		}
	}

	&__help {
		margin: 0 auto;
		max-width: 760px;

		&Options {
			display: flex;

			&Title {
				color: var(--darker-blue);
				margin-bottom: 5px !important;
				text-align: center;
			}

			&Description {
				margin-top: 0;
				text-align: center;
			}
		}

		&Option {
			width: 360px;
			margin-right: 20px;
			background: var(--white);
			border-radius: 10px;
			display: flex;
			align-items: center;
			padding: 18px 25px;
			box-sizing: border-box;

			&IconContainer {
				width: 55px;
				margin-right: 24px;
			}

			&Icon {
				width: 55px;
				max-height: 55px;
				height: auto;
			}

			&Body {
				flex: 1;

				a {
					color: var(--blue);
				}
			}

			&Title {
				font-size: 15px;
				font-weight: $fontWeight--bold;
				margin-bottom: 7px;
			}

			&Description {
				font-size: 14px;
				line-height: 1.46;
				margin-bottom: 2px;
			}

			&CTA {
				font-size: 15px;
				font-weight: $fontWeight--bold;
			}
		}
	}

	.textClamp button {
		font-size: 14px;
		text-transform: capitalize;
		margin-right: 0;
	}

	&__footer {
		margin-top: 30px;
		display: flex;
		justify-content: center;
	}

	&__viewAll {
		background: var(--blue);
		color: var(--white);
		display: inline-block;
		font-size: 14px;
		font-weight: $fontWeight--medium;
		border-radius: 6px;
		padding: 7px 32px;
	}

	&Results {

		&__answerGroup {
			display: flex;
			flex-direction: column;
			gap: 0.625rem;
		}

		&__answerText {
			font-size: 16px;
  line-height: 26px;
  margin: 0;
  color: var(--dark-grey);
}

		&__answer {
			background: var(--white);
			border-radius: 0;
			padding: 0;
			margin: 0;
			display: flex;
			flex-direction: column;
			gap: 1.5rem;

			&Title {
				font-size: 22px;
				font-weight: $fontWeight--regular;
				padding: 0;
				margin: 0;
				color: var(--blue);
				line-height: 1.3;
			}

			&Query {
				color: #000;
			}

			&Group {
				display: flex;
		    flex-direction: column;
		    gap: 10px;
			}

			&Answer {
				font-size: 16px;
				font-weight: $fontWeight--regular;
				line-height: 26px;
				color: #000;
				border-bottom: 1px solid #EBEEF4;

				table {
					border-collapse: collapse;
					border: 1px solid var(--blue);
					background: var(--white);
					margin: 16px 0;
					max-width: 100%;
				}

				th, td {
					padding: 4px 6px;
				}

				th {
					background: var(--blue);
					color: var(--white);
				}

				td {
					border: 1px solid #ddd;
				}

				a {
					color: var(--blue);
				}
			}
		}
	}
	
	&BenefitsAnswer {
		border-radius: 10px;
		border-width: 2px;
		border-style: solid;
		border-color: #007CAB;
		background-color: #eaf0f6;
		position: relative;


		&__body {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding: 2rem;
			gap: 2rem;

		@media (max-width: $size-sm) {
				flex-direction: column;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}

		&__logoContainer {
			width: 100%;
			height: 100%;
			max-width: 30%;
			display: flex;
			align-items: center;
			justify-content: center;

		@media (max-width: $size-sm) {
				max-width: 100%;
			}
		}

		&__tag {
			font-size: 16px;
			font-style: normal;
			font-weight: $fontWeight--bold;
			line-height: 22px; /* 137.5% */
			letter-spacing: 1.28px;
			text-transform: uppercase;
			height: 40px;
			background: var(--blue);
			color: var(--white);
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			padding: 0 2rem;
			border-radius: 8px 8px 0 0;
		}

		&__content {
			padding: 1.25rem 2rem 2rem 2rem;
		}

		&__linkWrapper {
			margin-top: 10px;
		}

		p {
			
			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

@media (max-width: 768px) {
	.knowledgeBase {
		padding: 20px 10px;

		&__header {
			flex-direction: column;

			&Search {
				max-width: 100%;
			}
		}

		&__examples {
			max-width: 100%;
		}

		&__featuredItems {
			grid-template-columns: 1fr 1fr;
			gap: 15px;
		}

		&__featuredItem {
			padding-top: 20vw;
		}

		&__items {
			gap: 15px;
		}

		&__helpOptions {
			flex-wrap: wrap;
		}

		&__helpOption {
			width: 100%;
			margin-right: 0;
			margin-bottom: 20px;
		}
	}
}

@media (max-width: 600px) {
	.knowledgeBase {

		&__questionAnswer {
	
			&Details {
	
				table {
					table-layout: fixed;
					width: 100%;
				}

				td, td {
					word-wrap: break-word;
					padding: 4px;
					font-size: 14px;
				}
			}
		}

		&Results {

			&__answer {
	
				&Answer {
	
					table {
						table-layout: fixed;
						width: 100%;
					}

					td, td {
						word-wrap: break-word;
						padding: 4px;
						font-size: 14px;
					}
				}
			}
		}

	}
}