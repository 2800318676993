@import '../../Variables.scss';

.HelpCenter {
	max-width: 1189px;
	margin: 40px auto;
	background: var(--white);
	border-radius: 20px;
	box-shadow: $shadow4;
	padding: 50px;
	box-sizing: border-box;

	&__header {
		display: flex;
		gap: 30px;
		align-items: center;

		&Meta {
			flex: 1;
		}

		&Image {
			width: 243px;
			height: 123px;
		}
	}

	&__title {
		margin: 0 0 15px;
		padding: 0;
		color: var(--blue);
		font-size: 26px;
		font-weight: $fontWeight--bold;
	}

	&__description {
		max-width: 496px;
		margin: 0;
		padding: 0;
		font-size: 16px;
		line-height: 1.625;
	}

	&__divider {
		width: 100%;
		height: 1px;
		border: 0;
		background-color: var(--grey2);
		margin: 30px 0;
	}

	&__body {
		display: flex;
		gap: 40px;
	}

	&__sidebar {
		flex: 0 0 300px;
	}

	&__filters {
		margin-bottom: 150px;
		display: flex;
		flex-direction: column;
		gap: 15px;
	}

	&__filter {

		&Container {
			cursor: pointer;
			display: inline-flex;
			font-size: 16px;
			padding: 11px 21px;
			border-radius: 50px;
			background-color: var(--grey0);
			align-items: center;

			&--active {
				background: var(--blue);
				color: var(--white);
			}
		}

		&Cancel {
			width: 15px;
			height: 15px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: var(--white);
			color: var(--blue);
			margin-left: 12px;
			border: 0;

			&Icon {
				width: 7px;
				height: 7px;
				margin: 0;
				padding: 0;
			}
		}
	}

	&__support {
		background-color: var(--grey0);
		border: 1px solid var(--grey2);
		border-radius: 10px;
		padding: 25px 10px 25px 25px;

		&Icon {
			width: 38px;
			height: 38px;
			margin-bottom: 9px;
		}

		&Title {
			margin: 0 0 10px;
			padding: 0;
			font-size: 16px;
			font-weight: $fontWeight--bold;
		}

		&Description {
			margin: 0 0 16px;
			padding: 0;
			font-size: 16px;
			line-height: 1.625;
		}

		&Email {
			background: var(--white);
			border: 1px solid var(--grey2);
			border-radius: 50px;
			color: #383838;
			font-size: 15px;
			font-weight: $fontWeight--medium;
			padding: 12px 18px;
			display: inline-block;
		}
	}

	&__body {
		flex: 1;
	}

	&__section {
		margin-bottom: 59px;

		&Title {
			color: var(--dark-blue);
			margin: 0 0 16px;
			padding: 0;
			font-size: 22px;
			font-weight: $fontWeight--bold;
			line-height: 1.23;
		}

		.MuiPaper-elevation1 {
			box-shadow: none;
		}

		.MuiAccordion-root {
			border: 1px solid var(--grey2);
			border-radius: 10px;
			padding: 18px;
			margin-bottom: 10px;
		}

		.MuiAccordion-root::before {
			display: none;
		}

		.MuiAccordion-root.Mui-expanded {
			margin: 0 0 10px;
		}
	
		.MuiAccordionSummary-root {
			padding: 0;
			min-height: auto;
			font-weight: $fontWeight--medium;
			font-size: 18px;
		}

		.MuiAccordionSummary-root.Mui-expanded {
			min-height: auto;
		}
	
		.MuiAccordionSummary-content {
			margin: 0;

			&.Mui-expanded {
				margin: 0;
			}
		}

		.MuiAccordionSummary-expandIcon {
			padding: 0px 0 0;
		}
	
		.MuiAccordionDetails-root {
			padding: 0;
		}

		.MuiCollapse-root {
			font-size: 16px;
			line-height: 1.625;
		}

		.MuiButtonBase-root {
			margin-right: 0;

			.HelpCenter__sectionAccordionIcon--expanded {
				display: none;
			}

			.HelpCenter__sectionAccordionIcon--contracted {
				display: inline-block;
			}
		}

		.MuiButtonBase-root.Mui-expanded {

			.HelpCenter__sectionAccordionIcon--expanded {
				display: inline-block;
			}
			
			.HelpCenter__sectionAccordionIcon--contracted {
				display: none;
			}
		}

		&Accordion {

			&Icon {
				width: 24px;
				height: 24px;
			}
		
			&Html {
				margin-top: 10px;
				margin-right: 80px;
				
				a {
					color: var(--blue);
				}
			}
		}
	}
}

@media (max-width: 1480px) {
	.HelpCenter {
		max-width: 1024px;
	}
}

@media (max-width: 1310px) {
	.HelpCenter {
		max-width: 768px;

		&__body {
			display: block;
		}

		&__sidebar {
			display: flex;
			flex-direction: column;
		}

		&__filters {
			overflow-x: scroll;
			order: 2;
			margin: 37px -50px 0;
			padding: 0 50px;
			flex-direction: row;
		}

		&__filter {
			
			&Container {
				white-space: nowrap;
			}
		}

		&__support {
			order: 1;
			display: flex;
			align-items: center;
		}

		&__content {
			margin-top: 60px;
		}
	}
}

@media (max-width: 800px) {
	.HelpCenter {
		margin: 12px 9px;
	}
}

@media (max-width: 640px) {
	.HelpCenter {
		padding: 25px;

		&__header {
			flex-direction: column;
		}

		&__filters {
			margin: 37px -25px 0;
			padding: 0 25px;
		}

		&__support {
			display: block;
		}

		&__sectionAccordionHtml {
			margin-right: 35px;
		}
	}
}