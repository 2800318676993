@import '../../Variables.scss';

.LevelPill {
	--width: 71px;
	--height: 24px;

	font-size: 11px;
	font-weight: $fontWeight--medium;
	display: flex;
	justify-content: center;
	align-items: center;
	width: var(--width);
	height: var(--height);
	position: relative;
	border-radius: 15px;
	text-transform: uppercase;
	letter-spacing: 0.06em;
	color: #383838 !important;

	// &::before {
	// 	content: '';
	// 	display: block;
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	width: var(--width);
	// 	height: var(--height);
	// 	border-radius: 5px;
	// 	opacity: 0.2;
	// 	z-index: 0;
	// }

	&--1 {
		background-color: var(--level-1);

		// &::before {
		// 	background: var(--level-1);
		// }
	}

	&--2 {
		background-color: var(--level-2);

		// &::before {
		// 	background: var(--level-2);
		// }
	}

	&--3 {
		background-color: var(--level-3);

		// &::before {
		// 	background: var(--level-3);
		// }
	}

	&--4 {
		background-color: var(--level-4);

		// &::before {
		// 	background: var(--level-4);
		// }
	}

	&--5 {
		background-color: var(--level-5);

		// &::before {
		// 	background: var(--level-5);
		// }
	}

	&--6 {
		background-color: var(--level-6);

		// &::before {
		// 	background: var(--level-6);
		// }
	}

	&--7 {
		background-color: var(--level-7);

		// &::before {
		// 	background: var(--level-7);
		// }
	}

	&--8 {
		background-color: var(--level-8);

		// &::before {
		// 	background: var(--level-8);
		// }
	}

	&--9 {
		background-color: var(--level-9);

		// &::before {
		// 	background: var(--level-9);
		// }
	}

	&--10 {
		background-color: var(--level-10);

		// &::before {
		// 	background: var(--level-10);
		// }
	}
}