.nilCoursesHorizontal {
	overflow: hidden;

	header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__title {
		color: #073853;
		font-size: 16px;
		font-weight: 600;
	}

	&__seeAll,
	&__seeAll:visited {
		color: var(--blue);
		text-decoration: none;
		font-size: 14px;
		font-weight: 500;
	}

	&__list {
		padding-inline: 0;
		padding-block: 0 10px;
		margin-block: 15px 0;
		list-style: none;
		display: flex;
		gap: 15px;
		overflow-x: auto;
		scrollbar-width: thin;

		&::-webkit-scrollbar-track {
			background-color: #f0f0f0;
		}

		&::-webkit-scrollbar {
			height: 6px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #cdcdcd;
		}
	}
}

.nilCoursesGrid {
	&__title {
		color: var(--dark-grey);
		font-size: 14px;
		font-weight: 600;
	}

	&__list {
		padding-inline: 0;
		padding-block: 0 10px;
		margin-block: 15px 0;
		list-style: none;
		display: grid;
		grid-template-columns: repeat(auto-fit, 295px);
		gap: 20px;
	}
}