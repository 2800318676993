// NOTE: DO NOT USE CSS COLORS. 
// These are here as a reference. 
// All colors should be defined in JSX.

// primary
$color-light-blue: #55abdd;     // color1
$color-blue: #077cab;           // color2
$color-dark-blue: #00507c;      // color3
$color-darker-blue: #073853;
// secondary
$color-white: #ffffff;          // color4
$color-x-light-grey: #e6e6e6;   // color5
$color-light-grey: #a5a4a2;     // color6
$color-grey: #686765;           // color7
$color-dark-grey: #383838;      // color8
// tertiary
$color-green: #88c52c;          // color9
$color-orange: #fa8a3b;         // color10
$color-red: #ed0000;            // color11
$color-yellow: #ffde1b;         // color12

$shadow1: 0 2px 6px rgba(0,0,0,0.06);
$shadow2: 0 2px 12px rgba(0,0,0,0.08);
$shadow3: 0 8px 28px rgba(0,0,0,0.10);
$shadow4: 0 14px 42px rgba(0,0,0,0.14);

// font weights
$fontWeight--thin : 100;
$fontWeight--light : 300;
$fontWeight--regular : 400;
$fontWeight--medium : 500;
$fontWeight--bold : 700;
$fontWeight--black : 900;

// screen size
$size-xs: 0px;
$size-sm: 600px;
$size-md: 960px;
$size-lg: 1280px;
$size-xl: 1920px;

$size-mobile-min: 320px;
$size-mobile-max: 480px;
$size-mobile-landscape-min: 481px;
$size-mobile-landscape-max: 767px;
$size-tablet-min: 768px;
$size-tablet-max: 1024px;
$size-desktop-min: 1025px;
$size-desktop-max: 1280px;
$size-desktop-lg-min: 1281px;

$sidebar-desktop-expanded: 226px;
$sidebar-desktop-collapsed: 70px;

$header-desktop-height: 80px;
$header-mobile-height: 80px;