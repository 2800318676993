.nilTask {
	display: grid;
	grid: 1fr min-content / 1fr max-content;
	grid-template-columns: 1fr 160px;
	gap: 6px;
	padding-block: 25px;
	padding-inline: 30px;
	background-color: var(--white);
	box-shadow: 0px 3px 10px #00000029;
	border-radius: 15px;
	overflow: hidden;
	color: var(--dark-grey);
	align-items: center;

	&--completed {
		background-color: #f7f7fa;
		box-shadow: none;
	}

	&__type {
		color: var(--blue);
		font-weight: 600;
	}

	&__title {
		font-weight: 600;
	}

	&__clock {
		width: 20px;
		aspect-ratio: 1;
		fill: #c1c1c1;
	}

	&__time {
		display: flex;
		align-items: center;
		gap: 12px;
		font-size: 14px;
	}

	&__expertVideo {
		background-color: var(--blue);
		background: #077cab;
		color: white;
		margin-left: 5px;
		padding: 2px 5px;
		border-radius: 3px;
	}

	&__action {
		grid-column: 2;
		grid-row: 1 / -1;
		display: inline-flex;
		justify-content: center;
		align-items: center;
        gap: 8px;
		background-color: var(--blue);
		border-radius: 5px;
		color: var(--white);
		font-size: 15px;
		font-weight: 600;
		padding: 0.7rem 2rem;
		height: 19px;
	}

    &__check {
        width: 15px;
        aspect-ratio: 1;
        margin-block-start: -2px;
    }

	&--completed &__action {
		background-color: #9d9d9d;
		font-size: 13px;
	}
}

@media screen and (max-width: 450px) {
	.nilTask {
		display: block;

		&__action {
			margin-top: 10px;
			width: 140px;
		}
	}
}