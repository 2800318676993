@import '../../Variables.scss';

.Sidebar {
	overflow: hidden;

	&__menu {
		background-color: #ebeef4;
		min-height: calc(100% - #{$header-desktop-height});
		display: flex;
		flex-direction: column;
	}

	&__header {
		display: flex;
		padding: 0 18px;
		align-items: center;
		justify-content: center;
		height: 88px;
	}

	&__menuButton {
		background-color: inherit;
		border: none;
		cursor: pointer;
		position: absolute;
		left: 18.5px;
		top: 27.5px;
		padding: 0;
		width: 33px;
		height: 33px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__menuIcon {
		width: 22px;
		height: 22px;
	}

	&__closeButton {
		background-color: inherit;
		border: none;
		cursor: pointer;
		position: absolute;
		right: 18.5px;
		top: 27.5px;
		padding: 0;
		width: 33px;
		height: 33px;
		display: flex;
		justify-content: center;
		align-items: center;
		display: none;
	}

	&__closeIcon {
		width: 14px;
		height: 14px;
	}

	&__logo {
		max-height: 30px;
		max-width: 150px;

		&Container {
			flex: 1;
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}
	}

	&__item[name='Advisor'] {
		.Sidebar__itemIcon {
			color: #ff9100 !important;
		}
	}

	&__item--active[name='Advisor'] {
		.Sidebar__itemIcon {
			color: #ffffff !important;
		}
	}

	&__item {
		font-size: 14px;
		font-weight: $fontWeight--regular;
		border-right: 4px solid transparent !important;
		padding: 14px 20px 14px 16px !important;
		color: #928b9f !important;
		overflow-x: hidden;

		&Icon {
			width: 18px;
			height: 18px;
			fill: var(--light-grey);
			margin: 0 3px;
			overflow: visible;

			&Container {
				width: 28px;
				height: 28px;
				border-radius: 5px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		span {
			white-space: nowrap;
		}

		&:hover {
			background: #EBEEF4 !important;
		}

		&--active {
			font-weight: $fontWeight--bold;
			border-right-color: var(--blue) !important;
			color: var(--blue) !important;
			background: #EBEEF4 !important;

			.Sidebar__itemIcon {
				fill: var(--white);
				color: var(--white);

				&Container {
					background: var(--blue);
				}
			}

			.Sidebar__itemLabel {
				color: var(--dark-grey);
			}
		}
	}

	&__score {
		font-size: 11px;
		font-weight: $fontWeight--regular;
		color: #707070;
		max-width: 112px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__aimee {
		margin: 0 32px 32px 32px;
		padding: 40px 17px 23px;
		background: var(--blue);
		border-radius: 20px;
		position: relative;
		text-align: center;
		color: var(--white);

		&Title {
			font-size: 16px;
			font-weight: $fontWeight--bold;
		}

		&Score {
			font-size: 34px;
			font-weight: $fontWeight--bold;
			margin-bottom: 20px;
		}

		&Button {
			width: 100%;
			background-color: var(--white) !important;
			color: var(--darker-blue) !important;
			text-transform: none !important;
			border-radius: 30px !important;
			font-weight: $fontWeight--bold !important;
			padding: 7px 14px !important;
			font-size: 16px !important;
			position: static !important;
		}

		.MuiButton-label span {
			text-align: center;
			width: 100%;
		}

		&Icon {
			width: 25px;
			height: 25px;
			padding: 3px;
			overflow: visible;

			&Container {
				width: 37px;
				height: 37px;
				background-color: var(--white);
				border-radius: 50%;
				margin-right: 16px;
				box-sizing: border-box;
				border: 2px solid var(--blue);
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: -18px;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}

	&__footer {
		width: 100%;
	}

	&__secondaryLogo {
		width: 100%;
		height: auto;

		&Container {
			text-align: center;
			margin: 10px auto 35px;
			max-height: 130px;
			max-width: 130px;
			display: block;
		}
	}

	&__sections {
		flex: 1 1;
		overflow-y: auto;
		overflow-x: hidden;
		scrollbar-width: thin;
		margin-top: 26px;

		.MuiList-padding {
			padding: 0;
		}

		&::-webkit-scrollbar-track {
			background-color: #f0f0f0;
		}

		&::-webkit-scrollbar {
			width: 6px;
			background-color: #f5f5f5;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #cdcdcd;
		}
	}
}

@media screen and (max-width: $size-tablet-max) {
	.Sidebar {
		&__overlay {
			position: fixed;
			left: 0;
			top: 0;
			height: 100%;
			z-index: 100;
			overflow-y: scroll;
		}

		&__menu {
			width: 322px;
			transition: margin-left 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		}

		&__menuButton {
			top: 10px;
			left: 19px;
		}

		&__item {
			font-size: 16px;
			padding: 14px 16px 14px 30px !important;

			&Icon {

				&Container {
					margin-right: 12px;
				}
			}
		}

		&__profile {
			&Avatar {
				margin-right: 17px;
			}

			&Name {
				font-size: 15px;
			}
		}

		&__score {
			font-size: 13px;
		}
	}

	.Sidebar.Sidebar--mobile-collapsed {
		.Sidebar {
			&__overlay {
				width: 0;
				background-color: #38383800;
				transition: background-color 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
					width 0ms cubic-bezier(0.4, 0, 0.2, 1) 240ms;
			}

			&__menu {
				margin-left: -322px;
			}

			&__header {
				position: fixed;
				background-color: #fff;
				left: 0;
				top: 80px;
				width: 100%;
				height: 53px;
				z-index: 150;
				box-shadow: 0 1px 4px #0000001a;
				padding: 0px;

				.Sidebar__logoContainer {
					justify-content: center;
				}
			}

			&__aimee {
				margin: 73px 20px 20px;

				&Icon {
					opacity: 1;
					width: 29px;
		
					&Container {
						opacity: 1;
						width: 35px;
					}
				}
			}
		}
	}

	.Sidebar.Sidebar--mobile-expanded {
		.Sidebar {
			&__overlay {
				width: 100%;
				background-color: #383838d4;
				transition: background-color 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			}

			&__menu {
				margin-left: 0;
				margin-top: $header-desktop-height;
			}

			&__header {
				height: 53px;
				position: relative;

				.Sidebar__logoContainer {
					justify-content: center;
				}
			}

			&__closeButton {
				display: block;
				top: 10px;
				right: 19px;
			}
		}
	}
}

@media screen and (min-width: $size-desktop-min) {
	.Sidebar {
		&__menu {
			transition: width 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			position: fixed;
			top: $header-desktop-height;
			left: 0;
			bottom: 0;
		}

		&__logo {
			transition: opacity 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
				visibility 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

			&Container {
				margin-left: 35px;
			}
		}

		&__item {
			overflow: hidden;
			&Label {
				overflow: hidden;
				white-space: nowrap;
				transition: opacity 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
					width 45ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			}
		}

		&__aimee {
			margin: 24px 13px;
			transition: margin 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

			&Button {
				padding: 7px !important;
			}

			&Icon {
				&Container {
					transition: margin 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
				}
			}

			.MuiButton-label {
				overflow: hidden;
				justify-content: flex-start;
				white-space: nowrap;
				transition: padding 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

				span {
					overflow: hidden;
					transition: opacity 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
						visibility 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
						width 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
				}
			}
		}

		&__secondaryLogo {
			margin: 0 auto 0;
			transition: opacity 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
				visibility 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		}
	}

	.Sidebar.Sidebar--desktop-collapsed {
		width: 70px;
		flex: 0 0 70px;

		.Sidebar {
			&__menu {
				width: 70px;
			}

			&__logo {
				opacity: 0;
				visibility: collapse;
			}

			&__item {
				&Label {
					opacity: 1;
					width: 0;
				}

				&Icon {
					overflow: visible;
				}
			}

			&__aimee {
				margin: 20px 13px;
				padding: 0;
				border-radius: 50%;
				border: 3px solid var(--blue);

				&Title {
					display: none;
				}

				&Score {
					display: none;
				}

				&Button {
					min-width: 0;
					margin-top: 0;
					padding: 0 !important;
					width: auto !important;
				}

				&Icon {
					opacity: 1;
					width: 29px;
					height: 29px;
					transition: opacity 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
					width 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

					&Container {
						margin-right: 2px;
						margin-left: 2px;
						opacity: 1;
						width: 35px;
						height: 35px;
						transition: opacity 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
						width 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
						position: static;
						top: auto;
						left: auto;
						transform: none;
						border: 0;
					}
				}

				.MuiButton-label {
					span {
						opacity: 0;
						visibility: collapse;
						width: 0;
					}
				}
			}

			&__secondaryLogo {
				opacity: 0;
				visibility: collapse;
			}
		}
	}

	.Sidebar.Sidebar--desktop-expanded {
		width: 226px;
		flex: 0 0 226px;

		.Sidebar {
			&__menu {
				width: 226px;
			}

			&__item {
				&Label {
					width: 149px;
					margin-left: 12px;
				}
			}

			&__aimee {

				.MuiButton-label {
					padding: 0 7px;
				}

				&Icon {
					transition: opacity 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
					width 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

					&Container {
						transition: opacity 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
						width 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
					}
				}
			}
		}
	}
}
