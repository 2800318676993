@import "../../../Variables.scss";

.actionStep {
	font-size: 14px;
	line-height: 1.5;
	padding: 34px 22px 42px;
	position: relative;

	&__iconStatus {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 26px;
		height: 26px;
		border-radius: 50%;
		border: 1px solid #e6e6e6;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;

		&Icon {
			color: #e6e6e6;
			width: 18px;
			height: 18px;
		}

		&--complete {
			border-color: var(--blue);
			background: var(--blue);
		}

		&--complete &Icon {
			color: var(--white);
		}
	}

	&__completion {
		&Container {
			display: flex;
			font-size: 9px;
			margin-bottom: 15px;
			text-transform: uppercase;
		}

		&Status {
			font-weight: $fontWeight--bold;
			color: #9d9d9d;

			&--incomplete {
				color: var(--blue);
			}
		}

		&Divider {
			display: inline-block;
			padding: 0 8px;
		}

		&Date {
			font-weight: $fontWeight--regular;
			color: #a5a5a5;
		}
	}

	&__title {
		font-size: 21px;
		font-weight: $fontWeight--bold;
		line-height: 1.4;
		color: var(--dark-blue);
		margin: 0 0 19px;
		padding: 0;
	}

	&__associated {
		font-size: 15px;

		&Level,
		&Milestone {
			margin-bottom: 20px;
		}

		&Title {
			padding: 0;
			margin: 0 0 5px;
			font-size: 16px;
			font-weight: $fontWeight--bold;

			&Icon {
				width: 14px;
				height: 14px;
				margin: 0 8px -1px 0;
			}
		}

		&Level {
			&Icon {
				width: 9px;
				height: 9px;
				border-radius: 50%;
				display: inline-block;
				margin: 0 10px 0 0;
			}
		}
	}

	&__description {
		margin-bottom: 29px;
		font-size: 15px;

		&Title {
			padding: 0;
			margin: 0 0 4px;
			font-weight: $fontWeight--bold;
			font-size: 16px;
		}

		&Content {
			line-height: 1.57;

			p {
				margin: 0;
			}

			a {
				color: var(--blue);
			}
		}
	}

	&__actions {
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
	}

	&__button {
		text-transform: none !important;
		font-size: 15px !important;
		box-shadow: none !important;
		margin: 5px 0 !important;
		color: var(--blue) !important;
		font-weight: $fontWeight--bold !important;
		position: relative;
		overflow: hidden;
		min-width: 145px !important;
		border-radius: 8px !important;

		&__getStarted {
			background: var(--blue) !important;
			color: var(--white) !important;

			&:disabled {
				background: #f2f2f2 !important;
				color: #707070 !important;
			}
		}

		&__markAsComplete {
			position: relative;
			background: transparent !important;

			&::before {
				content: "";
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				background-color: var(--blue);
				opacity: 0.1;
			}

			.MuiButton-label {
				z-index: 1;
			}
		}
	}

	&__markAsIncomplete {
		color: var(--blue);
		cursor: pointer;
		padding-inline-start: 4px;
		font-weight: $fontWeight--medium;
	}

	&__banner {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 24px;
		background: transparent linear-gradient(269deg, #55abdd 0%, #077cab 100%) 0%
			0% no-repeat padding-box;
		color: var(--white);
		border-radius: 10px;
		margin: 30px 0;

		&Icon {
		}

		&__aimeeIcon {
			width: 26px;
			height: 26px;

			&Container {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				background-color: #ffffff;
			}
		}

		&Body {
			margin: 22px 0 0;
			font-size: 17px;
			font-weight: $fontWeight--regular;

			a {
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #00000040;
				color: var(--white);
				border-radius: 10px;
				height: 45px;
				width: 100%;
				margin-top: 22px;
				font-size: 16px;
				font-weight: $fontWeight--bold;
			}
		}
	}
}

@media screen and (min-width: $size-tablet-min) {
	.actionStep {
		&__actions {
			flex-direction: row;
		}

		&__button {
			& + & {
				margin-left: 16px !important;
			}
		}

		&__associated {
			display: flex;
			flex-wrap: nowrap;
			margin-bottom: 5px;

			&Title {
				font-size: 15px;
			}

			&Level,
			&Milestone {
				flex: 0 0 50%;
				font-size: 14px;
			}
		}

		&__description {
			&Title {
				font-size: 15px;
			}

			&Content {
				font-size: 14px;
			}
		}

		&__banner {
			flex-direction: row;
			padding: 27px 25px;

			&Icon {
				width: 28px;
				height: 28px;
			}

			&__aimeeIcon {
				&Container {
					width: 32px;
					height: 32px;
				}
			}

			&Body {
				margin: 0 0 0 20px;
				a {
					width: 120px;
					background-color: #00000066;
					font-size: 13px;
				}
			}
		}
	}
}

@media screen and (min-width: $size-desktop-min) {
	.actionStep {
		padding: 50px;

		&__iconStatus {
			top: 24px;
			right: 24px;
		}
	}
}
