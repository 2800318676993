@import '../../Variables.scss';

.Consent {
	position: fixed;
	bottom: 20px;
	left: 50%;
	transform: translateX( -50% );
	z-index: 10000;
	background: var(--white);
	border-radius: 20px;
	box-shadow: 0 14px 42px rgba(0,0,0,0.14); 
	padding: 36px;
	width: 80%;
	max-width: 700px;
	box-sizing: border-box;
	
	a {
		color: var(--blue);
	}

	&__title {
		font-size: 22px;
		font-weight: $fontWeight--bold;
		line-height: 1.23;
		color: var(--dark-blue);
		margin: 0 0 25px;
		padding: 0;
	}

	&__description {
		font-size: 14px;
		line-height: 1.4;
	}

	&__tool {
		border: 1px solid var(--x-light-grey);
		border-width: 1px 0;
		padding: 10px 0;

		.MuiFormControlLabel-root {
			margin: 0;
		}

		&Name {
			font-size: 14px;
			font-weight: $fontWeight--medium;
			line-height: 1.4;

			.MuiTypography-root {
				font-size: 14px;
				font-weight: $fontWeight--medium;
			}

			.MuiCheckbox-root {
				padding: 9px 9px 9px 0;
			}

			&Control {
				color: var(--dark-blue);
				display: inline-block;

				&--alwaysActive {
					padding: 9px 9px 9px 0;
				}
			}
		}

		&Description {
			font-size: 14px;
			margin: 0 0 10px;
			line-height: 1.33;
		}

		&:last-child {
			border-width: 0 0 1px 0;
		}
	}

	&__actions {
		display: flex;
		margin-top: 25px;
		gap: 20px;

		.MuiButton-contained {
			border-radius: 50px;
			color: var(--white);
			background-color: var(--blue);

			&:hover {
				background-color: var(--dark-blue);
			}
		}
	}

	&__save,
	&__customize {

		&.MuiButtonBase-root {
			color: var(--blue);

			&:hover {
				background: none;
			}
		}
	}

	&__show {
		background: var(--white);
		width: 56px;
		height: 56px;
		position: fixed;
		bottom: 113px;
		right: 36px;
		border-radius: 50%;
		cursor: pointer;
		border: 0;
		margin: 0;
		padding: 0;
		box-shadow: 0 3px 6px #0000007a;
		z-index: 1000;

		&Icon {
			width: 36px;
			height: 36px;
			fill: var(--blue);
		}
	}
}

@media (max-width: 500px) {
	.Consent {
		width: 95%;
	}
}