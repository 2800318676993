@import "../../Variables.scss";

.LifeEventsIndex {
	padding-bottom: 70px;

	&__header {
		display: flex;
		flex-direction: column;
	}

	&__search {
		margin: -12px 10px 20px 10px;
		flex: 1;
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;
	}

	&__enhancedSearch {
		flex: 0 0 100%;
		margin-bottom: 10px;
	}

	&__titleBar {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 0 22px;
		max-width: 331px;
		height: 88px;

		h1 {
			margin: 0;
			font-size: 25px;
			font-weight: $fontWeight--bold;
			color: #073853;
		}
	}

	&__events {
		display: flex;
		flex-wrap: wrap;
		margin-top: -10px;
	}

	&__event {
		border-radius: 15px;
		overflow: hidden;
		background: var(--white);
		box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
		flex: 1 1 290px;
		margin: 10px;

		&Image {
			width: 100%;
			height: 0px;
			padding-top: 51.73%;
			background-size: cover;
			background-position: center;
			display: block;
		}

		&Title {
			font-size: 16px;
			font-weight: $fontWeight--bold;
			line-height: 1.22;
			padding: 17px 15px;
			color: #383838;
			min-height: 32px;
		}
	}
}

@media screen and (min-width: 310px) {
	.LifeEventsIndex {
		&__event {
			flex: 1 1 43%;
		}
	}
}

@media screen and (min-width: 600px) {
	.LifeEventsIndex {
		&__header {
			flex-direction: row;
			justify-content: space-between;
		}
	
		&__search {
			margin-top: 24px;
			max-width: 400px;
		}

		&__event {
			flex: 1 1 30%;
			max-width: 30%;
		}
	}
}

@media screen and (min-width: 1025px) {
	.LifeEventsIndex {
		padding: 0 34px 70px;
		&__titleBar {
			margin: 0 10px;
		}
	}
}

@media screen and (min-width: 1280px) {
	.LifeEventsIndex {
		padding: 0 34px 70px;

		&__events {
			max-width: 1500px;
		}

		&__event {
			flex: 1 1 22.5%;
			max-width: 22.5%;
		}
	}
}
