@import '../../../Variables.scss';

.ScoreExplainer {

	.MuiPaper-root {
		width: 341px;
		border: 1px solid var(--grey2);
		border-radius: 10px;
		padding: 22px 48px 22px 25px;
		box-sizing: border-box;
		box-shadow: $shadow4;
	}

	&__close {
		position: absolute;
		top: 9px;
		right: 9px;
		padding: 5px;
		border: 0;
		margin: 0;
		cursor: pointer;
		background: none;

		&Icon {
			color: var(--grey3);
			width: 9px;
			height: 9px;
			margin: 0;
			padding: 0;
		}
	}

	&__title {
		color: var(--darker-blue);
		font-size: 16px;
		font-weight: $fontWeight--bold;
		margin: 0 0 12px;
		padding: 0;
	}

	&__pill {
		$_pill: &;
		display: inline-flex;
		align-items: center;
		position: relative;
		font-size: 16px;
		font-weight: $fontWeight--bold;
		z-index: 1;
		padding: 3px 12px;

		&::before {
			content: '';
			display: block;
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 50px;
			opacity: 0.10;
		}

		&Indicator {
			display: block;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			margin-right: 11px;
		}

		&--crisis {

			&::before {
				background-color: #F03911;
			}

			#{ $_pill }Indicator {
				background-color: #F03911;
			}
		}

		&--struggling {

			&::before {
				background-color: #F5A051;
			}

			#{ $_pill }Indicator {
				background-color: #F5A051;
			}
		}

		&--planning {

			&::before {
				background-color: #55ABDD;
			}

			#{ $_pill }Indicator {
				background-color: #55ABDD;
			}
		}

		&--optimizing {

			&::before {
				background-color: #8BC35F;
			}

			#{ $_pill }Indicator {
				background-color: #8BC35F;
			}
		}
	}

	&__description {
		font-size: 16px;
		line-height: 1.25;
		margin: 9px 0 0;
		padding: 0;
	}
}