@import "../../Variables.scss";

.MyFinancials__additionalPayments {
	margin: 30px 0;
	padding: 24px;
	background-color: #f5f5f5;
	border-radius: 15px;

	table {
		table-layout: fixed;
		margin-inline-start: -2px;
		border-spacing: 2px 4px;
	}

	th {
		font-size: 14px;
		font-weight: 500;
		text-align: left;
		padding-left: 8px;
	}

	td:nth-child(1) {
		padding-inline-start: 0px;
		width: 30%;
	}

	td:nth-child(3) {
		max-width: 24px;
	}

	td fieldset.MuiOutlinedInput-notchedOutline > legend {
		display: block;
	}

	&__label {
		display: flex;
		align-items: center;
		gap: 5px;
		margin-bottom: 6px;
		font-size: 16px;
		font-weight: 600;
	}

	&__input {
		.MuiInputBase-root {
			background-color: var(--white);
		}

		.MuiOutlinedInput-input {
			padding: 13px 15px;
			border-radius: 8px;
		}

		.MuiOutlinedInput-notchedOutline {
			border-color: #cecece;
			border-radius: 8px;
		}

		.MuiInputBase-root.Mui-disabled {
			color: currentColor;
		}

		.MuiInputBase-input.Mui-disabled {
			background-color: #f5f5f5;
		}

		.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
			border: none;
		}
		
		.MuiInputAdornment-positionEnd {
			color: #cecece;
		}
		
		.MuiInputBase-input::placeholder {
			color: #383838;
			opacity: .8;
		}
	}

	&__delete {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 24px;
		padding: 0;
		border: none;
		background: transparent;
		color: #707070;
		cursor: pointer;
	}

	&__addPayment.MuiButton-root {
		margin-top: 15px;
		padding-inline-start: 2px;

		.MuiButton-label {
			line-height: 1;
			text-transform: none;
			font-weight: 500;
			color: var(--blue);
		}
	}
}
