@import '../../Variables.scss';

.dialog {

	&__paper {
		border-radius: 15px !important;
		padding: 50px !important;
		max-width: 490px !important;
		box-sizing: border-box;
		position: relative;
		text-align: center;
	}

	&__close {
		position: absolute;
		top: 43px;
		right: 18px;
		cursor: pointer;

		&Icon {
			width: 14px;
			height: 14px;
		}
	}

	&__image {
		height: 120px;
		width: 120px;
		margin: 0 auto 25px;
	}

	&__subtitle {
		font-size: 18px;
		font-weight: $fontWeight--medium;
		margin: 0 0 3px;
		padding: 0;
	}

	&__title {
		font-size: 32px;
		font-weight: $fontWeight--bold;
		color: var(--blue);
		margin: 0;
		padding: 0 0 19px;
	}

	&__body {
		font-size: 15px;
		line-height: 1.4;
		font-weight: $fontWeight--regular;
		margin: 0;
		padding: 0;
	}

	&__actions {
		margin-top: 36px;
	}

	&__action {
		width: 100%;
		border: 0;
		box-sizing: border-box;
		padding: 9px;
		background: var(--white);
		color: var(--blue);
		font-size: 15px;
		font-weight: $fontWeight--bold;
		border-radius: 20px;
		cursor: pointer;
		margin-bottom: 6px;

		&--primary {
			background: var(--blue);
			color: var(--white);
		}
	}
}

@media (max-width: 768px) {
	.dialog {

		&__paper {
			padding: 40px !important;
		}

		&__image {
			width: 98px;
			height: 98px;
			margin-bottom: 20px;
		}

		&__actions {
			margin-top: 30px;
		}
	}
}