@import "../../Variables.scss";

.benefitCard {
	border-radius: 10px;
	border: 2px solid #6aaad8;
	background-color: #eaf0f6;
	margin-top: 34px;
	padding: 25px 29px 30px;

	&__title {
		color: var(--dark-blue) !important;
		font-size: 15px !important;
		font-weight: $fontWeight--bold !important;
		margin: 0 0 12px !important;
		padding: 0 !important;
	}

	&__logo {
		margin-bottom: 10px !important;
		max-width: 160px !important;
		max-height: 36px !important;
	}

	&__shortDescription {
		margin: 0 0 16px !important;
		font-size: 14px !important;
		font-weight: $fontWeight--regular !important;
		line-height: 1.57 !important;
	}

	&__callToAction {
		display: inline-block !important;
		font-size: 13px !important;
		font-weight: $fontWeight--medium !important;
		color: var(--blue) !important;
	}
}

@media (max-width: 500px) {
	.benefitCard {
		&__logo {
			max-width: 100% !important;
			max-height: auto !important;
		}
	}
}
