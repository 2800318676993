@import "../../Variables.scss";

.upcomingSessions {
	min-height: 100%;
	height: 100%;

	&__backToList {
		display: flex;
		align-items: center;
		height: 32px;
		width: 32px;
		color: #073853;
		margin: -2px 10px -2px -10px;
	}

	&__filterIcon {
		height: 16px;
		width: 16px;
		stroke: #383838;

		&--active {
			stroke: var(--blue);
		}
	}

	&__workspace {
		display: flex;
		flex-direction: column;
		min-height: calc(100% - #{$header-desktop-height});
		box-sizing: border-box;
	}

	&__content {
		display: flex;
		flex-direction: column;
		min-height: calc(100% - 168px);
	}

	&__sessionList {
		background-color: var(--white);
		height: 100%;
	}

	&__details {
		display: flex;
		flex-direction: column;

		&Container {
			position: relative;
			flex: 1;
			background-color: var(--white);
		}
	}

	&__titleBar {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin: 0 26px;
		height: 88px;

		&__title {
			display: flex;
			align-items: center;
		}

		button {
			background-color: inherit;
			border: none;
			cursor: pointer;
			outline: none;
			display: flex;
			align-items: center;
		}

		h1 {
			margin: 0;
			font-size: 25px;
			font-weight: 600;
			color: #073853;
		}
	}
}

@media screen and (max-width: $size-tablet-max) {
	.upcomingSessions--hide-tablet {
		display: none;
	}
}

@media screen and (min-width: $size-desktop-min) {
	.upcomingSessions {
		flex-direction: row;

		&__backToList {
			display: none;
		}

		&__workspace {
			flex: 1;
			margin: 0 1%;
			transition: margin 200ms linear;
			position: relative;
			align-items: unset;
			padding: 0 16px 16px;
		}

		&__leftColumn {
			width: 40%;
			max-width: 343px;
			margin-right: 25px;
		}

		&__content {
			flex-direction: row;
			align-items: flex-start;
			padding-bottom: 20px;
			flex: 1;
		}

		&__sessionList {
			border-radius: 15px;
			box-shadow: 2px 4px 10px #00000029;
			height: calc(100vh - 204px);
		}

		&__details {
			width: 65%;
			align-self: stretch;
			min-height: 100%;

			&Container {
				border-radius: 15px;
				box-shadow: 0px 3px 10px #00000029;
			}
		}

		&__titleBar {
			width: 40%;
			max-width: 343px;
			margin: 0;
			top: 0;
		}
	}
}

@media screen and (min-width: $size-desktop-lg-min) {
	.upcomingSessions {
		&__workspace {
			margin: 0 45px;
			transition: margin 200ms linear;
		}
	}
}
