@import '../../Variables.scss';

:root {
	--lifeEventsGap: 16px;
}

.intlDashboard {
	display: grid;
	grid: repeat(4, min-content) / repeat(3, 1fr);
	gap: 30px;
	align-items: flex-start;
	max-width: 1200px;
	margin-block-start: 32px;
	margin-inline: 33px;
	padding-bottom: 70px;


	&__sidebar {
		grid-column: 3;
		grid-row: 1 / -1;
	}

	&__certificationTitle {
		grid-column: span 2;
		margin: 0;
		padding: 0;
		color: var(--blue);
		font-size: 18px;
		font-weight: $fontWeight--bold;
		margin-bottom: -20px;
	}

	&__lifeEvents {
		grid-column: span 2;

		&Header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 19px;
		}

		&Title {
			margin: 0;
			padding: 0;
			font-weight: $fontWeight--bold;
			font-size: 18px;
			color: var(--darker-blue);
		}

		&SeeAll {
			font-size: 13px;
			font-weight: $fontWeight--medium;
			color: var(--blue);
		}

		&Body {
			display: flex;
			justify-content: space-between;
			gap: var(--lifeEventsGap);

			&Item {
				border-radius: 15px;
				box-shadow: 0 3px 10px rgba(0,0,0,0.16);
				flex: 1 1 25%;
				background: var(--white);
			}

			&Image {
				display: block;
				height: 125px;
				width: 100%;
				background-size: cover;
				border-radius: 15px 15px 0 0;
			}

			&Title {
				padding: 18px;
				color: #000;
				font-size: 16px;
				font-weight: $fontWeight--bold;
			}
		}
	}

	&__postAssessment {
		display: flex;

		.intlBanner {

			&__body {
				flex-direction: column;
			}

			&__actions {
				display: flex;
				justify-content: space-between;
				gap: 10px;
				width: 100%;
			}

			&__action {
				flex: 1;
				color: var(--white);
				background: var(--dark-blue);
				border-radius: 20px;
				font-size: 13px;
				font-weight: $fontWeight--bold;
				padding: 10px;
				box-sizing: border-box;
				text-align: center;
			}

			&__image {
				margin: -23px -30px -23px 10px;
				position: relative;
				flex: 0 0 261px;
				display: flex;
				align-items: flex-end;

				&Primary {
					width: 261px;
					height: 191px;
					margin: 0;
					padding: 0;
					display: block;
				}

				&Badge {
					width: 74px;
					height: 75px;
					position: absolute;
					bottom: 102px;
					left: 0;
				}
			}
		}
	}
}

.featuredContent {
	background: var(--white);
	border-radius: 15px;
	box-shadow: 2px 4px 10px rgba(0,0,0,0.16);
	padding: 25px;
	grid-column: span 2;

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 19px;
	}

	&__title {
		margin: 0;
		padding: 0;
		font-weight: $fontWeight--bold;
		font-size: 18px;
		color: var(--darker-blue);
	}

	&__body {
		display: flex;
		flex-wrap: wrap;
		column-gap: 18px;
		row-gap: 20px;
		justify-content: space-between;
	}

	&__seeAll {
		font-size: 13px;
		font-weight: $fontWeight--medium;
		color: var(--blue);
	}

	&__rec {
		flex: 0 0 calc(50% - 18px);
		min-height: 57px;
		display: flex;

		&Image {
			display: block;
			flex: 0 0 65px;
			height: 57px;
			background-size: cover;
			border-radius: 10px;
			margin-right: 17px;
		}

		&Details {
			flex: 1;
		}

		&Type {
			font-size: 9px;
			font-weight: $fontWeight--medium;
			color: var(--white);
			background: var(--blue);
			padding: 2px 9px;
			border-radius: 20px;
		}
		
		&Title {
			font-size: 14px;
			font-weight: $fontWeight--regular;
			margin: 6px 0 0;
			padding: 0;
			color: #000;
		}
	}
}

@media (max-width: 1238px) {
	.intlDashboard {
		
		&__postAssessment {
	
			.intlBanner {
	
				&__actions {
					flex-wrap: wrap;
				}
	
				&__action {
					flex: 0 0 100%;
				}
			}
		}
	}
}

@media (max-width: 1130px) {
	.intlDashboard {
		&__quiz,
		&__badge {
			grid-column: span 2;
		}

		&__lifeEvents {
	
			&Body {
				flex-wrap: wrap;
	
				&Item {
					flex: 1 1 calc(50% - var(--lifeEventsGap));
				}
			}
		}
	}

	.featuredContent {
		&__rec {
			flex: 1 0 100%;
			min-height: 57px;
			display: flex;
		}
	}
}

@media (max-width: 1098px) {
	.intlDashboard {
		
		&__postAssessment {
	
			.intlBanner {
	
				&__image {
					flex: 0 0 200px;
	
					&Primary {
						width: 200px;
						height: auto;
					}
	
					&Badge {
						bottom: 64px;
					}
				}
			}
		}
	}
}

@media (max-width: 1024px) {
	.intlDashboard {
		
		&__postAssessment {
	
			.intlBanner {
	
				&__image {
					flex: 0 0 261px;
	
					&Primary {
						width: 261px;
						height: auto;
					}
	
					&Badge {
						bottom: 102px;
					}
				}
			}
		}

		&__lifeEvents {
	
			&Body {
				flex-wrap: wrap;
	
				&Item {
					flex: 1 1 calc(25% - var(--lifeEventsGap));
				}
			}
		}
	}
}

@media (max-width: 890px) {
	.intlDashboard {

		&__lifeEvents {
	
			&Body {
				flex-wrap: wrap;
	
				&Item {
					flex: 1 1 calc(50% - var(--lifeEventsGap));
				}
			}
		}
	}
}

@media (max-width: 834px) {
	.intlDashboard {
		
		&__postAssessment {
	
			.intlBanner {
	
				&__image {
					flex: 0 0 200px;
	
					&Primary {
						width: 200px;
						height: auto;
					}
	
					&Badge {
						bottom: 64px;
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.intlDashboard {
		grid: repeat(4, min-content) / repeat(2, 1fr);

		&__sidebar {
			grid-column: span 2;
			grid-row: auto;
		}

		&__postAssessment {
	
			.intlBanner {
	
				&__image {
					flex: 0 0 261px;
	
					&Primary {
						width: 261px;
						height: auto;
					}
	
					&Badge {
						bottom: 102px;
					}
				}
			}
		}

		&__lifeEvents {
	
			&Body {
				flex-wrap: wrap;
	
				&Item {
					flex: 1 1 calc(25% - var(--lifeEventsGap));
				}
			}
		}
	}
}

@media (max-width: 550px) {
	.intlDashboard {
		margin-inline: 10px;

		&__postAssessment {
			flex-direction: column;
	
			.intlBanner {
	
				&__image {
					flex: 1;
					margin: 0 -30px -23px;
	
					&Primary {
						width: 100%;
						height: auto;
					}
	
					&Badge {
						bottom: auto;
						top: 20px;
						left: 2%;
					}
				}
			}
		}

		&__lifeEvents {
	
			&Body {
				flex-wrap: wrap;
	
				&Item {
					flex: 1 1 calc(50% - var(--lifeEventsGap));
				}
			}
		}
	}
}