.sessionPreparations {
	padding: 25px;
	background-color: #007cab0c;
	border-radius: 10px;
	margin-bottom: 26px;
	margin-top: 26px;

	a {
		font-weight: 600;
		color: var(--blue);

		&:visited,
		&:active {
			color: var(--blue);
		}
	}

	&__item {
		margin-bottom: 16px;

		h3 {
			margin-bottom: 14px;
		}
	}

	&__apps {
		display: flex;
	}

	& &__app {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: var(--blue);
		color: var(--white);
		height: 60px;
		width: 60px;
		border-radius: 10px;
		font-size: 9px;
		font-weight: 400;

		&:visited,
		&:active {
			color: var(--white);
		}
	}

	&__app {
		& + & {
			margin-left: 17px;
		}
	}

	&__app__icon {
		width: 18px;
		height: 18px;
		padding: 5px;
	}
}
