.AccountsPieChart {
	display: grid;
	grid: 1fr / 1fr;
	place-items: center;

	&__chart {
		grid-area: 1 / 1;
		max-width: 250px;
	}

	&__total {
		grid-area: 1 / 1;
		max-width: 200px;
		font-size: 15px;
		text-align: center;
	}

	&__amount {
		font-size: 41px;
		font-weight: 600;
		line-height: 54px;

		&--small {
			font-size: 31px;
		}
	}
}

.accountsPieChartTooltip {
	opacity: 1;
	position: absolute;
	border-radius: 3px;
	-webkit-transition: all 0.1s ease;
	transition: all 0.1s ease;
	pointer-events: none;
	transform: translate(-50%, 0);
	padding: 16px;

	&__caret {
		width: 11px;
		aspect-ratio: 1;
		background: var(--white);
		z-index: -1;
		position: absolute;
		opacity: 0;

		.bottom > & {
			opacity: 1;
			bottom: 7px;
			left: 50%;
			transform: translate(-50%, -50%) rotateY(50deg) rotateZ(45deg);
		}

		.top > & {
			opacity: 1;
			top: 7px;
			left: 50%;
			transform: translate(-50%, 50%) rotateY(50deg) rotateZ(45deg);
		}

		.center.right > & {
			opacity: 1;
			right: 7px;
			top: 50%;
			transform: translate(-50%, -50%) rotateX(50deg) rotateZ(45deg);
		}

		.center.left > & {
			opacity: 1;
			left: 7px;
			top: 50%;
			transform: translate(50%, -50%) rotateX(50deg) rotateZ(45deg);
		}
	}

	&.bottom {
		transform: translate(-50%, -100%);
	}

	&.center.right {
		transform: translate(-100%, -50%);
	}

	&.center.left {
		transform: translate(0, -50%);
	}

	&__content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		background: var(--white);
		color: #383838;
		padding: 5px 10px;
		border-radius: 100vh;
		z-index: 1;
		width: auto;
		font-size: 13px;
		font-weight: 400;
		line-height: 1;
	}

	&__color {
		min-width: 8px;
		width: 8px;
		aspect-ratio: 1;
		border-radius: 50%;
		background-color: red;
	}

	&__name {
		font-weight: 500;
	}
}
