@import "../../Variables.scss";

.createPassword {
	display: flex;
	width: 100%;
	min-height: 100vh;

	&__createPassword {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	&__createPassword {
		position: relative;
		padding-top: 134px;

		&Form {
			width: 67%;
		}

		&Logo {
			max-width: 138px;
			max-height: 44px;
			width: auto;
			height: auto;
			position: absolute;
			top: 80px;
			left: 50%;
			transform: translateX(-50%);
		}

		&Title {
			font-size: 25px;
			font-weight: $fontWeight--bold;
			line-height: 1.32;
			text-align: center;
			margin: 0 0 20px;
			color: #073853;
		}

		&Subtitle {
			text-align: center;
			margin: 20px 0;
			color: var(--black);
			font-size: 14px;
			font-weight: $fontWeight--regular;
			line-height: 1.4;

			a {
				color: var(--dark-blue);
			}
		}

		.customField {
			margin-bottom: 22px;
		}

		&Btn.MuiButton-root {
			font-size: 15px;
			font-weight: $fontWeight--bold;
			padding: 11px 16px;
			color: var(--white);
			background-color: var(--blue);
			box-shadow: none;
			border-radius: 10px;
			margin-bottom: 22px;

			&:hover {
				color: var(--white);
				background-color: var(--blue);
			}

			& + & {
				margin-top: 15px;
			}
		}

		&Btn--sso.MuiButton-root {
			background-color: transparent;
			color: var(--blue);
			border: 1px solid var(--blue);
			border-radius: 10px;
			margin-bottom: 0;
		}

		& .MuiOutlinedInput-notchedOutline {
			border-radius: 10px;
		}

	}

	&__showHide {
		margin-left: 10px;
		cursor: pointer;
		height: 56px;
		padding: 18.5px 14px;
		background: var(--white);
		border: 1px solid #bbbbbb;
		border-radius: 10px;
		font-size: 14px;
	}

	&__error {
		font-size: 11px;
		font-weight: $fontWeight--regular;
		line-height: 1.3;
		color: #e53e3e;
		margin: 20px 0;
	}
}

@media (max-width: 768px) {
	.createPassword {
		flex-wrap: wrap;

		&__createPassword {
			padding-top: 22px;
			padding-bottom: 22px;
			height: 100%;

			&Logo {
				position: relative;
				top: auto;
				left: auto;
				transform: none;
				display: block;
				margin: 0 auto 30px;
			}
		}
	}
}

@media (max-width: 500px) {
	.createPassword {
		&__createPassword {
			padding-left: 22px;
			padding-right: 22px;

			&Form {
				width: 100%;
			}
		}
	}
}
