@import '../../Variables.scss';

.Header {
    background: #fff;
    box-shadow: 0px 2px 4px -1px #00000020, 0px 4px 5px 0px #00000014, 0px 1px 10px 0px #00000012;

    &__inner {
        height: 87px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 24px;
    }

    &__logoContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        max-height: 40px;
        height: 40px;
        min-width: 350px;
        overflow: hidden;
    }

    &__logo {
        height: 40px;
        width: 160px;
        color: inherit;
    }

    &__companyLogo,
    &__ffLogo {
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        height: 40px;
        width: 100%;
    }

    &__secondaryLogo {
        margin-left: 15px;
        border-left: 1px solid;
        padding-left: 15px;
    }
}

@media (max-width: 500px) {
    .Header {
    
        &__inner {
            justify-content: center;
        }

        &__logoContainer {
            justify-content: center;
        }

        &__logo {
            width: 150px;
            height: auto;
        }

    }
}