.chartTooltip {
	opacity: 1;
	position: absolute;
	border-radius: 3px;
	-webkit-transition: all 0.1s ease;
	transition: all 0.1s ease;
	pointer-events: none;
	transform: translate(-50%, 0);
	padding: 16px;

	&__caret {
		width: 14px;
		height: 14px;
		background: var(--blue);
		z-index: 0;
		position: absolute;
		opacity: 0;

		.bottom > & {
			opacity: 1;
			bottom: 2px;
			left: 50%;
			transform: translate(-50%, -50%) rotateX(50deg) rotateZ(45deg);
		}

		.top > & {
			opacity: 1;
			top: 2px;
			left: 50%;
			transform: translate(-50%, 50%) rotateX(50deg) rotateZ(45deg);
		}

		.center.right > & {
			opacity: 1;
			right: 2px;
			top: 50%;
			transform: translate(-50%, -50%) rotateX(50deg) rotateZ(45deg);
		}

		.center.left > & {
			opacity: 1;
			left: 2px;
			top: 50%;
			transform: translate(50%, -50%) rotateX(50deg) rotateZ(45deg);
		}
	}

	&.bottom {
		transform: translate(-50%, -100%);
	}

	&.center.right {
		transform: translate(-100%, -50%);
	}

	&.center.left {
		transform: translate(0, -50%);
	}

	&__content {
		background: var(--blue);
		color: var(--white);
		padding: 6px 8px;
		border-radius: 5px;
		z-index: 1;
	}

	&__date {
		font-size: 9px;
	}

	&__values {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		margin-top: 8px;
	}

	&__icon {
		&--gauge {
			color: var(--white);
			width: 13px;
			height: 13px;
		}

		&--arrow {
			width: 6px;
			height: 7px;
		}
	}

	&__value {
		font-size: 16px;
		font-weight: 600;
		display: flex;

		> div + div {
			margin-left: 5px;
		}
	}

	&__diff {
		font-size: 9px;
		display: flex;
		margin-left: 14px;

		> div + div {
			margin-left: 3px;
		}
	}
}
