.SessionMetadata {
	font-size: 14px;

	&__item {
		margin-bottom: 9px;
		display: flex;
		align-items: center;
	}

	&__icon {
		margin-right: 12px;
		width: 12px;
		height: 12px;
		color: var(--blue);
		fill: var(--blue) !important;
	}
}
