@import "../../Variables.scss";

.AccessCode {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100%;
	box-sizing: border-box;
	position: relative;

	&__header {
		position: absolute;
		top: 0;
		left: 0;
		padding: 14px 20px;

		&Logo {
			width: 127px;
			height: auto;
		}
	}

	&__container {
		width: 500px;
		box-sizing: border-box;
		padding: 55px 85px;
		background: var(--white);
		border-radius: 15px;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
		text-align: center;
	}

	&__title {
		font-size: 25px;
		line-height: 1.32;
		font-weight: $fontWeight--bold;
		margin: 0 0 20px;
		padding: 0;
		color: var(--dark-blue);
	}

	&__instructions {
		font-size: 15px;
		line-height: 1.6;
		font-weight: $fontWeight--regular;
		margin: 0 0 40px;
		padding: 0;
	}

	&__input {
		.MuiOutlinedInput-notchedOutline {
			border-radius: 10px;
		}
	}

	&__submit {
		margin-top: 40px;
		margin-bottom: 30px;
		display: block;
		padding: 14px 10px;
		border: 0;
		border-radius: 10px;
		background: var(--blue);
		color: var(--white);
		width: 100%;
		font-weight: $fontWeight--bold;
		font-size: 16px;
	}

	&__notice {
		font-size: 13px;
		line-height: 1.6;
		font-weight: $fontWeight--regular;
		margin: 0;
		padding: 0;
	}
	
	&__status {
		font-size: 14px;
		font-weight: $fontWeight--regular;
		line-height: 1.3;
		color: var(--dark-grey);
		background: #f2f2f2;
		border: 1px solid var(--x-light-grey);
		border-radius: 6px;
		padding: 12px 8px;
		margin-top: 20px;
	}

	&__showCodeInput {
		font-family: inherit;
		font-size: 14px;
		color: var(--blue);
		background: var(--white);
		border: 0;
		margin: 0 0 50px;
		font-weight: $fontWeight--medium;
		cursor: pointer;
	}

	&__codeContainer {
		overflow: hidden;
		max-height: 0;
		transition: max-height 0.3s ease-in-out;

		&--show {
			max-height: 500px;
			transition: max-height 0.3s ease-in-out;
			padding-top: 5px;
		}
	}
}

@media (max-width: 530px) {
	.AccessCode {
		display: block;

		&__header {
			display: block;
			background: var(--white);
			text-align: center;
			position: relative;
			top: auto;
			left: auto;
			box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
		}

		&__container {
			margin: 0;
			padding: 22px;
			border-radius: 0;
			box-shadow: none;
			display: flex;
			flex-direction: column;
			min-height: calc(100vh - 63px);
			width: 100%;
			box-sizing: border-box;
			justify-content: center;
		}
	}
}
