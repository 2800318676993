@import '../../../Variables.scss';

.HomeLifeEvents {
	width: 375px;
	height: 464px;
	padding: 25px;
	box-sizing: border-box;
	border-radius: 20px;
	box-shadow: $shadow4;
	background: var(--white);
	position: relative;

	&__title {
		text-transform: uppercase;
		font-size: 14px;
		color: var(--dark-blue);
		font-weight: $fontWeight--bold;
		letter-spacing: 0.08em;
		margin: 0 0 15px -25px;
		padding: 8px 28px 8px 25px;
		background: var(--grey1);
		border-radius: 0 20px 20px 0;
		display: inline-block;
	}

	&__tagline {
		font-size: 22px;
		color: var(--dark-blue);
		font-weight: $fontWeight--bold;
		line-height: 1.23;
		margin: 0 0 20px;
		padding: 0;
	}

	&__link {
		background: var(--blue);
		color: var(--white);
		display: block;
		text-align: center;
		border-radius: 50px;
		font-size: 18px;
		font-weight: $fontWeight--bold;
		padding: 14px 0;
	}

	&__image {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 306px;
		height: 218px;
	}
}