@import '../../Variables.scss';

.groupRegistration {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	&__message {
		background: var(--white);
		margin: 5%;
		padding: 20px 40px;
		border-radius: 15px;
		box-shadow: 0 3px 10px rgba(0,0,0,0.16);
	}
}