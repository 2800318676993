@import '../../../Variables.scss';

.homeWellnessScore {
	
	&__header {
		display: flex;
		min-height: 24px;

		&Title {
			font-size: 22px;
			line-height: 1.23;
			color: var(--darker-blue);
			font-weight: $fontWeight--bold;
			margin: 0;
			padding: 0 10px 0 0;
		}

		&Icon {
			width: 18px;
			height: 18px;
			color: var(--grey3);

			&Container {
				padding: 6px 2px 2px;
				cursor: pointer;
				margin: 0;
				border: 0;
				background: transparent;
			}
		}

		&Popover {

			.MuiPaper-root {
				width: 323px;
				border: 1px solid var(--grey2);
				box-shadow: $shadow4;
				padding: 20px;
				border-radius: 10px;
				font-weight: $fontWeight--regular;
				font-size: 16px;
				line-height: 1.25;
				box-sizing: border-box;
			}

			&Header {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 13px;

				&Icon {
					width: 17px;
					height: 17px;
					color: var(--grey3);
				}

				&Close {
					width: 10px;
					height: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0;
					margin: -4px 0 0;
					border: 0;
					background: transparent;
					cursor: pointer;

					&Icon {
						width: 8px;
						height: 8px;
						color: var(--grey3);
					}
				}
			}
		}
	}

	&__score {
		margin: 40px 0 44px;
		display: flex;
		justify-content: center;
		position: relative;

		&Gauge {
			width: 249px;
			height: 149px;
			position: relative;

			&Explainer {
				position: absolute;
				border: 0;
				padding: 0;
				margin: 0;
				background-color: transparent;
				cursor: pointer;
				z-index: 200;

				&--crisis {
					top: 69px;
					left: 0;
					width: 24px;
					height: 79px;
				}

				&--struggling {
					top: 4px;
					left: 15px;
					width: 74px;
					height: 65px;
				}

				&--planning {
					top: 55px;
					left: 218px;
					width: 33px;
					height: 95px;
				}

				&--optimizing {
					top: 0;
					left: 89px;
					width: 136px;
					height: 55px;
				}
			}
		}

		&Score {
			position: absolute;
			font-weight: $fontWeight--bold;
			font-size: 45px;
			color: var(--darker-blue);
			top: 71px;
		}

		&Level {
			position: absolute;
			top: 138px;
		}
	}

	&__levelText {
		font-size: 16px;
		text-align: center;
		font-weight: $fontWeight--bold;
		margin-bottom: 9px;
	}

	&__aimee {
		color: var(--blue);
		text-align: center;
		font-size: 14px;
		display: block;
	}
}