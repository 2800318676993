@import "../../Variables.scss";

.ForgotPassword {
	display: flex;
	width: 100%;
	min-height: 100vh;

	&__header {
		display: none;
	}

	&__about,
	&__login {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	&__about {
		background: var(--blue);
		background: linear-gradient(180deg, var(--dark-blue) 0%, var(--blue) 100%);
		color: var(--white);
		position: relative;

		&Logo {
			max-width: 138px;
			max-height: 44px;
			width: auto;
			height: auto;
			position: absolute;
			top: 80px;
			left: 50%;
			transform: translateX(-50%);
		}

		&Meta {
			width: 67%;
			text-align: center;
		}

		&Title {
			font-size: 32px;
			font-weight: $fontWeight--black;
			line-height: 1.34;
		}

		&Intro {
			font-size: 18px;
			font-weight: $fontWeight--regular;
			line-height: 1.78;
		}
	}

	&__login {
		text-align: center;
		position: relative;

		&Form {
			width: 67%;
		}

		&Logo {
			max-width: 138px;
			max-height: 44px;
			width: auto;
			height: auto;
			position: absolute;
			top: 80px;
			left: 50%;
			transform: translateX(-50%);
		}

		&Title {
			font-size: 25px;
			font-weight: $fontWeight--bold;
			line-height: 1.32;
			text-align: center;
			margin: 0 0 30px;

			&--resend {
				color: var(--dark-blue);
			}
		}

		.customField {
			margin-bottom: 22px;
		}

		&Btn.MuiButton-root {
			font-size: 15px;
			font-weight: $fontWeight--bold;
			padding: 11px 16px;
			color: var(--white);
			background-color: var(--dark-blue);
			box-shadow: none;
			margin-bottom: 22px;

			&:hover {
				color: var(--white);
				background-color: var(--dark-blue);
			}
		}

		&BtnBack.MuiButton-root {
			font-size: 15px;
			font-weight: $fontWeight--bold;
			padding: 11px 16px;
			box-shadow: none;
			margin-bottom: 30px;
			text-transform: none;

			&:hover {
				background-color: inherit;
			}
		}

		&BtnResend.MuiButton-root {
			text-transform: none;
			background-color: inherit;
			padding: 0;
			margin: 0;
			font-size: inherit;
			min-width: 0;
			line-height: normal;
			letter-spacing: normal;
			vertical-align: baseline;

			&:hover {
				background-color: inherit;
			}
		}

		&Notice {
			font-size: 15px;
			font-weight: $fontWeight--regular;
			line-height: 1.46;
			margin: 0 0 30px;
			padding: 0;
		}
	}
}

@media (max-width: 768px) {
	.ForgotPassword {
		flex-wrap: wrap;

		&__about {
			display: none;
		}

		&__header {
			display: flex;
			background: var(--white);
			width: 100%;
			flex: 0 0 100%;
			align-items: center;
			justify-content: center;
			height: 53px;
			box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

			&Logo {
				width: 107px;
				height: 29px;
			}
		}

		&__login {
			padding-top: 0;
			height: 100%;

			&Logo {
				position: relative;
				top: auto;
				left: auto;
				transform: none;
				display: block;
				margin: 0 auto 30px;
			}
		}
	}
}

@media (max-width: 500px) {
	.ForgotPassword {
		&__login {
			padding-left: 22px;
			padding-right: 22px;

			&Form {
				width: 100%;
			}
		}
	}
}
