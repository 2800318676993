@import '../../Variables.scss';

.EmailVerification {
	padding: 0;
	margin: 0;
	width: 100%;
	min-height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;

	&__container {
		max-width: 500px;
	}

	&__title {
		color: var(--dark-blue);
		font-size: 22px;
		line-height: 1.23;
		margin: 0 0 30px;
	}

	&__verification {
		height: 80px;

		&Character {
			border-radius: 12px;
			border: 1px solid var(--light-grey);
			line-height: 80px;

			&--inactive {
				background: var(--x-light-grey);
			}

			&--selected {
				outline: 2px solid var(--dark-blue);
			}
		}
	}

	&__error {
		margin: 30px 0;
	}

	&__instructions {
		font-size: 14px;
		line-height: 1.4;
		margin: 30px 0 30px;
		word-wrap: break-word;
		word-break: break-word;
	}

	&__resend {
		color: var(--white);
		background: var(--dark-blue);
		border: 0;
		margin: 0;
		padding: 15px 34px;
		border-radius: 50px;
		cursor: pointer;
		transition: background 200ms, color 200ms;
		min-width: 254px;

		&:hover {
			background: var(--darker-blue);
		}

		&[disabled] {
			cursor: not-allowed;
			background: var(--x-light-grey);
			color: var(--blue);
		}
	}
}

@media (max-width: 620px) {
	.EmailVerification {
		padding-left: 10%;
		padding-right: 10%;

	}
}