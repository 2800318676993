@import "../../Variables.scss";

.Survey {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #f7f7fa;
  padding: 24px;
  box-sizing: border-box;
  overflow: auto;
  position: relative;

  &__container {
    flex: 1;
    background-color: var(--white);
    border-radius: 20px;
    box-shadow: 0px 14px 42px 0px rgba(0, 0, 0, 0.14);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 192px);
  }

  &__typeform {
    flex: 1;
    width: 100% !important;
    height: 100% !important;
    border: none;
  }

  &__error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 40px;
    color: var(--dark-grey);
    font-size: 16px;
    text-align: center;
  }
}

@media screen and (max-width: $size-tablet-max) {
  .Survey {

    &__container {
      min-height: calc(100vh - 128px);
    }
  }
}

@media screen and (max-width: $size-mobile-max) {
  .Survey {
    padding: 10px;
    
    &__container {
      min-height: calc(100vh - 100px);
    }
  }
}