@import '../../../Variables.scss';

.AdvisorIndex {
	height: 100%;

	&__content {
		padding: 0px 10px 70px 10px;
	}

	&__titleBar {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin: 0 12px 0 12px;
		height: 88px;

		&__title {
			display: flex;
			align-items: center;
		}

		button {
			background-color: inherit;
			border: none;
			cursor: pointer;
			outline: none;
			display: flex;
			align-items: center;
		}

		h1 {
			margin: 0;
			font-size: 25px;
			font-weight: $fontWeight--bold;
			color: #073853;
		}
	}

	&__container {
		background: var(--white);
		padding: 45px 40px;
		margin-bottom: 30px;
		border-radius: 20px;
		box-shadow: 0px 14px 42px 0px rgba(0, 0, 0, 0.14);
	}

	&__sort {
		display: flex;
		justify-content: space-between;
		font-size: 12px;
		font-weight: $fontWeight--bold;
		text-transform: uppercase;
		padding: 10px 100px 10px 20px;

		&Name,
		&Type {
			cursor: pointer;
			user-select: none;
		}
	}

	&__sorted {
		&--asc::after {
			content: url(data:image/svg+xml,%3Csvg%20width%3D%2212%22%20height%3D%2213%22%20viewBox%3D%220%200%2012%2013%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M5%2013V3.8L1.4%207.4L0%206L6%200L12%206L10.6%207.4L7%203.8V13H5Z%22%20fill%3D%22%23383838%22%2F%3E%3C%2Fsvg%3E%0A);
			margin-left: 8px;
			position: absolute;
		}

		&--desc::after {
			content: url(data:image/svg+xml,%3Csvg%20width%3D%2212%22%20height%3D%2213%22%20viewBox%3D%220%200%2012%2013%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M7%200L7%209.2L10.6%205.6L12%207L6%2013L0%207L1.4%205.6L5%209.2L5%200H7Z%22%20fill%3D%22%23383838%22%2F%3E%3C%2Fsvg%3E%0A);
			margin-left: 8px;
			position: absolute;
		}
	}

	&__filterIcon {
		height: 16px;
		width: 16px;
		stroke: #383838;

		&--active {
			stroke: var(--blue);
		}
	}

	&__filters {
		--filterBorderRadius: 30px;
		--filterFontSize: 14px;
		margin: 50px 0 30px;
		padding: 0 0 6px;
		white-space: nowrap;
		overflow-x: auto;
		scrollbar-width: thin;

		&Track {
			width: 200px;
			display: inline-flex;
		}

		&Action {
			display: inline-flex;
			align-items: center;
			background: var(--x-light-grey);
			border-radius: var(--filterBorderRadius);
			padding: 0 9px;
			margin-right: 12px;
		}

		&Show {
			display: flex;
			align-items: center;
			border: 0;
			background: none;
			cursor: pointer;
			font-family: inherit;
			color: #000;
			font-size: var(--filterFontSize);
			font-weight: $fontWeight--regular;
			padding: 9px;
		}

		&Icon {
			width: 18px;
			height: 18px;
			margin-right: 9px;
		}

		&Clear {
			border: 0;
			background: none;
			cursor: pointer;
			background: var(--grey);
			color: var(--white);
			border-radius: 50%;
			margin: 0;
			padding: 2px;
			width: 22px;
			height: 22px;
			box-sizing: border-box;

			&Icon {
				width: 10px;
				height: 10px;
			}
		}

		&Type {
			&Filter {
				background: var(--Grey-Background, #f4f7fe);
				border-radius: var(--filterBorderRadius);
				border: none;
				color: #000;
				font-size: var(--filterFontSize);
				font-weight: $fontWeight--regular;
				padding: 0 15px;
				height: 36px;
				font-family: inherit;
				margin-right: 8px;
				cursor: pointer;

				&--checked {
					color: var(--white);
					background: var(--Primary-FF-Blue-1, #007cab);
					background: var(--Primary-FF-Blue-1, #007cab);
				}
			}
		}
	}

	&__noFilteredResults {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 27vh;
	}

	&__noResults {
		margin: 27vh 13px 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;

		&Icon {
			width: 43px;
			height: 35px;
			margin-bottom: 20px;
		}

		&Title {
			font-size: 16px;
			font-weight: $fontWeight--medium;
			padding: 0;
			margin: 0 0 14px;
			color: #454f63;
		}

		&Description {
			font-size: 14px;
			line-height: 1.44;
			font-weight: $fontWeight--regular;
			margin: 0 0 12px;
			text-align: center;
		}
	}

	&__items {
		display: flex;
		flex-wrap: wrap;
	}

	&__filterChips {
		margin-left: -10px;
		margin-right: 10px;
	}

	&__item:first-of-type {
		border-top: 1px solid #e6e6e6;
	}

	&__item {
		border-bottom: 1px solid #e6e6e6;
	}

	&__item:hover {
		border-radius: 10px;
		box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
	}

	&__item {
		overflow: hidden;
		box-sizing: border-box;
		color: inherit;
		position: relative;
		flex-basis: 100%;
		padding: 10px 0;
		display: flex;
		margin: 1px 0;

		&Text {
			flex-grow: 1;
		}

		&Flag {
			display: flex;
			width: 22px;
			height: 16px;
			padding: 0 13px 0px;
			justify-content: center;
			align-items: center;
			gap: 6px;
			flex-shrink: 0;
			border-radius: 15px;
			background: rgba(139, 195, 95, 0.3);

			color: #383838;
			font-size: 11px;
			font-style: normal;
			font-weight: 400;
			line-height: 11px;
			letter-spacing: 0.66px;
			text-transform: uppercase;

			&--ofColor {
				background: var(--darker-blue);
			}
		}

		&TitleContainer {
			display: flex;
			align-items: center;
		}

		&Title {
			font-size: 15px;
			font-weight: $fontWeight--medium;
			line-height: 1.29;
			margin: 10px 6px 7px;
		}

		&Summary {
			color: #928b9f;
			font-size: 14px;
			font-weight: $fontWeight--regular;
			line-height: 1.31;
			margin: 0 6px 14px;
			overflow: hidden;
		}

		&Type {
			display: flex;
			align-items: center;
			min-width: 100px;
			padding: 0 30px 0 0;
			margin-left: 20px;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
		}

		&Metadata {
			font-size: 9px;
			font-weight: $fontWeight--medium;
			color: #9d9d9d;
			position: absolute;
			right: 24px;
			bottom: 15px;
		}
	}

	&__imageContainer {
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		height: auto;
		overflow: hidden;
		position: relative;
		min-width: 70px;
		padding: 0 0 0 20px;
	}

	&__image {
		width: 100%;
		height: 100%;
		max-width: 50px;
		max-height: 50px;
		object-fit: contain;
		object-position: center;
	}

	&__pagination {
		width: 100%;
		text-align: center;

		.MuiPagination-ul {
			justify-content: center;

			li {
			}
		}

		.MuiPaginationItem-page {
			font-size: 13px;
			font-weight: $fontWeight--medium;
			background: var(--white);
			border-radius: 8px;
			border: 1px solid var(--x-light-grey);

			&.Mui-selected {
				color: var(--blue);
				border-color: var(--blue);
				background: var(--white);
			}

			&:hover {
				color: var(--blue);
			}
		}
	}

	&__search {
		.MuiInputBase-root {
			border-radius: 50px;
			background: #f4f7fe;
		}

		.MuiOutlinedInput-notchedOutline {
			border-radius: 50px;
			border-color: #d6dcea;
		}

		.MuiInputAdornment-root {
			color: #007cab;
			padding: 0 0 0 10px;
		}

		&Option {
			&.MuiAutocomplete-option {
				font-weight: $fontWeight--medium;
				font-size: 14px;
				padding-left: 25px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow-x: hidden;
				display: block;
			}

			&--seeAll {
				color: var(--blue);
			}
		}
	}
}

@media screen and (max-width: $size-sm) {
	.AdvisorIndex {
		&__enhancedSearch {
			&Header {
				flex-direction: column;
				align-items: flex-start;
			}

			&Intro {
				order: 2;
				margin-top: 18px;
			}
		}

		&__container {
			padding: 30px 16px;
		}

		&__image {
			margin-top: 12px;

			&Container {
				align-content: flex-start;
				padding: 0;
			}
		}

		&__item {
			flex-wrap: wrap;

			&Text {
				width: min-content;
			}

			&Type {
				width: 100%;
				margin-left: 76px;
			}
		}
	}
}

@media screen and (max-width: $size-tablet-max) {
	.AdvisorIndex--hide-tablet {
		display: none;
	}

	.AdvisorIndex {
		&__titleBar {
			flex-wrap: wrap;
			margin: 25px 12px;
			height: auto;
		}

		&__search {
			flex: 1 0 100%;
			margin: 20px 0 15px;
		}

		&__enhancedSearch {
			margin-top: 0;
		}

		&__noResults {
			margin-top: 20vh;
		}
	}
}

@media screen and (min-width: $size-sm) {
	.AdvisorIndex {
		&__titleBar {
			margin-left: 13px;
		}

		&__filterChips {
			margin-left: 0px;
		}

		// &__item {
		// 	flex: 1 1 252px;
		// 	margin: 13px;
		// }
	}
}

@media screen and (min-width: $size-desktop-min) {
	.AdvisorIndex {
		&__titleBar {
			margin-left: 15px;
			justify-content: flex-end;
			position: relative;

			&__title {
				position: absolute;
				left: 0;
			}
		}

		&__icon {
			order: 1;
			margin-right: 1vw;
		}

		&__search {
			flex: 1 0 25%;
			margin-right: 20px;
			max-width: 530px;
		}

		&__filterChips {
			margin-left: 10px;
		}

		&__content {
			max-width: 1400px;
			padding: 0 44px 70px;
		}

		// &__item {
		// 	flex: 1 1 28%;
		// 	max-width: 30%;
		// }
	}
}

@media screen and (min-width: 1354px) {
	.AdvisorIndex {
		// &__item {
		// 	flex: 1 1 22.5%;
		// 	max-width: 22.5%;
		// }

		&__icon {
			order: 1;
			margin-right: 1.5vw;
		}
	}
}
