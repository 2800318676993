.nilCourseCard {
	display: grid;
	grid: 3fr 2fr / min-content repeat(2, 1fr);
	gap: 0 15px;
	width: 295px;
	border-radius: 10px;
	overflow: hidden;
	background-color: var(--white);
	box-shadow: 0 3px 10px #0000001c;

	&__image {
		grid-row: 1 / -1;
		width: 70px;
		aspect-ratio: 70/95;
		object-fit: cover;
		object-position: center;
	}

	&__title {
		grid-column: 2 / -1;
		color: var(--dark-grey);
		padding-block-start: 13px;
		padding-inline-end: 13px;
		font-size: 14px;
		font-weight: 500;
		line-height: 1.2;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		height: 33px;
	}

	&__dot {
		width: 6px;
		aspect-ratio: 1;
		border-radius: 50%;
		background-color: #9d9d9d;

		&--1 {
			background-color: var(--nil-about);
		}

		&--2 {
			background-color: var(--nil-financial-success);
		}

		&--3 {
			background-color: var(--nil-my-financial-plan);
		}
	}

	&__timeToRead {
		display: flex;
		align-items: center;
		gap: 5px;
		color: #9d9d9d;
		font-size: 9px;
		font-weight: 500;
	}

	&__arrow {
		justify-self: flex-end;
		align-self: flex-end;
		padding-block-end: 2px;
		padding-inline-end: 12px;

		&Icon {
			width: 20px;
			aspect-ratio: 1;
		}
	}
}
