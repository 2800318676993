@import "../../Variables.scss";

.rewardableActivities {
	display: flex;
	flex-direction: column;
	width: 375px;
	height: 464px;
	padding: 25px;
	box-sizing: border-box;
	border-radius: 20px;
	box-shadow: $shadow4;
	background: var(--white);

	&__title {
		font-size: 22px;
		color: var(--dark-blue);
		margin: 0 0 17px;
		padding: 0;

		&Icon {
			width: 26px;
			height: 26px;
			vertical-align: middle;
			margin-right: 8px;
			margin-top: -2px;
		}
	}

	&__description {
		font-size: 16px;
		line-height: 1.25;
		margin: 0 0 12px;
		padding: 0;
	}

	&__progress {
		display: flex;
		align-items: center;
		gap: 20px;
		margin-bottom: 20px;

		&Track {
			background: var(--x-light-grey);
			position: relative;
			flex: 1;
			height: 10px;
			border-radius: 5px;

			&Progress {
				height: 10px;
				border-radius: 5px;
				background: var(--blue);
				position: absolute;
			}
		}

		&Amount {
			color: var(--blue);
			font-size: 18px;
			font-weight: $fontWeight--bold;
		}
	}

	&__activities {
		margin-bottom: 29px;
		flex-grow: 1;
	}

	&__activity {
		border: 1px solid var(--grey2);
		background-color: var(--grey0);
		border-radius: 10px;
		display: flex;
		align-items: center;
		min-height: 58px;
		margin-bottom: 10px;
		padding: 11px 15px;
		box-sizing: border-box;
		justify-content: space-between;
		gap: 18px;
		color: inherit;

		&IconContainer {
			background: var(--x-light-grey);
			width: 20px;
			height: 20px;
			flex: 0 0 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%; 

			&--complete {
				background: var(--green);
			}

			&--inProgress {
				background: var(--white);
				border: 2px solid var(--yellow);
				box-sizing: border-box;
			}
		}

		&Icon {
			width: 10px;
			color: var(--white);
			display: flex;
			align-items: center;
			justify-content: center;
			.rewardableActivities__activityIconContainer--complete & {
				width: 16px;
				height: 16px;
			}
			.rewardableActivities__activityIconContainer--inProgress & {
				color: var(--yellow);
				position: relative;
				left: 1px;
			}
		}

		&Name {
			font-weight: $fontWeight--medium;
			font-size: 16px;
			line-height: 1.25;
			flex: 1;
		}

		&CallToActionContainer {
			width: 24px;
			height: 24px;
			flex: 0 0 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			background-color: var(--blue);
			border-radius: 50%;

			&--complete {
				background-color: var(--grey2);
			}
		}

		&CallToAction {
			color: var(--white);
			width: 10px;
		}
	}

	&__contact {
		font-size: 14px;
		line-height: 1.5;
		text-align: center;

		a {
			color: var(--blue);
		}
	}
}
