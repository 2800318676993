.sessionStatus {
	margin-left: 8px;
	width: 12px;
	height: 12px;

	&--open {
		color: #6da31a;
	}

	&--full,
	&--waitlist {
		color: #ff4b4b;
	}

	&--registered,
	&--waitlisted {
		color: #55abdd;
	}
}
