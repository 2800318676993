@import "../../Variables.scss";

.Home {
	width: 1196px;
	margin: 0 auto;
	padding: 45px 0;

	&__component {
		border-radius: 20px;
		box-shadow: $shadow4;
		background: var(--white);

		&Container {
			display: flex;
			flex-wrap: wrap;
			gap: 35px;
			margin-top: 35px;
		}

		&--fixed {
			padding: 25px;
			width: 375px;
			height: 464px;
			box-sizing: border-box;
		}

		&--nps {
			box-shadow: none;
		}
	}

	&__wellnessPlan {
		padding: 30px;
		display: flex;

		&Divider {
			width: 1px;
			min-height: 100%;
			margin: 0 30px 0 35px;
			background: var(--grey2);
			display: block; 
		}
	}

	&__enhancedSearch {
		--enhancedSearchPadding: 25px;

		background: var(--white);
		border-radius: 10px;
		padding: var(--enhancedSearchPadding);
		margin-bottom: 20px;

		.MuiAutocomplete-inputRoot {
			border: 1px solid #DBE1EE !important;
			border-radius: 40px !important;
			background: #F4F7FE;
		}
		
		.MuiInputBase-input::placeholder {
			color: var(--dark-grey);
		}

		.enhancedSearch__searchIcon {
			width: 20px;
			height: 20px;
			fill: var(--blue);
		}

		.enhancedSearch__searchAimeeIcon {
			width: 22px;
			height: 22px;
		}
	}
}


@media screen and (max-width: 1470px) {
	.Home {
		width: 786px;
		padding: 30px 0;

		&__wellnessPlan {
			flex-direction: column;
	
			&Divider {
				width: 100%;
				height: 1px;
				margin: 34px 0;
			}
		}
	}
}

@media screen and (max-width: 855px) {
	.Home {
		width: 375px;
	}
}