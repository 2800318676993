@import '../../../Variables.scss';

.LibraryContent {
	.advisorDownload {
		background: var(--dark-blue);
		color: var(--white) !important;
		font-size: 16px;
		font-weight: $fontWeight--medium;
		margin: 20px auto 30px;
		padding: 12px 24px;
		border-radius: 50px;
		display: block;
		width: fit-content;
	}
}
