@import '../../../Variables.scss';

.homeActionPlan {
	flex: 1;

	&__header {
		display: flex;
		align-items: center;
		margin-bottom: 33px;

		&Level {
			flex: 0 0 71px;
		}

		&Title {
			flex: 1;
			margin: 0 12px;
			padding: 0;
			font-size: 18px;
			font-weight: $fontWeight--bold;
			color: var(--darker-blue);
		}

		&Link {
			color: var(--blue);
			font-size: 16px;
			font-weight: $fontWeight--bold;
		}
	}

	&__footer {
		display: none;
	}
}

.WellnessActionPlanNoActionItems {
	background-color: #F4F7FE;
	border-radius: 15px;
	text-align: center;
	padding: 35px;

	&__image {
		width: 50px;
		height: 50px;
		margin: 0 0 18px;
		padding: 0;
	}

	&__title {
		margin: 0 0 7px;
		padding: 0;
		color: var(--blue);
		font-size: 22px;
		line-height: 1.22;
		font-weight: $fontWeight--bold;
	}

	&__description {
		margin: 0 0 21px;
		padding: 0;
		font-size: 16px;
		line-height: 1.625;
	}

	&__link {
		display: inline-block;
		background: var(--blue);
		color: var(--white);
		padding: 15px 42px;
		font-size: 16px;
		font-weight: $fontWeight--bold;
		border-radius: 50px;
	}
}

.WellnessActionPlanNoScore {
	background-color: #F4F7FE;
	border-radius: 15px;
	padding: 54px 24px 54px 45px;
	display: flex;

	&__title {
		margin: 0 0 10px;
		padding: 0;
		color: var(--blue);
		font-size: 22px;
		line-height: 1.22;
		font-weight: $fontWeight--bold;
	}

	&__description {
		margin: 0 0 21px;
		padding: 0;
		font-size: 16px;
		line-height: 1.44;
	}

	&__link {
		display: inline-block;
		background: var(--blue);
		color: var(--white);
		padding: 15px 42px;
		font-size: 16px;
		font-weight: $fontWeight--bold;
		border-radius: 50px;
	}

	&__image {
		width: 177px;
		height: 183px;
		margin-left: 30px;
	}
}

.WellnessActionPlanPerfectScore {
	background-color: #F4F7FE;
	border-radius: 15px;
	padding: 54px 34px 46px 34px;
	display: flex;
	align-items: center;

	&__container {
		flex: 1;
	}

	&__title {
		margin: 0 0 10px;
		padding: 0;
		color: var(--blue);
		font-size: 22px;
		line-height: 1.22;
		font-weight: $fontWeight--bold;
	}

	&__description {
		margin: 0 0 21px;
		padding: 0;
		font-size: 16px;
		line-height: 1.44;
	}

	&__link {
		display: inline-block;
		background: var(--blue);
		color: var(--white);
		padding: 13px 21px;
		font-size: 18px;
		font-weight: $fontWeight--bold;
		border-radius: 50px;
		border: 2px solid var(--blue);

		&--outline {
			background: transparent;
			color: var(--blue);
			margin-right: 14px;
		}
	}

	&__image {
		width: 198px;
		height: 191px;
		margin-left: 10px;
	}
}

.WellnessActionPlanActionItems {
	display: flex;
	flex-direction: column;
	gap: 10px;
	
	&__item {
		display: flex;
		align-items: center;
		width: calc(100% - 2px);
		border-radius: 10px;
		border: 1px solid var(--grey2);
		background-color: var(--grey0);
		padding: 18px 16px;
		box-sizing: border-box;
		min-height: 56px;

		&Status {
			margin-right: 23px;

			&Icon {
				width: 17px;
				height: 17px;
				border-radius: 50%;
				border: 2px solid var(--grey2);
			}
		}

		&Title {
			font-size: 16px;
			line-height: 1.25;
			font-weight: $fontWeight--medium;
			color: #383838;
			flex: 1;
		}

		&Meta {
			flex: 0 0 125px;
			margin: 0 20px;

			&Pill {
				width: 102px;
				height: 24px;
				font-size: 11px;
				font-weight: $fontWeight--regular;
				text-transform: uppercase;
				color: #383838;
				letter-spacing: 0.06em;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;

				&::before {
					position: absolute;
					top: 0;
					left: 0;
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					border-radius: 15px;
					background-color: var(--teal);
					opacity: 0.20;
				}
			}
		}

		&Arrow {
			flex: 0 0 12px;

			&Icon {
				width: 12px;
				height: 12px;
				color: var(--blue);
			}
		}
	}
}

@media screen and (max-width: 855px) {
	.homeActionPlan {

		&__header {
			margin-bottom: 25px;

			&Link {
				display: none;
			}
		}

		&__footer {
			display: block;
			margin-top: 19px;
			text-align: center;

			&Link {
				color: var(--blue);
				font-size: 16px;
				font-weight: $fontWeight--bold;
			}
		}
	}

	.WellnessActionPlanNoScore {
		flex-direction: column;
		padding: 47px 25px;

		&__link {
			display: block;
			text-align: center;
		}

		&__image {
			margin: 60px auto 0;
		}
	}

	.WellnessActionPlanPerfectScore {
		flex-direction: column;
		padding: 47px 25px;

		&__link {
			display: block;
			text-align: center;
			margin: 0 0 10px;
		}

		&__image {
			margin: 60px auto 0;
		}
	}

	.WellnessActionPlanActionItems {

		&__item {
			display: grid;
			grid-template-columns: 21px repeat(2, auto) 12px;
			grid-template-rows: repeat(2, auto);
			grid-column-gap: 23px;
			grid-row-gap: 0; 

			&Status {
				grid-area: 1 / 1 / 3 / 2;
				align-self: start;
			}

			&Title {
				grid-area: 1 / 2 / 2 / 4;
			}

			&Meta {
				grid-area: 2 / 2 / 3 / 4;
				margin: 0;

				&Pill {
					margin: 10px 0 0;
				}

				.LevelPill {
					margin-top: 10px;
				}
			}

			&Arrow {
				grid-area: 1 / 4 / 3 / 5;
				align-self: start;

				&Icon {
					margin-top: 5px;
				}
			}
		}
	}
}