@import '../../Variables.scss';

.IntlHomeSessions {
    background: var(--white);
	padding: 24px;
	border-radius: 15px;
	box-shadow: 2px 4px 10px rgba(0,0,0,0.16);
	margin: 0 0 20px;
	display: flex;
	flex-direction: column;

	&__header {
		display: flex;
		margin-bottom: 16px;
	}

	&__title {
		color: var(--dark-blue);
		width: 50%;
		text-align: left;
		font-size: 16px;
		font-weight: $fontWeight--bold;
		margin: 0;
	}

	&__seeAll {
		width: 50%;
		color: var(--blue);
		text-align: right;
		font-weight: $fontWeight--medium;
		font-size: 13px;
	}

	&__body {
		display: flex;
		flex-direction: column;

		li > a {
			padding: 0;
		}
	}

    &__link {
        margin-top: 25px;
		color: var(--white);
		background: var(--blue);
		font-size: 14px;
		font-weight: $fontWeight--bold;
        width: 100%;
		height: 39px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 20px;
	}
}
