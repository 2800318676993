@import '../../Variables.scss';

.GoalProgress__chart {
	background-color: var(--blue);

	&__invisible {
		display: none;
	}
}

.GoalProgress {
	&__legend {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		font-size: 13px;
		font-weight: $fontWeight--regular;
		color: var(--white);
		
		&Minimum {
			margin-right: 25px;
		}

		&Payoff {
			margin-left: 25px;
		}

		&Icon {
			display: inline-block;
			width: 9px;
			height: 9px;
			background-color: var(--dark-blue);
			border-radius: 50%;
			margin-right: 10px;

			&--payoff {
				background-color: var(--green);
			}
		}
	}
}