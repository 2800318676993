.course {
	--padding-block: 50px;
	--padding-inline: 50px;
	padding-block: var(--padding-block);
	padding-inline: var(--padding-inline);
	background-color: var(--white);
	box-shadow: 0px 3px 10px #00000029;
	border-radius: 15px;
	overflow: hidden;
	color: var(--dark-grey);
	font-size: 14px;
	margin-top: 20px;

	&__layout {
		max-width: 700px;
		margin-block-start: 32px;
		margin-inline: 33px;
		padding-bottom: 70px;
		margin-left: auto;
		margin-right: auto;
	}

	&__header {
		margin: 0;
		color: #073853;
		font-size: 25px;
		font-weight: 600;
		margin-bottom: 10px;
	}

	&__hero {
		margin-block-start: calc(-1 * var(--padding-block));
		margin-inline: calc(-1 * var(--padding-inline));
		margin-block-end: 42px;
		padding-top: calc( 56.25% -  (-1 * var(--padding-inline)));
		overflow: hidden;
		position: relative;

		img {
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
		}
	}

	&__video {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
	}

	&__heroVideoOverlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 2px;
		display: flex;
		background: rgba(0,0,0,0.20);
		align-items: center;
		justify-content: center;
		cursor: pointer;
		z-index: 3;
	}

	&__heroVideoIcon {
		width: 64px;
		height: 64px;
		color: var(--white);
	}

	&__completion {
		margin-block: 10px;
		color: #9d9d9d;
		font-weight: 500;
		text-transform: uppercase;
	}

	&__title {
		margin-block: 10px 25px;
		color: #073853;
		font-size: 22px;
		font-weight: 600;
	}

	&__details {
		display: flex;
		align-items: center;
		gap: 10px 40px;
		color: var(--dark-grey);
		flex-wrap: wrap;
	}

	&__moduleDetails {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	&__dot {
		width: 12px;
		aspect-ratio: 1;
		border-radius: 50%;
		background-color: currentColor;
	}

	&__module {
		font-weight: 600;
	}

	&__clock {
		width: 20px;
		aspect-ratio: 1;
		fill: #c1c1c1;
	}

	&__timeToRead {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	&__author {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	&__authorImg {
		width: 20px;
		aspect-ratio: 1;
		object-fit: cover;
		object-position: center;
		border-radius: 50%;
	}

	&__separator {
		margin-block: 30px;
		height: 2px;
		background-color: #efefef;
	}

	&__instructions {
		header {
			margin-block-end: 6px;
			color: var(--blue);
			font-weight: 600;
		}

		p {
			margin: 0;
			color: var(--dark-grey);
		}
	}

	&__tasks {
		margin-block-start: 25px;
		display: grid;
		grid-template-columns: 1fr;
		grid-auto-flow: row;
		gap: 20px;
	}

	.complete {
		color: var(--blue);
		display: flex;
		align-items: center;
	}

	.course__check {
		width: 20px;
		height: 20px;
	}
}

@media (max-width: 1092px) {
	.course {
		grid-column: span 2;

		&__nextCourseBanner {
			grid-column: span 2;
		}

		&__layout {
			grid: repeat(3, min-content) / 2fr 1fr;
			gap: 30px;
			align-items: flex-start;
			max-width: 100%;
		}

		&__header {
			grid-column: span 2;
		}

		&__certificationProgress {
			grid-column: span 2;
			grid-row: -1;
		}
	}
}

@media (max-width: 550px) {
	.course {
		padding-inline: 20px;

		&__layout {
			margin-inline: 0;
		}
	}
}