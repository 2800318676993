@import '../../Variables.scss';

.quiz {
	padding: 25px;
	background-color: var(--white);
	box-shadow: 2px 4px 10px #00000029;
	border-radius: 15px;
	overflow: hidden;

	&__title {
		color: #073853;
		font-weight: 600;
		margin-bottom: 14px;
	}

	&__description {
		font-weight: $fontWeight--regular;
		font-size: 14px;
		line-height: 1.35;
		margin-bottom: 15px;
	}

	&__cta {
		width: 100%;
		border-radius: 5px;
		border: 0;
		background: var(--x-light-grey);
		color: #44565D;
		font-size: 13px;
		margin: 0 0 43px;
		padding: 10px;
		box-sizing: border-box;
		font-weight: $fontWeight--bold;
		text-align: center;
		display: block;

		&--available {
			color: var(--white);
			background: var(--blue);
		}
	}

	&__quiz {
		margin-bottom: 10px;
		border-radius: 10px;
		border: 1px solid #EFEFEF;
		padding: 15px 16px;

		&--overall {
			background: #F7F7FA;
		}

		&Title {
			font-weight: $fontWeight--medium;
			font-size: 16px;
			margin-bottom: 8px;
			line-height: 1.4;
		}

		&Flag {
			color: var(--dark-blue);

			&--passed {
				color: var(--blue);
			}

			&--notPassed {
				color: var(--red);
			}
		}

		&ProgressContainer {
			display: flex;
			align-items: center;
			gap: 30px;
		}

		&Progress {
			flex: 1;
		}

		&Percentage {
			font-weight: $fontWeight--medium;
			font-size: 16px;
		}
	}

	&__link {
		color: #232323;
	}
}
